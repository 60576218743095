import React, { useState } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import Dialog from "../../Dialog/Dialog";
import "./BlogList.scss";

function BlogList({ categories, onEdit, onDelete }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState(null);

  const handleDeleteClick = (blogId) => {
    setBlogToDelete(blogId);
    setDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (blogToDelete) {
      onDelete(blogToDelete);
      setBlogToDelete(null);
    }
  };

  return (
    <>
      <Dialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Delete Blog"
        message="Are you sure you want to delete this blog? This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        type="danger"
      />

      {Object.entries(categories).map(([category, blogs]) => (
        <div className="blog-settings-category" key={category}>
          <h2 className="blog-settings-category-title">
            {category.toUpperCase()}
          </h2>
          <div className="blog-settings-blogs">
            {blogs.map((blog) => (
              <div className="blog-settings-blog" key={blog._id}>
                <div className="blog-settings-blog-image">
                  <img src={blog.image} alt="" />
                </div>
                <div className="blog-settings-blog-content">
                  <h2 className="blog-settings-blog-content-title">
                    {blog.title}
                  </h2>
                  <p className="blog-settings-blog-content-text">
                    {blog.subTitle}
                  </p>
                  <div className="blog-settings-blog-content-footer">
                    <span className="date">
                      {new Date(blog.releaseDate).toLocaleDateString()}
                    </span>
                    <div className="blog-settings-blog-content-actions">
                      <button
                        className="action-button preview"
                        onClick={() =>
                          window.open(`/blog/${blog.link}`, "_blank")
                        }
                        title="Preview"
                      >
                        <FaEye />
                      </button>
                      <button
                        className="action-button edit"
                        onClick={() => onEdit(blog)}
                        title="Bearbeiten"
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="action-button delete"
                        onClick={() => handleDeleteClick(blog._id)}
                        title="Löschen"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default BlogList;
