import React from "react";
import { FaExclamationTriangle, FaTimes } from "react-icons/fa";
import "./Dialog.scss";

const Dialog = ({
  isOpen,
  onClose,
  onConfirm,
  title = "Confirm Action",
  message = "Are you sure you want to proceed?",
  confirmText = "Confirm",
  cancelText = "Cancel",
  type = "warning", // warning, danger, info
}) => {
  if (!isOpen) return null;

  return (
    <div className="dialog-overlay" onClick={onClose}>
      <div className="dialog-content" onClick={(e) => e.stopPropagation()}>
        <button className="dialog-close" onClick={onClose}>
          <FaTimes />
        </button>

        <div className="dialog-header">
          <div className={`dialog-icon ${type}`}>
            <FaExclamationTriangle />
          </div>
          <h2>{title}</h2>
        </div>

        <div className="dialog-body">
          <p>{message}</p>
        </div>

        <div className="dialog-actions">
          <button className="dialog-button cancel" onClick={onClose}>
            {cancelText}
          </button>
          <button
            className={`dialog-button confirm ${type}`}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
