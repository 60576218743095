import React from 'react'
import "./SingleBlogs.scss"
import workout1 from "../../../img/workout1.png"
import BackIcon from '../../Icons/BackIcon'


function Workout1() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Home Workout</h1>
      <img src={workout1} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Videos Woche 1</h2>
      <p className="single-blog-text">
        Hier findest Du als kleine Inspiration 5 Workout Videos für WOCHE 1 Deiner HiLife Challenge. <br /> <br />
        Absolviere die 3 Beat the Coach Workouts, das Mobility und Core Workout und sammle Punkte für Dich. <br /> <br />
        Du kannst natürlich auch anders Sport machen: Laufen, Yoga, Pilates oder was Dir sonst noch gefällt – sofern Du Dich mindestens 15 Minuten mit Intention bewegst, darfst Du Dir beim Training ein Häkchen in der App setzen und erhältst Deine Punkte..</p> <br />

      <br /> <br />

      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://player.vimeo.com/video/586663180`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <br /> <br />

      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://player.vimeo.com/video/487193277`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <br /><br />
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://player.vimeo.com/video/543276715`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <br /><br />
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://player.vimeo.com/video/487201027`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>   <br /><br />

      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://player.vimeo.com/video/543294196`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  )
}

export default Workout1