import React, { useRef } from "react";
import { useStore } from "../../utils/store";
import "./TeamView.scss";
import CreateTeam from "../CreateTeam/CreateTeam";
import FooterNavigation from "../FooterNavigation/FooterNavigation";
import HeaderNavigation from "../HeaderNavigation/HeaderNavigation";
import TeamDashboard from "../TeamDashboard/TeamDashboard";
import Footer from "../Footer/Footer";

function TeamView() {
  const footerNavigationRef = useRef(null);

  const user = useStore((state) => state.user);
  const userChallenge = useStore((state) => state.userChallenge);

  return (
    <div className="teamView">
      <HeaderNavigation simpleNavi={true} team={userChallenge.team} />
      {userChallenge.team ? <TeamDashboard /> : <CreateTeam />}
      <Footer />
      <FooterNavigation ref={footerNavigationRef} />
    </div>
  );
}

export default TeamView;
