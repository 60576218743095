import React from "react";

function ReflectionIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: props.color }}
    >
      <path d="M23.3179 22.5437L22.1766 22.1298C22.0261 22.1298 21.8881 21.8414 21.7878 21.5028C21.5642 20.6018 21.6122 19.6549 21.9257 18.7812C23.1799 17.3515 24.4341 15.5956 24.4341 11.1935C24.4968 6.42768 21.2359 4.29559 18.0002 4.29559C14.7644 4.29559 11.5036 6.42768 11.5036 11.1935C11.5036 15.5956 12.7578 17.3515 14.0119 18.7812C14.3547 19.6461 14.4333 20.5932 14.2377 21.5028C14.1374 21.8414 13.9994 22.0797 13.8489 22.1298L12.7076 22.5437C8.35565 24.1491 5.18261 25.3029 4.29215 27.1465C3.47154 28.8212 3.01422 30.6505 2.9502 32.5143C2.9502 32.8607 3.23095 33.1414 3.57728 33.1414H32.4231C32.7694 33.1414 33.0502 32.8607 33.0502 32.5143C33.0136 30.6569 32.5863 28.8279 31.796 27.1465C30.8052 25.3029 27.6698 24.1491 23.3179 22.5437Z" />
      <path d="M4.20436 9.31228C4.89702 9.31228 5.45853 8.75077 5.45853 8.05811V6.17686C5.45853 5.83053 5.73928 5.54978 6.08561 5.54978H7.96686C8.65952 5.54978 9.22103 4.98827 9.22103 4.29561C9.22103 3.60295 8.65952 3.04144 7.96686 3.04144H5.45853C4.07321 3.04144 2.9502 4.16446 2.9502 5.54978V8.05811C2.9502 8.75077 3.5117 9.31228 4.20436 9.31228Z" />
      <path d="M5.45853 21.854H7.96686C8.65952 21.854 9.22103 21.2925 9.22103 20.5998C9.22103 19.9071 8.65952 19.3456 7.96686 19.3456H6.08561C5.73928 19.3456 5.45853 19.0649 5.45853 18.7185V16.8373C5.45853 16.1446 4.89702 15.5831 4.20436 15.5831C3.5117 15.5831 2.9502 16.1446 2.9502 16.8373V19.3456C2.9502 20.7309 4.07321 21.854 5.45853 21.854Z" />
      <path d="M30.5418 3.04144H28.0335C27.3408 3.04144 26.7793 3.60295 26.7793 4.29561C26.7793 4.98827 27.3408 5.54978 28.0335 5.54978H29.9147C30.261 5.54978 30.5418 5.83053 30.5418 6.17686V8.05811C30.5418 8.75077 31.1033 9.31228 31.796 9.31228C32.4886 9.31228 33.0501 8.75077 33.0501 8.05811V5.54978C33.0501 4.16446 31.9271 3.04144 30.5418 3.04144Z" />
      <path d="M31.796 15.5831C31.1033 15.5831 30.5418 16.1446 30.5418 16.8373V18.7185C30.5418 19.0649 30.261 19.3456 29.9147 19.3456H28.0335C27.3408 19.3456 26.7793 19.9071 26.7793 20.5998C26.7793 21.2925 27.3408 21.854 28.0335 21.854H30.5418C31.9271 21.854 33.0501 20.7309 33.0501 19.3456V16.8373C33.0501 16.1446 32.4886 15.5831 31.796 15.5831Z" />
    </svg>
  );
}

export default ReflectionIcon;
