import React from 'react'
import "./SingleBlogs.scss"
import atmung2 from "../../../img/atmung2.png"
import BackIcon from '../../Icons/BackIcon'


function Atmung2() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Atmung</h1>
      <img src={atmung2} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Videos und Übungen</h2>
      <p className="single-blog-text">
        Unsere Atemübung in der App ist eine sichere Bank! Falls Du aber schon routiniert bist und einfach mal andere Atemübungen ausprobieren möchtest, haben wir Dir hier eine kleine Auswahl zusammengestellt.  <br /> <br />
        Neben den Videos können wir Dir auch folgende Apps fürs Smartphone empfehlen.In einige Apps kannst Du die Atem-Intervalle selber einstellen und mit verschiedenen Längen spielen.
      </p> <br />
      <h2 className="single-blog-sub">APPS</h2>
      <ul className="single-blog-list">
        <p className="single-blog-text">iOS</p>
        <li> <a href="https://apps.apple.com/us/app/breathe-simple-breath-trainer/id1106998959" className="single-blog-link">Breathe+</a> </li>
        <li> <a href="https://apps.apple.com/us/app/state-breathing/id1449322496" className="single-blog-link">State</a> </li>
        <li> <a href="https://apps.apple.com/us/app/7mind-meditation-sleep/id943347681" className="single-blog-link">7Mind</a> </li>
        <li> <a href="https://apps.apple.com/us/app/headspace-mindful-meditation/id493145008" className="single-blog-link">Headspace</a> </li>
      </ul>
      <ul className="single-blog-list">
        <p className="single-blog-text">Android</p>
        <li> <a href="https://myleo.us4.list-manage.com/track/click?u=fae7d7d10a0cb31710c9d64bb&id=3c04779d93&e=2da6bbb0d7" className="single-blog-link">Breathing</a> </li>
        <li> <a href="https://play.google.com/store/apps/details?id=com.breathwrk.android" className="single-blog-link">Breathwrk</a> </li>
        <li> <a href="https://play.google.com/store/apps/details?id=awesomelabs.awesomebreathing.app" className="single-blog-link">Awesome Breathing</a> </li>
      </ul>

      <h1 className="single-blog-title">VIDEOS</h1>

      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://player.vimeo.com/video/703624557`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <br /><br />
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/0BNejY1e9ik`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <br /><br />
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/Wemm-i6XHr8`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>


      <br /><br />
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/tKaUEVnducI`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>




    </div>
  )
}

export default Atmung2