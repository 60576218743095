import React from "react";
import "./SingleBlogs.scss";
import ernaehrung2 from "../../../img/ernaehrung2.png";
import BackIcon from "../../Icons/BackIcon";

function Ernaehrung2() {
  return (
    <div className="single-blog">
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Ernährung</h1>
      <img src={ernaehrung2} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">
        Die Regel: Halte Dich täglich an den von Dir selbst ausgewählten
        Ernährungsplan.
      </h2>
      <p className="single-blog-text">
        Mit einer guten Ernährung kannst Du Deine Leistungsfähigkeit deutlich
        steigern und was noch viel wichtiger ist: gesund bleiben - im Körper und
        Geist. Ernährung ist mehr als nur Energieaufnahme, es ist Medizin und
        Lebenselixier für Deinen Körper. In der Ernährung liegt der Schlüssel
        für ein gesundes, langes Leben – und das wollen wir doch eigentlich
        alle!
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Natürlich ist Ernährung etwas sehr Individuelles. Und egal was Du über
        Ernährung gehört hast, eine Sache können wir mit Sicherheit sagen: Es
        gibt KEINE ONE SIZE FITS ALL Ernährung!
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Einige haben mit einer ketogenen Ernährung tolle Erfolge, andere essen
        mehr Carbs, wieder andere machen intermittierendes Fasten. Manche essen
        alles, andere nur vegetarisch oder keine Milchprodukte etc.
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Unabhängig von Deinen persönlichen Präferenzen, gilt in der Challenge
        das Ernährungsmotto: EAT REAL FOOD.
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Ernähre Dich mit möglichst natürlichen, unverarbeiteten Lebensmitteln.
        Im besten Fall kommt Dein Essen aus der Natur, nicht aus einer Fabrik.
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Natürlich gibt es hier kein schwarz oder weiß und der Grad der
        Verarbeitung liegt eher auf einer Skala, einem Kontinuum:
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Vom selbst angebauten Gemüse aus dem Garten über Käse vom Biobauern zu
        gehackten Tomaten aus der Dose bis zu Tiefkühlpizza ist fast jedes
        Nahrungsmittel aus dem Supermarkt immer verschieden stark “industriell”
        verarbeitet.
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Versuche einfach, möglichst weit unten auf der Verarbeitungsskala zu
        essen. D.h. lieber die gehackten Tomaten aus der Dose als die (meist
        noch mit Zucker) fertig gewürzte und eingekochte Tomatensauce. Lieber
        das ungezuckerte Apfelmus als eine Marmelade etc.
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Falls doch mal Zweifel aufkommen sollten, dann stell Dir folgende
        Fragen: Hat es eine Verpackung? Hat es mehr als 3-5 Zutaten? Hat es
        Inhaltsstoffe, die Du im Lexikon nachschlagen musst? Wird dafür Werbung
        gemacht? Beantwortest Du mehr als eine Frage davon mit „JA“, dann ist es
        vermutlich eher keine gute Idee (natürlich gibt es auch Ausnahmen, aber
        als grobe Regel ist das ganz gut).
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Aber wenn es nur so einfach wäre... Essen ist aber eine sehr emotionale
        Angelegenheit. Wir essen, um uns zu belohnen, um uns zu trösten, um ein
        positives Gefühl zu haben. In den Zeiten der Altsteinzeit hatte dieses
        Belohnungssystem einen Sinn - der Mensch sollte immer motiviert sein,
        Fettreiches (Fleisch) und Süßes (Obst) zu finden und zu essen, um
        optimal mit Nährstoffen und Energie versorgt zu sein. In unserer
        modernen Zeit, einer Zeit des Überflusses und so genannten
        "Frankenfoods", wird uns dieses System jedoch zum Verhängnis. Besonders
        bei Süßigkeiten und anderen industriell verarbeiteten Lebensmitteln
        sendet unser Gehirn große Mengen Dopamin aus. Essen macht glücklich.
        Aber diese neuen Lebensmittel sind auch dafür verantwortlich, dass wir
        nicht einfach nach dem einen Löffel Ben&Jerry's oder dem halben
        Tortenstück aufhören können. Das ist das so genannte "food with no
        brakes": neuzeitliche Lebensmittel, die unsere Geschmacksrezeptoren so
        sehr überreizen, dass "normale" Lebensmittel gar nicht mithalten können,
        sog. "superstimulating and hyperpalatable foods". Natürlich schmeckt
        Wasser oder ungesüßter Tee im Vergleich zu einer Apfelschole oder Cola
        ziemlich langweilig. Deswegen wird Euer Gehirn fast immer die Cola
        vorziehen.
      </p>{" "}
      <br />
      <p className="single-blog-text">
        Es sei denn, ihr legt den Schalter um und programmiert Euren Geschmack
        wieder um. Zurück zu REAL FOOD - ohne Zucker oder Geschmacksverstärker..
        Das ist das Ernährungsziel der HiLife Challenge!
      </p>{" "}
      <br />
      <h2 className="single-blog-sub">Die HiLife Ernährungslevel</h2>
      <p className="single-blog-text">
        Gesunde Ernährung ist vielleicht simpel, aber nicht einfach. Je nachdem,
        wo DU gerade stehst, haben wir für Dich den passenden Ernährungsplan.
        Für die Auswahl gilt: Deine gesteckten Ernährungsziele sollen Dich ruhig
        fordern, aber nicht überfordern. <br />
        Wir stellen Dir unsere 3 Ernährungslevel vor. Du entscheidest, welcher
        Typ du bist:
      </p>{" "}
      <br />
      <h2 className="single-blog-sub">BASIC</h2>
      <p className="single-blog-text">
        Gesunde Ernährung spielt bei Dir momentan keine große Rolle. Du isst,
        was schmeckt oder gerade da ist. Deine Prioritäten lagen bisher einfach
        woanders. Das ist OK. <br />
        Du möchtest Deine Gesundheit verbessern, Dich besser fühlen, performen
        und aussehen. Dann starte Deine erste HiLife Challenge mit dem
        BASIC-Level. <br />
        Damit bildest Du ein Fundament und die Basis bewusster Ernährung.
      </p>{" "}
      <br />
      <h2 className="single-blog-sub">PRO</h2>
      <p className="single-blog-text">
        Gesundheit und Leistungsfähigkeit sind für Dich absolute Priorität und
        Du hast Freude daran. Du kennst Dich super mit der Verarbeitung von
        Lebensmitteln aus und kochst fast alle Gerichte selbst. Du hast bereits
        eine solide Routine. <br />
        Du möchtest eine weit überdurchschnittliche Körperkomposition, top
        Leistung und Regeneration erreichen und genau herausfinden, welche
        spezifischen Lebensmittel Dir wirklich gut tun? <br />
        Dann wähle das Ernährungslevel PRO.
      </p>{" "}
      <br />
      <h2 className="single-blog-sub">TIPP FÜR FORTGESCHRITTENE:</h2>
      <h2 className="single-blog-sub">MAKE IT YOUR OWN!</h2>
      <p className="single-blog-text">
        Du möchtest den PRO Plan aber ohne Milchprodukte machen? Du möchtest den
        LIFESTYLE Plan aber ohne Fleisch, nur mit Fisch? Alles kein Problem.
        Nimm den Plan der am besten zu Dir passt und streiche alle Lebensmittel
        aus der "YES" Kategorie, die Du nicht dabei haben willst. <br />
        ABER: Aus der “NO” Kategorie darfst Du nichts zu Deinem individuellen
        Plan hinzufügen. <br />
        Die einzelnen Regeln der Ernährungspläne findest Du hier (LINK zum PDF.)
      </p>{" "}
      <br />
    </div>
  );
}

export default Ernaehrung2;
