import React, { useRef, useState, useEffect } from "react";
import DOMPurify from "dompurify";
import "./ChatView.scss";
import FooterNavigation from "../../Components/FooterNavigation/FooterNavigation";
import HeaderNavigation from "../../Components/HeaderNavigation/HeaderNavigation";
import { useStore } from "../../utils/store";

function ChatView() {
  const challenge = useStore((state) => state.challenge); // Get challenge from store
  const footerNavigationRef = useRef(null);
  const ownRankRef = useRef(null);
  const [footerNavigationHeight, setFooterNavigationHeight] = useState(0);
  const [ownRankHeight, setOwnRankHeight] = useState(0);

  useEffect(() => {
    setFooterNavigationHeight(footerNavigationRef?.current?.clientHeight);
    setOwnRankHeight(ownRankRef?.current?.clientHeight);
  }, [footerNavigationHeight, ownRankHeight]);

  return (
    <>
      <HeaderNavigation simpleNavi={true} />
      <div
        className="page-container"
        style={{ paddingBottom: footerNavigationHeight + ownRankHeight + "px" }}
      >
        <div className="chat">
          <h1 className="chat-title">
            {challenge?.globalSettings?.chatView?.title || "CHAT"}
          </h1>
          <div
            className="chat-text"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                challenge?.globalSettings?.chatView?.content ||
                  `Wir kommunizieren über unsere eigens Community App. <br /> <br />
                  Noch kein Mitglied? Stelle jetzt eine Beitrittsanfrage!
                  <br /><br />
                  <a class="underline" href="https://community.direktplus.net/timeline/challenge" target="_blank">
                    ZUR HiLife Community App
                  </a>`
              ),
            }}
          />
        </div>
      </div>
      <FooterNavigation ref={footerNavigationRef} />
    </>
  );
}

export default ChatView;
