import React from 'react'
import "./SingleBlogs.scss"
import superprotokoll from "../../../img/superprotokoll.png"
import BackIcon from '../../Icons/BackIcon'


function Superprotkoll() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title"> Das Neuroplastizität Superprotkoll von Huberman</h1>
      <h2 className="single-blog-sub">Du musst für eine Prüfung, einen Vortrag oder eine neue Sprache lernen?</h2>


      <img src={superprotokoll} alt="" className="single-blog-image" />
      <p className="single-blog-text">Wir stellen Dir das Neuroplastizitäts-Superprotokoll von Neurowissenschaftler Andrew Huberman von der Stanford University School of Medicine vor.<br /> <br />
        Den Originalartikel findest Du hier:</p>

      <a href="https://hubermanlab.com/teach-and-learn-bett er-with-a-neuroplasticity-super-protocol/" className="single-blog-ink">https://hubermanlab.com/teach-and-learn-bett er-with-a-neuroplasticity-super-protocol/</a>

      <h2 className="single-blog-sub">1. SEI WACH(SAM)</h2>
      <p className="single-blog-text">
        Wir müssen wach(sam) sein, um Neuroplastizität auszulösen und später den Lernprozess im Schlaf zu vervollständigen. Wach zu werden, beinhaltet viele Mechanismen, aber hauptsächlich die Freisetzung von Epinephrin (Adrenalin) in Gehirn und Körper. <br /> <br />
        Eine einfache Möglichkeit, wacher zu werden, sind 25-30 tiefe Atemzüge (durch die Nase einatmen und durch den Mund ausatmen). Dann atme aus und halte den Atem mit leeren Lungen für 15-60 Sekunden an. Atme dann einmal ein und halte die Luft an. Aber forciere das Anhalten des Atems nicht. Beginne sofort normal zu atmen, sobald Du den Impuls zum Atmen verspürst..

      </p>

      <h2 className="single-blog-sub">2. KONZENTRIERE DICH</h2>
      <p className="single-blog-text">
        Das beste Getränk für jeden Menschen? WASSER! Kein Getränk versorgt Deinen Körper besser mit Feuchtigkeit und stillt Durst. Gewöhne Dich daran, Deinen Tee oder Kaffee ohne Süßungsmittel zu trinken, damit Dein Geschmackssinn runter kommt von der unnatürlichen Süße. <br /> <br />
        Lass die Finger von Säften (auch selbst gepresst), Limonaden oder anderen gesüßten Getränken und auch von Alkohol.<br /> <br />
        Davon abgesehen, dass diese zuckerhaltigen Getränke ziemlich schlecht für Deine Zähne sind, nimmst Du sehr schnell und unbemerkt eine große Menge an Kalorien (genauer gesagt Kohlenhydrate) zu Dir, die Du viel lieber essen könntest und die Dich in fester Form bei der
        Du auch kauen musst, viel satter machen würden. <br /> <br />
        Smoothies - also Frucht-Pürees, bei denen die GANZE Frucht verwendet wurde (inklusive Fasern = Ballaststoffe) sind erlaubt.      </p>

      <h2 className="single-blog-sub">3. WIEDERHOLE DICH</h2>
      <p className="single-blog-text">
        Führe die maximale Anzahl von Wiederholungen durch, die Du in einem bestimmten Lernabschnitt ausführen kannst. Bei einigen Arten des Lernens sind „Wiederholungen“ tatsächliche Wiederholungen von etwas – zum Beispiel das Erlernen von Tonleitern. Bei anderen Arten des Lernens kommen wir linear voran, indem wir den gleichen Prozess wiederholen, wie z. B. Lesen oder Matheaufgaben lösen. <br /> <br />
        Unabhängig davon gilt das gleiche Prinzip: arbeite daran, den Vorgang etwas schneller zu wiederholen, als es für Dich eigentlich richtig anfühlt. Dies hilft dem Geist, nicht von der Aufgabe abzudriften, und hält Dich auf natürliche Weise wach. Wirst Du Fehler machen? Klar! Siehe Nr. 4</p>

      <h2 className="single-blog-sub">4. ERWARTE FEHLER</h2>
      <p className="single-blog-text">
        Solange sie nicht die Sicherheit betreffen, sind Fehler beim Lernen großartig, weil sie die Aktivierung der neuronalen Schaltkreise erhöhen, die die Wachsamkeit erhöhen. Durch Fehler wird die Aufmerksamkeit erhöht, die uns hilft, den nächsten Versuch auszuführen.. <br /> <br />
        Fun Fact: Computermodelldaten deuten darauf hin, dass eine Fehlerquote von ~ 15 % optimal ist und helfen kann, zu bestimmen, wie schwierig wir eine Aufgabe machen sollten.
      </p>

      <h2 className="single-blog-sub">5. ZUFÄLLIGE MICRO-PAUSEN EINFÜGEN</h2>
      <p className="single-blog-text">
        Studien (am Menschen) haben gezeigt, wenn wir das Lernen hin und wieder für 10 Sekunden pausieren und während der Pause nichts tun, Neuronen im Hippocampus und Kortex – Bereiche des Gehirns, die am Lernen und Gedächtnis beteiligt sind – aktiv werden. <br /> <br />
        Das sind dieselben Muster der neuronalen Aktivität, die während der eigentlichen Aktivität des Lesens, Musizierens, Fertigkeitstrainings usw. aufgetreten sind, aber 10-mal schneller. <br /> <br />
        Diese „Gap-Effekte“ ähneln dem, was im Tiefschlaf passiert. <br /> <br />
        Füge also während des Lernens zufällig 10-Sekunden-Pausen ein. <br /> <br />
        Wie oft? 1 Pause pro 2 Minuten Lernen ist gut, aber zufällig einstreuen! </p>

      <h2 className="single-blog-sub">6. VERWENDE ZUFÄLLIGE BELOHNUNGEN</h2>
      <p className="single-blog-text">
        Die neuronalen Schaltkreise, die Belohnungen kontrollieren sind eng mit den Schaltkreisen verbunden, die die Motivation und das Lernen steuern. Halte es wie mit den Pausen. Belohne Dich zufällig und intermittierend (wie Automaten im Casino). Vorhersehbare Belohnungen verlieren schnell ihre Motivationswirkung.
      </p>

      <h2 className="single-blog-sub">7. BESCHRÄNKE DIE LERNEINHEITEN AUF 90 MINUTEN</h2>
      <p className="single-blog-text">
        Untersuchungen zeigen, dass wir uns maximal 90 Minuten am Stück Konzentrieren können, bevor Du eine Pause brauchst.. Platziere intensive Lerneinheiten im Abstand von mindestens 2-3 Stunden. Die meisten Menschen können übrigens nicht mehr als 270 Minuten pro Tag intensiv lernen.
      </p>


      <h2 className="single-blog-sub">8. 8. NACH DEM LERNEN: NSDR-PROTOKOLL</h2>
      <p className="single-blog-text">
        Neuste Studien zeigen: Flache Nickerchen und/oder NSDR (NON SLEEP DEEP REST) können die Geschwindigkeit und Tiefe des Lernens verbessern.
        Führe innerhalb von 1 Stunde nach Abschluss einer Lernrunde ein kurzes NSDR-Protokoll durch.        </p>

      <ul className="single-blog-list">
        <p className="single-blog-text">Beispiele:
        </p>
        <li>Reveri Selbsthypnose-App</li>
        <li>kurzes 20-minütiges Nickerchen,
          NSDR-Skript wie Yoga Nidra an.</li>
      </ul>
      <h2 className="single-blog-sub">9. SCHLAFE GUT</h2>
      <p className="single-blog-text">
        Die Lerneinheit ist der Auslöser fürs Lernen, aber Schlaf und NSDR sind der Zeitpunkt, an dem das eigentliche Lernen – die
        Neuverdrahtung der neuronalen Schaltkreise – stattfindet. Der Schlaf am Tag der Lerneinheit sollte daher besonders gut sein (ausreichend und effizient). Die Nächte danach natürlich auch...      </p>

    </div>
  )
}

export default Superprotkoll