import React from 'react'
import "./SingleBlogs.scss"
import bucketlist from "../../../img/bucketlist.png"
import BackIcon from '../../Icons/BackIcon'


function BucketList() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">SCHREIBE DEINE BUCKET LIST FÜR 2023</h1>
      <img src={bucketlist} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">WOCHE 1</h2>
      <p className="single-blog-text">
        Das Jahr hat gerade begonnen und riecht noch ganz frisch und unverbraucht und lockt mit Hoffnung und Neuanfängen. Nutze diesen - wie wir wissen schnell verblassenden Zauber des Anfangs - und schreibe DEINE BUCKET LIST FÜR 2023. <br /> <br />
        Was ist eine Bucket List? Eine Liste mit Dingen, die Du in diesem Jahr gerne TUN, ERREICHEN ODER ERLEBEN MÖCHTEST. Mach sie so konkret wie möglich:<br /> <br />
        ungünstig: “Ich möchte mehr unternehmen.”<br /> <br />
        sehr gut: “ich möchte dieses Jahr ins Naturkundemuseum. Ich möchte dieses Jahr ein neues Restaurant ausprobieren. Ich möchte dieses Jahr einmal wieder richtig im Club Tanzen gehen.”<br /> <br />
        noch besser: “Ich gehe am Sonntag, den 26.2. ins Naturkundemuseum gehen. Ich möchte am Freitag Abend, den 17.2. das neue Sushi Restaurant in der xxstr. ausprobieren. etc.”<br /> <br />
        Es müssen keine großen Dinge sein. Überlege Dir, worüber Du im Dezember 2023 froh sein wirst, dass Du es getan hast. Speicher diese Liste in den Notizen Deines Handys oder schreib sie handschriftlich auf und häng sie an Deine Pinnwand. Streiche im Laufe des Jahres alles durch, was abgehakt ist. (Oder füge neue Dinge hinzu, die Dir noch einfallen).<br /> <br />
        Vielleicht gibt es ja ein paar Dinge, die Du in den nächsten 4 Wochen während der Challenge schon machen kannst!<br /> <br />
        Teile Deine interessantesten Ideen gerne mit der HiLife Community - und inspiriere die anderen!        </p> <br />
    </div>
  )
}

export default BucketList