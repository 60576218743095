import React from "react";

function GratitudeIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: props.color }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8932 5.33242C27.4623 2.46606 23.9785 1.30262 21.1121 2.73355C20.552 3.01329 20.0408 3.38133 19.5987 3.82415L18.4514 4.97147C18.2006 5.21705 17.7997 5.21705 17.549 4.97147L16.4016 3.82415C14.1341 1.55658 10.4575 1.55658 8.18993 3.82415C5.92237 6.09172 5.92237 9.76829 8.18993 12.0359L16.8142 21.0597C17.4478 21.7146 18.4926 21.732 19.1475 21.0984C19.1604 21.0855 19.1733 21.0726 19.1862 21.0597L27.8104 12.0359C29.5778 10.2678 30.0141 7.56712 28.8932 5.33242"
      />
      <mask
        id="mask0_10_6195"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="14"
        width="13"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.53027 14.6141H14.5965V33.0615H2.53027V14.6141Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10_6195)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.38291 21.4594C7.52113 22.0111 7.21947 23.1275 7.68678 24.0376L9.62047 27.2089C9.82737 27.5415 9.72553 27.9785 9.39358 28.1854C9.39165 28.1867 9.39036 28.1873 9.38842 28.1886C9.03972 28.3807 8.60141 28.2537 8.40869 27.905L6.4879 24.7466C5.90908 23.6225 5.97805 22.2741 6.66837 21.2144C6.74508 21.1004 6.78568 20.9656 6.7844 20.8277V16.7412C6.7844 15.5662 5.83238 14.6141 4.65734 14.6141C3.48231 14.6141 2.53029 15.5662 2.53029 16.7412V24.0376C2.52707 25.4911 3.02273 26.9021 3.93543 28.0339L7.68678 32.7908C7.82085 32.9609 8.02453 33.0608 8.2411 33.0615H13.8875C14.2794 33.0615 14.5965 32.7444 14.5965 32.3525V28.0855C14.5984 26.8221 14.2207 25.5872 13.5136 24.5404C10.8451 21.743 10.0072 20.3894 8.38291 21.4594"
        />
      </g>
      <mask
        id="mask1_10_6195"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="14"
        width="13"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4033 14.6128H33.4695V33.0614H21.4033V14.6128Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_10_6195)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6169 21.4593C28.46 22.0272 28.7384 23.1397 28.2615 24.0376L26.3793 27.2088C26.1724 27.5414 26.2743 27.9784 26.6062 28.1853C26.6082 28.1866 26.6095 28.1873 26.6114 28.1885C26.9504 28.3871 27.3855 28.2781 27.5911 27.9436L29.5119 24.7466C30.0907 23.6225 30.0218 22.2741 29.3314 21.2144C29.2547 21.1003 29.2141 20.9656 29.2154 20.8277V16.7411C29.2148 15.5661 30.1661 14.6134 31.3412 14.6128C32.5156 14.6121 33.4689 15.5635 33.4695 16.7386V16.7411V24.0376C33.4727 25.4911 32.9771 26.902 32.0644 28.0339L28.313 32.7907C28.179 32.9609 27.9753 33.0608 27.7587 33.0614H22.1123C21.7205 33.0614 21.4033 32.7443 21.4033 32.3524V28.0854C21.4014 26.8221 21.7791 25.5871 22.4862 24.5403C25.1547 21.7429 25.9926 20.3894 27.6169 21.4593"
        />
      </g>
    </svg>
  );
}

export default GratitudeIcon;
