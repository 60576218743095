import React from "react";
import { format } from "date-fns";
import { de } from "date-fns/locale";

import CalendarIcon from "../Icons/CalendarIcon";

function Log({ icon, title, gratitudes, reflections, habitSettings }) {
  gratitudes &&
    gratitudes.sort((a, b) => {
      return new Date(a.time) - new Date(b.time);
    });
  reflections &&
    reflections.sort((a, b) => {
      return new Date(a.time) - new Date(b.time);
    });

  const gratitudesLogs =
    gratitudes &&
    gratitudes.map((item) => (
      <article className="py-6 px-5 border-t" key={item._id}>
        <header className="flex items-center gap-2 mb-4">
          <CalendarIcon />
          <span className="text-xs font-extrabold">
            {format(new Date(item.date), "d", { locale: de })}.{" "}
            {format(new Date(item.date), "MMMM", { locale: de })}{" "}
            {format(new Date(item.date), "y", { locale: de })}
          </span>
        </header>

        <ul className="text-xs list-disc ml-4">
          {item.logs.map((log, index) => (
            <li key={index}>{log}</li>
          ))}
        </ul>
      </article>
    ));

  const reflectionsLogs =
    reflections &&
    reflections.map((item, index) => (
      <article className="py-6 px-5 border-t" key={item._id}>
        <header className="flex items-center gap-2 mb-4">
          <CalendarIcon />
          <span className="text-xs font-extrabold">
            {format(new Date(item.date), "d", { locale: de })}.{" "}
            {format(new Date(item.date), "MMMM", { locale: de })}{" "}
            {format(new Date(item.date), "y", { locale: de })}
          </span>
        </header>

        <ul className="text-xs">
          {item.logs.map((log, logIndex) => (
            <li key={logIndex}>
              <b>{habitSettings[index]?.selfReflection[logIndex]}</b>: {log}
            </li>
          ))}
        </ul>
      </article>
    ));
  return (
    <div className="mb-12">
      <div className="flex flex-col items-center">
        {icon}
        <h2 className="text-2xl font-extrabold mb-5 mt-2">{title}</h2>
      </div>
      <div className="border-b overflow-auto max-h-96">
        {gratitudes && gratitudesLogs.reverse()}
        {reflections && reflectionsLogs.reverse()}
      </div>
    </div>
  );
}

export default Log;
