import React from "react";

function ProfileIcon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0181 13.6615C13.9204 13.4179 13.9204 13.1456 14.0181 12.902C14.9099 11.8328 15.3569 10.4614 15.2658 9.07215C15.3694 7.34979 14.0566 5.86937 12.3342 5.76576C12.2263 5.75925 12.1188 5.75816 12.0109 5.76304C10.2869 5.68818 8.82818 7.02484 8.75332 8.74884C8.74844 8.85679 8.74952 8.96474 8.75603 9.07215C8.66436 10.4511 9.10322 11.8133 9.98203 12.8803C10.0764 13.1283 10.0764 13.4027 9.98203 13.6507C7.76873 14.4644 6.58613 15.0394 6.04365 15.9942C5.7838 16.4992 5.60099 17.0406 5.50117 17.5999C5.46971 17.7588 5.51365 17.9232 5.62052 18.0447C5.72142 18.1684 5.87277 18.24 6.0328 18.24H17.9673C18.1273 18.24 18.2787 18.1684 18.3796 18.0447C18.4864 17.9232 18.5304 17.7588 18.4989 17.5999C18.4024 17.0411 18.2233 16.5003 17.9673 15.9942C17.4899 15.0394 16.2531 14.4644 14.0181 13.6615"
      />
    </svg>
  );
}

export default ProfileIcon;
