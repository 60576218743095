import React from "react";

function GroupIcon() {
  return (
    <svg
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-10 h-10"
    >
      <path
        d="M29.7142 2.2312H22.6166C21.4718 2.23119 20.542 3.15564 20.5354 4.30034V8.48673C20.5354 8.81892 20.2661 9.08822 19.9339 9.08822C19.6017 9.08822 19.3324 8.81892 19.3324 8.48673V4.93792C19.3335 4.78495 19.2196 4.65553 19.0678 4.63717H13.7385C13.3809 4.6403 13.0599 4.85715 12.9234 5.18775C12.787 5.51834 12.8616 5.89849 13.113 6.15293L15.2783 8.24613C15.3372 8.30281 15.3704 8.38098 15.3704 8.46267C15.3704 8.54435 15.3372 8.62252 15.2783 8.6792L13.113 10.8205C12.8616 11.075 12.787 11.4551 12.9234 11.7857C13.0599 12.1163 13.3809 12.3331 13.7385 12.3363H22.6286C23.7713 12.3363 24.6977 11.4099 24.6977 10.2671C24.6976 10.1056 24.8251 9.97286 24.9864 9.9664H27.9458C28.1119 9.9664 28.2465 10.101 28.2465 10.2671V29.8036C28.2465 30.468 28.7851 31.0066 29.4495 31.0066C30.1139 31.0066 30.6525 30.468 30.6525 29.8036V3.1575C30.6631 2.90662 30.5672 2.66295 30.3885 2.48655C30.2098 2.31014 29.9649 2.21741 29.7142 2.2312Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.38237 30.2607C2.87296 28.9877 3.80916 27.936 5.0169 27.3014C5.10818 27.2411 5.15534 27.1326 5.1372 27.0247C5.15041 26.9208 5.10385 26.8184 5.0169 26.7601C3.60617 25.6777 3.0416 23.817 3.61308 22.1333C4.18455 20.4495 5.76509 19.3169 7.54317 19.3169C9.32125 19.3169 10.9018 20.4495 11.4733 22.1333C12.0447 23.817 11.4802 25.6777 10.0694 26.7601C9.99104 26.8244 9.95033 26.9239 9.96117 27.0247C9.97383 27.1279 10.0369 27.2181 10.1296 27.2653C10.5614 27.4703 10.9656 27.729 11.3326 28.0352C11.4166 28.1107 11.4534 28.2257 11.4288 28.336C11.241 29.0344 11.144 29.7541 11.1401 30.4773C11.1341 30.5654 11.1341 30.6538 11.1401 30.7419C11.1441 30.8264 11.1137 30.9088 11.0559 30.9705C10.9999 31.0309 10.9217 31.0656 10.8394 31.0667H2.93574C2.74378 31.0659 2.56424 30.9717 2.45455 30.8141C2.34313 30.6519 2.31629 30.4461 2.38237 30.2607ZM5.47404 22.6098C5.38392 22.6372 5.31519 22.7105 5.29359 22.8022C5.1876 23.006 5.11452 23.2252 5.07705 23.4518C5.07705 24.7806 6.15424 25.8578 7.48302 25.8578C8.8118 25.8578 9.88899 24.7806 9.88899 23.4518C9.90188 23.4169 9.90188 23.3785 9.88899 23.3436C9.8508 23.3261 9.80688 23.3261 9.76869 23.3436C9.40208 23.4305 9.02669 23.4749 8.64992 23.4759C7.61614 23.475 6.60484 23.1741 5.73869 22.6098C5.65522 22.5689 5.55752 22.5689 5.47404 22.6098Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8724 30.4772C12.863 27.7994 14.4133 25.3612 16.8422 24.2337C16.9413 24.1856 17.0092 24.0905 17.0227 23.9811C17.0249 23.8703 16.9705 23.766 16.8783 23.7044C16.5446 23.4889 16.2377 23.2345 15.964 22.9466C14.098 20.9623 14.1776 17.8455 16.1424 15.959C18.1073 14.0725 21.2247 14.1199 23.1314 16.0651C25.0382 18.0103 25.0231 21.1281 23.0977 23.0548C22.8717 23.2818 22.6216 23.4835 22.3519 23.6563C22.2584 23.7155 22.207 23.823 22.2195 23.933C22.217 24.0442 22.2843 24.1452 22.388 24.1856C24.8965 25.2725 26.5217 27.7434 26.5262 30.4772C26.5263 30.6357 26.4625 30.7875 26.3494 30.8984C26.2362 31.0093 26.0831 31.0699 25.9247 31.0667H13.4618C13.1363 31.0667 12.8724 30.8028 12.8724 30.4772ZM16.6016 18.4714C16.4656 18.8204 16.3963 19.1917 16.3971 19.5662C16.4052 20.684 17.0049 21.714 17.973 22.2729C18.4485 22.5607 18.9931 22.7146 19.5489 22.718C20.0791 22.7118 20.5996 22.5755 21.0647 22.321C22.0887 21.7646 22.7293 20.6955 22.7368 19.5301C22.7388 19.4695 22.712 19.4115 22.6647 19.3737C22.6111 19.3495 22.5498 19.3495 22.4962 19.3737C21.9813 19.4737 21.4565 19.514 20.9324 19.494C19.5473 19.5068 18.1929 19.0862 17.0588 18.291C16.9742 18.2549 16.8786 18.2549 16.7941 18.291C16.7065 18.3198 16.636 18.3859 16.6016 18.4714Z"
        fill="black"
      />
    </svg>
  );
}

export default GroupIcon;
