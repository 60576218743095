import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useStore } from "../../utils/store";
import HiLifeLogo from "../Icons/HiLifeLogo";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [teamIdentifier, setTeamIdentifier] = useState(null);
  const location = useLocation();

  useEffect(() => {
    let pathParts = location.pathname.split("/");
    if (pathParts[1] === "teams" && pathParts[2]) {
      setTeamIdentifier(pathParts[2]);
    }
  }, [location.pathname]);

  const setUser = useStore((state) => state.setUser);

  const getPostLoginRedirect = (teamIdentifier) => {
    if (teamIdentifier) {
      return `/join-team/${teamIdentifier}`;
    }
    return "/challenge-dashboard";
  };

  window.localStorage.removeItem("pageStore");

  const handleLogin = (event) => {
    event.preventDefault();

    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/login/signin`,
        {
          email: username,
          password: password,
        }
      )
      .then((response) => {
        toast.remove();
        toast.success("Sie wurden erfolgreich eingelogged.");

        setUser(response.data.user);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        navigate(getPostLoginRedirect(teamIdentifier));
      })
      .catch((err) => {
        if (!err.response.data.isUsernameMatch)
          toast.error("Incorrect Username!");
        else if (!err.response.data.isVerified) toast.error("Not Verified!");
        else toast.error("Incorrect Password!");
      });
  };

  return (
    <div className="bg-beige w-full h-full flex items-center justify-center">
      <Toaster />
      <div className="max-w-md w-full p-5">
        <div className="flex justify-center mb-5 w-32 h-32 mx-auto">
          <HiLifeLogo />
        </div>
        <h1 className="text-2xl font-extrabold text-center mb-3">LOGIN</h1>
        <h3 className="login-box-sub text-center font-extrabold mb-8">
          Willkommen zurück!
        </h3>
        <form onSubmit={handleLogin}>
          <div className="mb-5">
            <label htmlFor="email" className="text-sm">
              E-Mail Adresse
            </label>

            <div className="relative mt-2">
              <input
                type="email"
                className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                placeholder="E-Mail Adresse"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="text-sm">
              Passwort
            </label>

            <div className="relative mt-2">
              <input
                type="password"
                className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                placeholder="Passwort"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
          </div>

          <Link
            to={"/forgot-password"}
            className="text-pink font-extrabold w-full block mb-7"
          >
            PASSWORT VERGESSEN?
          </Link>

          <button
            className="bg-pink w-full block text-white font-extrabold p-4 rounded-full mb-8"
            type="submit"
          >
            ANMELDEN
          </button>

          <p className="text-center">Du hast noch keinen Account?</p>
          <Link
            to={"/register"}
            className="text-pink font-extrabold w-full block text-center"
          >
            JETZT REGISTRIEREN
          </Link>
        </form>
      </div>
    </div>
  );
}

export default Login;
