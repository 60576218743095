import React from "react";

function HomeIcon() {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.76 18.76"
    >
      <path
        className="cls-1"
        d="M9.94,.24l8.6,8.99c.22,.23,.28,.56,.16,.85-.12,.29-.41,.48-.72,.48h-1.17c-.22,0-.39,.18-.39,.39v6.64c0,.65-.52,1.17-1.17,1.17H3.51c-.65,0-1.17-.52-1.17-1.17v-6.64c0-.11-.04-.21-.11-.28-.07-.08-.17-.12-.28-.12H.78c-.31,0-.59-.19-.72-.48-.12-.29-.06-.62,.15-.85L8.81,.24c.15-.15,.35-.24,.56-.24s.42,.09,.56,.24Zm2.12,11.4l1.84-1.63v-.03c.14-.16,.19-.38,.13-.58-.09-.22-.31-.36-.55-.35h-2.08c-.16,0-.3-.09-.36-.23l-1.1-2.56c-.12-.2-.33-.32-.56-.32s-.44,.12-.56,.32l-1.09,2.49c-.06,.14-.2,.23-.36,.23h-2.08c-.24-.01-.46,.13-.55,.35-.09,.23-.03,.5,.16,.66l1.81,1.59c.13,.11,.17,.29,.1,.45l-1.02,2.34c-.11,.24-.04,.52,.16,.69,.2,.18,.49,.22,.72,.09l2.53-1.38c.12-.06,.26-.06,.38,0l2.52,1.43c.24,.13,.53,.1,.73-.09,.2-.17,.26-.45,.15-.69l-1.02-2.34c-.06-.16-.02-.33,.1-.45Z"
      />
    </svg>
  );
}

export default HomeIcon;
