import React from 'react'
import "./SingleBlogs.scss"
import hitzeundkaelte2 from "../../../img/hitzeundkaelte2.png"
import BackIcon from '../../Icons/BackIcon'


function HitzeUndKaelte() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Hitze & Kälte</h1>
      <img src={hitzeundkaelte2} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Setze Deinen Körper täglich Hitze und/oder Kälte aus.
      </h2>
      <p className="single-blog-text">
        Das ist wohl die gemeinste Aufgabe der Challenge und gleichzeitig so simpel und potenziell lebensverändernd! <br /> <br />
        Mit dieser Gewohnheit machen wir es Dir und Deinem Schweinehund etwas ungemütlicher, denn es geht um Kälteexposition und Temperaturwechsel. <br /> <br />
        Unsere Vorfahren hatten keine andere Wahl, sie waren den Elementen ausgeliefert und konnten ihre Behausungen oder Höhlen im Winter nicht wohlig warm auf 22° Grad heizen und im Sommer mit Klimaanlagen runterkühlen. <br /> <br />
        Heute leben wir in einer ziemlich eintönigen, wohltemperierten Welt. Wir duschen bei angenehmen 38° Grad, steigen ins Auto, schalten die Klimaanlage oder Sitzheizung ein und laufen dann ca. 10 Sekunden vom Parkplatz zum nächsten wohltemperierten Haus oder Büro. <br /> <br />
        Hört sich gemütlich und angenehm an, oder? Der Schweinehund fühlt sich dabei zumindest sehr wohl. <br /> <br />
        Doch was würdest Du sagen, wenn wir möchten, dass Du Dir Dein Leben etwas ungemütlicher machst? <br /> <br />
        Und wenn das wissenschaftlich erforschte Vorteile für Deine mentale und körperliche Gesundheit hätte? <br /> <br />
        Die einfachste Möglichkeit täglich extreme Temperaturen zu erleben ist: DIE KALTE DUSCHE. <br /> <br />
        Wenn wir “kalt” schreiben, meinen wir eiskalt. Dreh den Duschthermostat so weit nach kalt, bis es nicht mehr weiter geht. Und dann: ATME. 2 Minuten reichen aus um Dein Nervensystem zu aktivieren und die Punkte einzustreichen. <br /> <br />
      </p>
      <ul className="single-blog-list">
        <p className="single-blog-text">Kalte Duschen haben so viele wissenschaftlich bewiesene Vorteile, es wäre verrückt, es nicht zu tun:
        </p>
        <li>stärkt physische und mental Resilienz und Willenskraft</li>
        <li>erhöht den Stoffwechsel</li>
        <li>erhöht Menge an braunen Fettzellen
          (braunen Fettzellen helfen beim
          Abnehmen)</li>
        <li>verbessert die Regeneration nach
          körperlicher Anstrengung (=Sport)</li>
        <li>reduziert Erschöpfungssymptome</li>
        <li>lindert Depressionen, Angstzustände,
          Stress durch Aktivierung des
          Vagus-Nerves</li>
        <li>verbessert die Laune</li>
        <li>stärkt das Immunsystem und schützt
          vor Infekten</li>
        <li>verbessert mentale Klarheit und
          Konzentration</li>
      </ul>


      <p className="single-blog-text">
        Du kannst Dir die Hitze & Kälte Punkte aber auch anders verdienen: ein Eisbad, ein Besuch in der Kryo-Kammer oder in der Sauna sind tolle Möglichkeiten, Deinen Körper extremen Temperaturen auszusetzen. ACHTUNG: Eisbade nie alleine und nur, wenn Du bereits
        Kälte-trainiert bist! <br /> <br />
        Auch wenn es am Anfang schwer ist: Probiere es für 4 Wochen. Dein Körper wird sich daran gewöhnen und Du wirst ganz bestimmt stärker, widerstandsfähiger, wacher und besser gelaunt sein.
      </p> <br />
      <ul className="single-blog-list">
        <p className="single-blog-text">Newbie Tipp: Wenn Du noch nie kalt geduscht hast:
        </p>
        <li>Dusche zuerst normal warm bevor Du auf kalt drehst.</li>
        <li>Beginne mit den Extremitäten und arbeite Dich vorsichtig vor.</li>
        <li>Starte langsam: 15 Sekunden in Woche 1, 30 Sek in Woche 2, 60 Sek in Woche 3 und dann 120 Sek in Woche 4.</li>
      </ul>

    </div>
  )
}

export default HitzeUndKaelte