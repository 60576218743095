import React, { useState, useMemo } from "react";
import {
  getTime,
  startOfWeek,
  endOfDay,
  parseISO,
  nextWednesday,
  isBefore,
  isAfter,
  differenceInDays,
} from "date-fns";

import "./styles/weeklie.scss";
import ActionTile from "../ActionTile";
import WeekliesModals from "./components/WeekliesModals";
import KnowledgeIcon from "../Icons/KnowledgeIcon";
import ActionIcon from "../Icons/ActionIcon";
import NatureIcon from "../Icons/NatureIcon";
import ReflectionIcon from "../Icons/ReflectionIcon";
import Countdown from "../Countdown/Countdown";

function Weeklies({
  updateWeeklie,
  currentWeek,
  userChallenge,
  user,
  challenge,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const isHabitChecked = (habit) => {
    if (habit === "nature") {
      const natureLog = userChallenge.weeklyHabits[habit].find(
        (nature) => nature.week === currentWeek
      );
      return natureLog && natureLog.minutes >= 120;
    } else if (habit === "reflection") {
      return (
        userChallenge.weeklyHabits[habit] &&
        userChallenge.weeklyHabits[habit].some(
          (reflection) =>
            reflection.week === currentWeek && reflection.points > 0
        )
      );
    } else {
      return (
        userChallenge.weeklyHabits[habit] &&
        userChallenge.weeklyHabits[habit][currentWeek] &&
        userChallenge.weeklyHabits[habit][currentWeek] > 0
      );
    }
  };

  // useMemo to calculate time until next weekly reset
  const nextCountdownEnd = useMemo(() => {
    const challengeStartDate = parseISO(challenge.startDate);
    const challengeEndDate = parseISO(challenge.endDate);
    const today = new Date();

    // Calculate days since challenge start
    const daysSinceStart = differenceInDays(today, challengeStartDate);
    const currentDay = daysSinceStart + 1; // Current day in the challenge

    // Calculate days remaining until next 7-day cycle
    const daysUntilNextWeek = 7 - (currentDay % 7);

    // Calculate the next reset date by adding remaining days
    let nextReset = new Date(today);
    nextReset.setDate(today.getDate() + daysUntilNextWeek);
    nextReset.setHours(0, 0, 0, 0);

    // Ensure countdown does not exceed the challenge end date
    if (isAfter(nextReset, challengeEndDate)) {
      nextReset = challengeEndDate;
    }

    return getTime(nextReset);
  }, [challenge.startDate, challenge.endDate]);

  return (
    <div className="weeklie text-center">
      <div className="weeklie-action my-3">
        <div className="weeklie-timer">
          <Countdown countdownTimestampMs={nextCountdownEnd} weeklie hideText />
          <p className="weeklie-timer-text">TIME TO TRACK YOUR</p>
          <p className="weeklie-timer-title">WEEKLIES</p>
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-4 lg:max-w-2xl lg:mx-auto">
        {/* Action tiles for different habits */}
        <ActionTile
          icon={<KnowledgeIcon />}
          onClick={() => setModalOpen("workshop")}
          checked={isHabitChecked("workshop")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
        <ActionTile
          icon={<ActionIcon />}
          onClick={() => setModalOpen("action")}
          checked={isHabitChecked("action")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
        <ActionTile
          icon={<NatureIcon />}
          onClick={() => setModalOpen("nature")}
          checked={isHabitChecked("nature")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
          nature={
            userChallenge.weeklyHabits.nature.find(
              (nature) => nature.week === currentWeek
            )?.minutes || 0
          }
        />

        <ActionTile
          icon={<ReflectionIcon />}
          onClick={() => setModalOpen("reflection")}
          checked={isHabitChecked("reflection")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
      </div>

      <WeekliesModals
        open={modalOpen}
        setOpen={setModalOpen}
        updateWeeklie={updateWeeklie}
        currentWeek={currentWeek}
        user={user}
        userChallenge={userChallenge}
      />
    </div>
  );
}

export default Weeklies;
