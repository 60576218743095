import React from "react";

function EditIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0052 0.523773C5.65098 0.523773 0.5 5.67475 0.5 12.029C0.5 18.3832 5.65098 23.5342 12.0052 23.5342C18.3594 23.5342 23.5104 18.3832 23.5104 12.029C23.5104 5.67475 18.3594 0.523773 12.0052 0.523773Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4988 8.55824L16.6935 9.35402C16.6057 9.4451 16.4845 9.49688 16.3579 9.49783C16.2309 9.49927 16.1091 9.44702 16.0223 9.35402L14.4499 7.75288C14.262 7.5664 14.2606 7.26295 14.4471 7.07503C14.448 7.07407 14.449 7.07311 14.4499 7.07215L15.2553 6.27638C15.5597 5.97724 15.9691 5.8085 16.3962 5.80658C17.2913 5.81185 18.0122 6.54147 18.007 7.43648C18.0046 7.86074 17.8358 8.26678 17.5372 8.56783L17.4988 8.55824Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25239 16.8995L7.31662 14.2246C7.34395 14.1656 7.37942 14.1105 7.42209 14.0616L13.0692 8.41443C13.2591 8.23035 13.5601 8.23035 13.75 8.41443L15.399 10.0156C15.4844 10.1067 15.5318 10.2265 15.5333 10.3511C15.5347 10.4791 15.4863 10.6028 15.399 10.6963L9.75189 16.3434C9.70635 16.3904 9.65074 16.4264 9.5889 16.4489L6.91394 17.5227C6.73561 17.5918 6.53331 17.5505 6.39621 17.4173C6.24185 17.2936 6.18432 17.085 6.25239 16.8995Z"
        fill="white"
      />
    </svg>
  );
}

export default EditIcon;
