import React from 'react'
import "./SingleBlogs.scss"
import noscreen from "../../../img/noscreen.png"
import BackIcon from '../../Icons/BackIcon'


function NoScreen() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">NO SCREEN NIGHTS</h1>
      <img src={noscreen} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">WOCHE 4</h2>
      <p className="single-blog-text">
        Diese Aufgabe ist für viele vielleicht die schwierigste - aber nach 3 Wochen Kampf gegen den Schweinehund, eiskalten Duschen und zuckerfreier Ernährung ist auch diese Challenge für Dich ganz sicher machbar und hoffentlich sehr wertvoll!<br /> <br /> </p>

      <p className="single-blog-text">
In dieser Woche wollen wir Deine Screen-Time reduzieren. Wenn Du es schaffst, diese Woche jeden Tag nach 20:00 bis zum Schlafengehen keinen Bildschirm mehr vor der Nase zu haben, erhältst Du 5 Punkte!        </p>
      <br />
      <h2 className="single-blog-sub">SCALED VERSION</h2> <br/>



        <p className="single-blog-text">
        <b>Wir wissen: Das sind echt viele NoScreen!</b> Und deswegen haben wir für alle, die noch nicht ganz routiniert im Training sind und diesen Umfang nicht gewohnt sind, eine skalierte Version: 150 NoScreen reichen für Deine Action Punkte diese Woche aus.
        <br />
        </p>

      <p className="single-blog-text">
        Du sollst dabei auf <b>ALLE</b> Bildschirme verzichten, d.h. auf Film, Fernsehen UND Social Media. Kein passiver Medienkonsum, kein Berieseln lassen, kein Mindless Scrolling.        </p>

      <br />

      <p className="single-blog-text">Vielleicht könnte es sein, dass Du während Deiner abendlichen Medien Abstinenz ein paar Dinge feststellen wirst:<br />
      </p>
      <ul className="single-blog-list">
        <li>dass Du auf einmal ziemlich viel Zeit hast </li>
        <li>dass Du bessere Laune hast</li>
        <li>dass Du Dich weniger mit anderen vergleichst</li>
        <li>dass Du zufriedener bist</li>
        <li>dass Du ausgeglichener bist</li>
        <li>dass Du produktiver bist</li>

        <li>dass Lesen/Meditieren/Tagebuch schreiben/Musik hören/eine Unterhaltung führen/telefonieren viel erfüllender ist</li>
        <li>dass Du besser schläfst</li>
      </ul>

      <br /> <br />
      <p className="single-blog-text">Was Du statt Instagram Scrolling oder Netflix tun kannst? Hier ein paar Vorschläge:<br />
      </p>
      <ul className="single-blog-list">
        <li>ein Buch lesen</li>
        <li>Dich mit einem netten Menschen unterhalten</li>
        <li>Spiel spielen</li>
        <li>Tagebuch schreiben</li>
        <li>Musik hören</li>
        <li>meditieren</li>
        <li>malen</li>
      </ul>
      <p className="single-blog-text">und so weiter...</p> <br/>

      <p className="single-blog-text"> <i>Das Kleingedruckte zur Sonderaufgabe: Musst Du beruflich bei Social Media sein, dann darfst Du das natürlich. Wichtig ist hier, dass Du eine ganz klare Grenze ziehst und KEINERLEI PRIVATE ZEIT dort verbringst. Kein "mindless scrolling" erlaubt. Versuche trotzdem, die Stunden VOR dem Zubettgehen ohne Medien zu verbringen. </i></p> <br />

      <p className="single-blog-text"> <i>Über reine Kommunikations-Tools wie Whatsapp, Zoom etc. darfst Du natürlich weiter mit Deiner Umwelt kommunizieren und DEINE PUNKTE EINTRAGEN selbstverständlich auch. Nur eben nicht passiv konsumieren. Ein kurzes HALLO und Lebenszeichen in die HiLife Gruppe geht natürlich auch klar.</i></p> <br />


    <br/> <br/>
      
    </div>
  )
}

export default NoScreen