import React, { useRef, useState, useEffect } from "react";
import "./RankView.scss";
import FooterNavigation from "../../Components/FooterNavigation/FooterNavigation";
import TotalRank from "../../Components/TotalRank/TotalRank";
import HeaderNavigation from "../../Components/HeaderNavigation/HeaderNavigation";
import TotalRankTeam from "../../Components/TotalRankTeam/TotalRankTeam";

function RankView() {
  const footerNavigationRef = useRef(null);
  const ownRankRef = useRef(null);
  const [footerNavigationHeight, setFooterNavigationHeight] = useState(0);
  const [ownRankHeight, setOwnRankHeight] = useState(0);
  const [switchToggle, setSwitchToggle] = useState(false);

  useEffect(() => {
    setFooterNavigationHeight(footerNavigationRef?.current?.clientHeight);
    setOwnRankHeight(ownRankRef?.current?.clientHeight);
  }, [footerNavigationHeight, ownRankHeight]);

  return (
    <>
      <HeaderNavigation
        ranking
        setSwitchToggle={setSwitchToggle}
        switchToggle={switchToggle}
      />
      <div
        className="page-container"
        style={{ paddingBottom: footerNavigationHeight + ownRankHeight + "px" }}
      >
        <div className="rankview">
          {switchToggle ? (
            <TotalRankTeam
              ownRankRef={ownRankRef}
              footerNavigationHeight={footerNavigationHeight}
            />
          ) : (
            <TotalRank
              ownRankRef={ownRankRef}
              footerNavigationHeight={footerNavigationHeight}
            />
          )}
        </div>
      </div>
      <FooterNavigation ref={footerNavigationRef} />
    </>
  );
}

export default RankView;
