import React from "react";

function BookmarkIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2286 6.85713C20.2286 5.721 19.3076 4.79999 18.1715 4.79999H5.82863C4.6925 4.79999 3.77148 5.721 3.77148 6.85713V17.1428C3.77148 18.279 4.6925 19.2 5.82863 19.2H18.1715C19.3076 19.2 20.2286 18.279 20.2286 17.1428V6.85713ZM5.82863 7.19999C5.82863 6.82128 6.13563 6.51427 6.51434 6.51427C6.89305 6.51427 7.20006 6.82128 7.20006 7.19999C7.20006 7.5787 6.89305 7.8857 6.51434 7.8857C6.13563 7.8857 5.82863 7.5787 5.82863 7.19999ZM8.57148 6.51427C8.19277 6.51427 7.88577 6.82128 7.88577 7.19999C7.88577 7.5787 8.19277 7.8857 8.57148 7.8857C8.95019 7.8857 9.2572 7.5787 9.2572 7.19999C9.2572 6.82128 8.95019 6.51427 8.57148 6.51427ZM9.94291 7.19999C9.94291 6.82128 10.2499 6.51427 10.6286 6.51427C11.0073 6.51427 11.3143 6.82128 11.3143 7.19999C11.3143 7.5787 11.0073 7.8857 10.6286 7.8857C10.2499 7.8857 9.94291 7.5787 9.94291 7.19999ZM18.1715 17.8286C18.5502 17.8286 18.8572 17.5216 18.8572 17.1428V9.94285C18.8572 9.75349 18.7037 9.59999 18.5143 9.59999H5.48577C5.29642 9.59999 5.14291 9.75349 5.14291 9.94285V17.1428C5.14291 17.5216 5.44992 17.8286 5.82863 17.8286H18.1715Z"
        fill="black"
      />
      <path
        d="M13.0558 16.4365C13.1291 16.4694 13.2087 16.4858 13.289 16.4845C13.4468 16.4842 13.5977 16.4199 13.7073 16.3063L14.9621 15.0514C15.0324 14.9885 15.1387 14.9885 15.209 15.0514L16.4638 16.3063C16.6335 16.4795 16.892 16.5312 17.1153 16.4365C17.3404 16.3443 17.487 16.1245 17.4856 15.8811V10.5051C17.4856 10.3158 17.3321 10.1623 17.1427 10.1623H13.0284C12.8391 10.1623 12.6856 10.3158 12.6856 10.5051V15.8811C12.6842 16.1245 12.8307 16.3443 13.0558 16.4365V16.4365Z"
        fill="black"
      />
    </svg>
  );
}

export default BookmarkIcon;
