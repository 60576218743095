import React from "react";

import Modal from "../../../Modal";
import BookmarkModal from "./components/BookmarkModal";
import DietModal from "./components/DietModal";
import LifestyleCheckModal from "./components/LifestyleCheck";
import ProfileModal from "./components/ProfileModal";
import RulesModal from "./components/RulesModal";
import TeamModal from "./components/TeamModal";
import KeepUpModal from "./components/KeepUpModal";
import LifestyleCheckModal2 from "./components/LifestyleCheck2";

function LifestyleModals({
  open,
  setOpen,
  user,
  setUser,
  challenge,
  setUserChallenge,
  userChallenge,
}) {
  return (
    <Modal open={open} keepUpModal={open === "keepup"}>
      {open === "profile" && (
        <ProfileModal
          setOpen={setOpen}
          user={user}
          setUser={setUser}
          challenge={challenge}
          setUserChallenge={setUserChallenge}
          userChallenge={userChallenge}
        />
      )}
      {open === "diet" && (
        <DietModal
          setOpen={setOpen}
          checked={userChallenge.diet}
          user={user}
          setUser={setUser}
          challenge={challenge}
          userChallenge={userChallenge}
          setUserChallenge={setUserChallenge}
        />
      )}
      {open === "lifestyle" && (
        <LifestyleCheckModal
          setOpen={setOpen}
          checked={userChallenge.lifestyleScore}
          user={user}
          setUser={setUser}
          challenge={challenge}
          userChallenge={userChallenge}
          setUserChallenge={setUserChallenge}
        />
      )}
      {open === "lifestyle2" && (
        <LifestyleCheckModal2
          setOpen={setOpen}
          checked={userChallenge.lifestyleScore2}
          user={user}
          setUser={setUser}
          challenge={challenge}
          userChallenge={userChallenge}
          setUserChallenge={setUserChallenge}
        />
      )}
      {open === "bookmark" && (
        <BookmarkModal
          setOpen={setOpen}
          checked={userChallenge.bookmark}
          user={user}
          setUser={setUser}
          challenge={challenge}
          userChallenge={userChallenge}
          setUserChallenge={setUserChallenge}
        />
      )}

      {open === "rules" && (
        <RulesModal
          setOpen={setOpen}
          checked={userChallenge.rules}
          user={user}
          setUser={setUser}
          challenge={challenge}
          userChallenge={userChallenge}
          setUserChallenge={setUserChallenge}
        />
      )}

      {open === "fbgroup" && (
        <TeamModal
          setOpen={setOpen}
          checked={userChallenge.fbgroup}
          user={user}
          setUser={setUser}
          challenge={challenge}
          userChallenge={userChallenge}
          setUserChallenge={setUserChallenge}
        />
      )}

      {open === "keepup" && <KeepUpModal setOpen={setOpen} />}
    </Modal>
  );
}

export default LifestyleModals;
