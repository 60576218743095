import React, { useState } from "react";
import { useStore } from "../../utils/store";

import "./styles/lifestyle-setup.scss";
import ActionTile from "../ActionTile";
import ProfileIcon from "../Icons/ProfileIcon";
import LifestyleModals from "./components/LifestyleModals";
import LifestyleIcon from "../Icons/LifestyleIcon";
import DietIcon from "../Icons/DietIcon";
import BookmarkIcon from "../Icons/BookmarkIcon";
import TeamIcon from "../Icons/TeamIcon";
import RulesIconHomepage from "../Icons/RulesIconHomepage";

function LifestyleSetup({ challenge }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useStore((state) => [state.user, state.setUser]);
  const userChallenge = useStore((state) => state.userChallenge);
  const setUserChallenge = useStore((state) => state.setUserChallenge);

  return (
    <>
      <div className="flex flex-wrap justify-center gap-4 mt-7 lg:mt-12 lg:max-w-screen-lg lg:mx-auto lg:mb-12">
        <ActionTile
          icon={<ProfileIcon />}
          label={"Profil einrichten"}
          onClick={() => setModalOpen("profile")}
          checked={user.bday && user.gender && user.avatar}
          classes={"max-w-[30.33%] lg:max-w-[14.66%] w-full flex-auto"}
          smallIcon
        />
        <ActionTile
          icon={<RulesIconHomepage />}
          label={"Regelwerk lesen"}
          onClick={() => setModalOpen("rules")}
          checked={userChallenge.rules}
          classes={"max-w-[30.33%] lg:max-w-[14.66%] w-full flex-auto"}
          smallIcon
        />
        <ActionTile
          icon={<LifestyleIcon />}
          label={"Lifestyle Check"}
          onClick={() => setModalOpen("lifestyle")}
          checked={userChallenge?.lifestyleScore?.length === 10}
          classes={"max-w-[30.33%] lg:max-w-[14.66%] w-full flex-auto"}
          smallIcon
        />
        <ActionTile
          icon={<DietIcon />}
          label={"Ernährungsplan"}
          onClick={() => setModalOpen("diet")}
          checked={userChallenge.diet}
          classes={"max-w-[30.33%] lg:max-w-[14.66%] w-full flex-auto"}
          smallIcon
        />
        <ActionTile
          icon={<BookmarkIcon />}
          label={"Bookmark"}
          onClick={() => setModalOpen("bookmark")}
          checked={userChallenge.bookmark}
          classes={"max-w-[30.33%] lg:max-w-[14.66%] w-full flex-auto"}
          smallIcon
        />
        <ActionTile
          icon={<TeamIcon />}
          label={"Community Chat"}
          onClick={() => setModalOpen("fbgroup")}
          checked={userChallenge.fbgroup}
          classes={"max-w-[30.33%] lg:max-w-[14.66%] w-full flex-auto"}
          smallIcon
        />
      </div>

      <LifestyleModals
        open={modalOpen}
        setOpen={setModalOpen}
        user={user}
        setUser={setUser}
        challenge={challenge}
        setUserChallenge={setUserChallenge}
        userChallenge={userChallenge}
      />
    </>
  );
}

export default LifestyleSetup;
