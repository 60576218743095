import React from "react";

function DietIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9996 7.14649C16.2977 7.14649 18.932 8.53298 18.932 9.57284C18.932 10.6127 16.2977 11.9992 11.9996 11.9992C7.70147 11.9992 5.06715 10.6127 5.06715 9.57284C5.06715 8.53298 7.70147 7.14649 11.9996 7.14649M11.9996 5.76001C7.9649 5.76001 3.68066 7.09797 3.68066 9.57284V10.2661C3.78292 14.7618 7.50355 18.3275 11.9996 18.2384C16.4956 18.3275 20.2162 14.7618 20.3185 10.2661V9.57284C20.3185 7.09797 16.0342 5.76001 11.9996 5.76001"
        fill="#1B1B1B"
      />
    </svg>
  );
}

export default DietIcon;
