import React from "react";

function LifestyleIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.23032" cy="12" r="1.38462" fill="black" />
      <ellipse
        cx="6.23032"
        cy="16.6154"
        rx="1.38462"
        ry="1.38461"
        fill="black"
      />
      <path
        d="M18.2304 11.0769H9.46116C8.95136 11.0769 8.53809 11.4902 8.53809 12C8.53809 12.5098 8.95136 12.9231 9.46116 12.9231H18.2304C18.7402 12.9231 19.1535 12.5098 19.1535 12C19.1535 11.4902 18.7402 11.0769 18.2304 11.0769V11.0769Z"
        fill="black"
      />
      <circle cx="6.23032" cy="7.38462" r="1.38462" fill="black" />
      <path
        d="M18.2304 6.46155H9.46116C8.95136 6.46155 8.53809 6.87482 8.53809 7.38462C8.53809 7.89443 8.95136 8.3077 9.46116 8.3077H18.2304C18.7402 8.3077 19.1535 7.89443 19.1535 7.38462C19.1535 6.87482 18.7402 6.46155 18.2304 6.46155Z"
        fill="black"
      />
      <path
        d="M18.2304 15.6923H9.46116C8.95136 15.6923 8.53809 16.1056 8.53809 16.6154C8.53809 17.1252 8.95136 17.5385 9.46116 17.5385H18.2304C18.7402 17.5385 19.1535 17.1252 19.1535 16.6154C19.1535 16.1056 18.7402 15.6923 18.2304 15.6923Z"
        fill="black"
      />
    </svg>
  );
}

export default LifestyleIcon;
