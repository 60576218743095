import React from "react";

function CrossIcon() {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.5" cy="12" r="11.5" fill="#1B1B1B" />
      <path
        d="M14.2104 7.27274L11.684 9.79914C11.5863 9.89685 11.4278 9.89685 11.3301 9.79914L8.80373 7.27274C8.42678 6.89485 7.8196 6.87928 7.42377 7.23736L6.7161 7.94503C6.35802 8.34086 6.37358 8.94804 6.75148 9.325L9.27787 11.8514C9.37558 11.9491 9.37558 12.1075 9.27787 12.2052L6.77979 14.7033C6.40189 15.0803 6.38632 15.6874 6.74441 16.0833L7.45208 16.7909C7.8479 17.149 8.45509 17.1335 8.83204 16.7556L11.3584 14.2292C11.4561 14.1315 11.6146 14.1315 11.7123 14.2292L14.2387 16.7556C14.6201 17.1015 15.2018 17.1015 15.5832 16.7556L16.2909 16.0479C16.6368 15.6664 16.6368 15.0848 16.2909 14.7033L13.7362 12.1486C13.6385 12.0509 13.6385 11.8925 13.7362 11.7948L16.2626 9.26838C16.6085 8.88693 16.6085 8.30526 16.2626 7.9238L15.5549 7.21613C15.1591 6.88586 14.5771 6.91037 14.2104 7.27274Z"
        fill="white"
      />
    </svg>
  );
}

export default CrossIcon;
