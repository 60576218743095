import React from "react";
import { useEffect } from "react";
import logo from "./img/logo.svg";
import "./NotPaid.scss";

function NotPaid() {
  useEffect(() => {
    setTimeout(() => {
      window.location = "/dashboard";
    }, 5000);
  }, []);

  return (
    <div className="notpaid">
      <img src={logo} alt="" className="notpaid-logo" />
      <h2 className="notpaid-title">BEZAHLUNG</h2>
      <p className="notpaid-text">
        Das hat leider nicht geklappt. Du wirst automatisch weitergeleitet.{" "}
        <br />
        <br />
        <a href="/dashboard">Versuche es erneut.</a>
      </p>
    </div>
  );
}

export default NotPaid;
