import classNames from "classnames";
import React from "react";
import ResetIcon from "../../../../../Icons/ResetIcon";
import TrainingIcon from "../../../../../Icons/TrainingIcon";

function TrainingModal({
  setOpen,
  habit,
  updateHabit,
  userChallenge,
  currentDay,
}) {
  const isHabitChecked = () => {
    return (
      userChallenge.dailyHabits["training"] &&
      userChallenge.dailyHabits["training"][currentDay] &&
      userChallenge.dailyHabits["training"][currentDay] > 0
    );
  };

  return (
    <>
      {!isHabitChecked() ? (
        <div>
          <div className="w-8 w-8 mx-auto mb-4">
            <TrainingIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">BEWEGUNG</h1>

          <h5 className="text-sm mt-4 mb-6 font-extrabold text-center">
            Hast Du Dich heute 15 Minuten sportlich betätigt?
          </h5>

          <p className="text-sm text-center">
            Wenn Du Dich heute mindestens 15 Minuten mit Intention bewegt hast,
            klicke auf "FERTIG" und Du erhältst 4 Punkte.
          </p>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out mb-5"
            )}
            onClick={() => {
              updateHabit(4);
              setOpen(false);
            }}
          >
            FERTIG
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen("keepup")}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="w-full text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PUNKTE WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du deine Punkte zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => {
              updateHabit(0);
              setOpen(false);
            }}
          >
            PUNKTE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default TrainingModal;
