import dayjs from "dayjs";

export function getRemainingTimeUntilMsTimestamp(timestampMs) {
  const timestamp = dayjs(timestampMs);
  const nowDayjs = dayjs();

  if (timestamp.isBefore(nowDayjs)) {
    return {
      seconds: "00",
      minutes: "00",
      hours: "00",
      days: "00",
    };
  }

  // Get the difference in days directly
  const daysRemaining = Math.ceil(timestamp.diff(nowDayjs, "day", true));

  // Get remaining hours after removing full days
  const totalHours = timestamp.diff(nowDayjs, "hour");
  const hours = totalHours % 24;

  // Get remaining minutes and seconds
  const minutes = timestamp.diff(nowDayjs, "minutes") % 60;
  const seconds = timestamp.diff(nowDayjs, "seconds") % 60;

  return {
    seconds: padWithZeros(seconds, 2),
    minutes: padWithZeros(minutes, 2),
    hours: padWithZeros(hours, 2),
    days: padWithZeros(daysRemaining, 2),
  };
}

function padWithZeros(number, minLength) {
  const numberString = number.toString();
  if (numberString.length >= minLength) return numberString;
  return "0".repeat(minLength - numberString.length) + numberString;
}
