import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStore } from "../../utils/store";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./SeeOrders.scss";

function SeeOrders(props) {
  const [user, setUser] = useStore((state) => [state.user, state.setUser]);
  const [tickets, setTickets] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/ticket/get/tickets`,
          {
            userId: user._id,
          }
        );
        setTickets(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchTickets();
  }, [user._id]);

  useEffect(() => {
    if (selectedTicketId) {
      const fetchInvoice = async () => {
        try {
          const response = await axios.get(
            `${
              process.env.NODE_ENV === "production"
                ? "api"
                : "http://localhost:5001/api"
            }/ticket/get/invoice/${selectedTicketId}`,
            { responseType: "blob" }
          );
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          setPdfData(URL.createObjectURL(pdfBlob));
        } catch (err) {
          console.error(err);
        }
      };

      fetchInvoice();
    } else {
      setPdfData(null);
    }
  }, [selectedTicketId]);

  const handleButtonClick = (id) => {
    if (selectedTicketId === id) {
      setSelectedTicketId(null);
    } else {
      setSelectedTicketId(id);
    }
  };

  return (
    <>
      {tickets.length > 0 ? (
        <div className="border-b">
          {tickets.map((item) => (
            <div
              key={item.invoiceNo}
              className="bg-white text-center p-4 border-t border-b mb-4"
            >
              <h3 className="text-lg font-extrabold">{item.item}</h3>
              <p>
                gekauft am {format(new Date(item.date), "P", { locale: de })}
              </p>
              <button
                className="orders-button"
                onClick={() => handleButtonClick(item._id)}
              >
                {selectedTicketId === item._id
                  ? "Rechnung schließen"
                  : "Rechnung anzeigen"}
              </button>
              {selectedTicketId === item._id && pdfData && (
                <div className="orders-pdf">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={pdfData}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p className="orders-text">Du hast noch keine Einkäufe.</p>
        </div>
      )}
    </>
  );
}

export default SeeOrders;
