import React from "react";

function CrownIcon() {
  return (
    <svg
      width="29"
      height="15"
      viewBox="0 0 29 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="crown-icon"
    >
      <path
        d="M2.60114 15H26.4605C27.6064 15 28.5353 14.0711 28.5353 12.9253V1.72172C28.5375 1.31877 28.3015 0.952558 27.9336 0.788093C27.5595 0.622072 27.1232 0.686712 26.8132 0.954071L20.5891 6.36912C20.4813 6.44992 20.3503 6.4936 20.2156 6.4936C20.0785 6.47874 19.9523 6.41192 19.8629 6.30688L14.8006 0.352399C14.5998 0.128157 14.3131 0 14.0122 0C13.7112 0 13.4245 0.128157 13.2238 0.352399L8.16142 6.28613C8.06489 6.39023 7.92992 6.45022 7.78796 6.45211C7.64118 6.46725 7.49564 6.41363 7.39377 6.30688L2.28993 1.22379C1.99501 0.928027 1.54447 0.852938 1.16957 1.03706C0.778419 1.19153 0.522757 1.5709 0.526406 1.99144V12.9668C0.549002 14.0964 1.47131 15.0002 2.60114 15Z"
        fill="black"
      />
    </svg>
  );
}

export default CrownIcon;
