import React from "react";
import "./PayWall.scss";
import Stripe from "../Stripe/Stripe";
import Footer from "../Footer/Footer";
import HiLifeLogo from "../Icons/HiLifeLogo";
import { useLocation } from "react-router-dom";

function PayWall() {
  const location = useLocation();
  const challengeId = location.state.challengeId;
  return (
    <div className="paywall center-absolute">
      <h2 className="paywall-title">BEZAHLUNG</h2>
      <div className="flex justify-center mb-5 w-32 h-32 mx-auto">
        <HiLifeLogo />
      </div>
      <p className="paywall-text">Bezahle jetzt Deine Challenge</p>
      <Stripe />
      <Footer />
    </div>
  );
}

export default PayWall;
