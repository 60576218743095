import React from "react";
import "./SingleBlogs.scss";
import regeln from "../../../img/regeln.png";
import BackIcon from "../../Icons/BackIcon";

function ChallengeRegeln() {
  return (
    <div className="single-blog">
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Die Regeln</h1>
      <img src={regeln} alt="" className="single-blog-image" />
      <p className="single-blog-text">
        Sammle täglich Punkte für gesunde Habits für Dich. Für die verschiedenen
        Habits gibt es unterschiedliche Punkte - je nach Wichtigkeit oder
        Aufwand.
      </p>
      <h2 className="single-blog-sub">ERNÄHRUNG (6 Punkte)</h2>
      <p className="single-blog-text">
        Halte Dich täglich an den von Dir selbst ausgewählten Ernährungsplan.
        <br />
        Für einen perfekten Ernährungstag erhältst Du 6 Punkte. Hast Du maximal
        2 Lebensmittel von der NO-Liste getrunken/gegessen, erhältst Du für den
        Tag 3 Punkte.
      </p>
      <h2 className="single-blog-sub">BEWEGUNG (4 Punkte)</h2>
      <p className="single-blog-text">
        Bewege Dich täglich min. 15 min mit Intention.
      </p>

      <h2 className="single-blog-sub">SCHLAF (3 Punkte)</h2>
      <p className="single-blog-text">Schlafe täglich mindestens 7 Stunden. </p>

      <h2 className="single-blog-sub">HITZE & KÄLTE (1 Punkt)</h2>
      <p className="single-blog-text">
        Setze Deinen Körper täglich Hitze oder Kälte aus.
      </p>

      <h2 className="single-blog-sub">ATMUNG (1 Punkt)</h2>
      <p className="single-blog-text">
        Konzentriere Dich täglich 5 Minuten lang auf Deine Atmung.
      </p>

      <h2 className="single-blog-sub">DANKBARKEIT (1 Punkt)</h2>
      <p className="single-blog-text">
        Notiere täglich 3 Dinge, für die Du dankbar bist.
      </p>

      <h2 className="single-blog-sub">NATUR (5 Punkte)</h2>
      <p className="single-blog-text">
        Verbringe jede Woche 120 min in der Natur.
      </p>

      <h2 className="single-blog-sub">WISSEN (10 Punkte)</h2>
      <p className="single-blog-text">
        Lerne Neues und nimm an den wöchentlichen Workshops teil.
      </p>

      <h2 className="single-blog-sub">ACTION (5 Punkte)</h2>
      <p className="single-blog-text">
        Werde aktiv und stell Dich der wöchentlichen Aufgabe.
      </p>

      <h2 className="single-blog-sub">REFLEXION (2 Punkte)</h2>
      <p className="single-blog-text">
        Reflektiere Dich und Dein Leben mit unseren drei wöchentlichen Fragen.
      </p>
    </div>
  );
}

export default ChallengeRegeln;
