import React from 'react'
import "./SingleBlogs.scss"
import schlaf from "../../../img/schlaf.png"
import BackIcon from '../../Icons/BackIcon'


function Schlaf() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Schlaf</h1>
      <img src={schlaf} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Schlafe täglich mindestens 7 Stunden.</h2>
      <p className="single-blog-text">
        Ausreichend und qualitativ hochwertiger Schlaf bildet neben Ernährung und Bewegung die Basis unserer Gesundheit.< br />
        Schlaf ist essenziell für die grundlegende Regeneration und Reparatur des neurologischen, endokrinen, Immun- und Verdauungssystems sowie des Bewegungsapparates. Du kannst Dich perfekt ernähren und alle Nahrungsergänzungsmittel der Welt nehmen, aber wenn Du nicht gut schläfst und Deinen Stress regulierst, hilft das alles ziemlich wenig. <br /> <br />
        Normalerweise schüttet der Körper das Hormon Melatonin vermehrt nach Sonnenuntergang und in der Nacht aus, was das Immunsystem stärkt und uns gegen Infekte schützt. Deswegen ist es wahrscheinlicher krank zu werden, wenn Du ein paar Nächte hintereinander schlecht geschlafen hast.
      </p> <br />


      <ul className="single-blog-list">
        <p className="single-blog-text">Was eine Nacht mit ausreichendem, guten Schlaf für Dich bedeutet:
        </p>
        <li>verbesserte Gedächtnisfähigkeit und mentale Klarheit</li>
        <li>verbesserte körperliche Leistungsfähigkeit</li>
        <li>verbesserte Laune und Energie</li>
        <li>verbesserte Immunfunktion</li>
        <li>erhöhte Stresstoleranz</li>
      </ul>

      <p className="single-blog-text">
        Weniger als 6 Stunden Schlaf pro Nacht sind mit leichten chronischen Entzündungen und verschlechterter Insulinsensitivität sowie erhöhtem Risiko für Übergewicht, Typ 2 Diabetes und Herzerkrankungen assoziiert. <br /> <br />
        Also: PRIORISIERE DEINEN SCHLAF! <br /> <br />
        Newbie Tipp: 7-9 Stunden sind für Dich unmöglich? Suche Dir ein Ziel aus, was für DICH eine Verbesserung Deiner Schlafsitutation bedeutet wie z.B. 30 min mehr Schlaf oder ins Bett vor 23:00.
      </p> <br />
    </div>
  )
}

export default Schlaf