import React, { useState } from "react";
import ResetIcon from "../../../../../Icons/ResetIcon";
import DietIcon from "../../../../../Icons/DietIcon";
import axios from "axios";
import classNames from "classnames";

function DietModal({
  setOpen,
  user,
  challenge,
  setUserChallenge,
  userChallenge,
}) {
  const [diet, setDiet] = useState("basic");

  function sendToDatabase() {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/add/single/info/string`,
        {
          path: "diet",
          info: diet,
          challengeId: challenge._id,
          userId: user._id,
        }
      )
      .then(() => {
        const updatedUserChallenge = {
          ...userChallenge,
          diet: diet,
        };

        setUserChallenge(updatedUserChallenge);
        setOpen(false);
      });
  }

  const handleReset = () => {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/delete/single/info/string`,
        {
          userId: user._id,
          challengeId: challenge._id,
          path: "diet",
        }
      )
      .then(() => {
        const updatedUserChallenge = {
          ...userChallenge,
          diet: null,
        };

        setUserChallenge(updatedUserChallenge);
        setOpen(false);
      });
  };

  return (
    <div className="w-full">
      {!userChallenge.diet ? (
        <>
          <div className="w-8 w-8 mx-auto mb-4">
            <DietIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">ERNÄRUNGSPLAN</h1>

          <h5 className="text-lg mt-8 font-extrabold text-center">
            Ernährungsplan wählen
          </h5>
          <p className="pt-8">
            Wähle deine Ernährungsregeln, die für die Challenge gelten sollen.
            Lies dir dazu hier alles durch: <br />
            <a className="underline" href="/blog/deine-ernhrungsregeln+su0t">
              zu den Ernährungsleveln.
            </a>
          </p>

          <div className="relative my-14">
            <select
              name="diet"
              id="diet"
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300 appearance-none bg-white"
              onChange={(e) => {
                setDiet(e.target.value);
              }}
            >
              <option value="basic" defaultValue>
                BASIC
              </option>
              <option value="pro">PRO</option>
            </select>
          </div>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out"
            )}
            onClick={() => sendToDatabase()}
          >
            FERTIG
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      ) : (
        <>
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">
            DATEN WIDERRUFEN
          </h1>
          <h3 className="mt-4 text-sm text-center mb-8">
            Hier kannst du dein Profil zurücksetzen. Klicke auf den Button wenn
            deinen Ernähungsplan nochmal lesen möchtest.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => handleReset()}
          >
            DATEN ZURÜCKSETZEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      )}
    </div>
  );
}

export default DietModal;
