import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./FooterNavigation.scss";
import { useStore } from "../../utils/store";
import HomeIcon from "../Icons/HomeIcon";
import RankingIcon from "../Icons/RankingIcon";
import ChatIcon from "../Icons/ChatIcon";
import TeamIcon from "../Icons/TeamIcon";
import AnalyseIcon from "../Icons/AnalyseIcon";
import classNames from "classnames";
import { differenceInDays } from "date-fns";

const FooterNavigation = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const challenge = useStore((state) => state.challenge);
  const isAfterChallenge = useStore((state) => state.isAfterChallenge);

  const handleNavigation = (path) => {
    // If we're in after-challenge mode, redirect home to /after
    if (isAfterChallenge && path === "/home") {
      navigate("/after-dashboard");
      return;
    }
    if (
      !isAfterChallenge ||
      ["home", "analyse", "ranking"].includes(path.slice(1))
    ) {
      navigate(path);
    }
  };

  return (
    <div className="footer-navigation" ref={ref}>
      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active":
            location.pathname === "/home" ||
            location.pathname === "/after-dashboard",
        })}
        onClick={() => handleNavigation("/home")}
      >
        <HomeIcon />
        <p className="footer-navigation-link-title">Home</p>
      </div>

      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/analyse",
        })}
        onClick={() => handleNavigation("/analyse")}
      >
        <AnalyseIcon />
        <p className="footer-navigation-link-title">Analyse</p>
      </div>

      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/ranking",
        })}
        onClick={() => handleNavigation("/ranking")}
      >
        <RankingIcon />
        <p className="footer-navigation-link-title">Ranking</p>
      </div>

      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/team",
          "footer-navigation-link--disabled": isAfterChallenge,
        })}
        onClick={() => handleNavigation("/team")}
      >
        <TeamIcon />
        <p className="footer-navigation-link-title">Team</p>
      </div>

      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/chat",
          "footer-navigation-link--disabled": isAfterChallenge,
        })}
        onClick={() => handleNavigation("/chat")}
      >
        <ChatIcon />
        <p className="footer-navigation-link-title">
          {challenge?.globalSettings?.chatView?.title || "Chat"}
        </p>
      </div>
    </div>
  );
});

export default FooterNavigation;
