import React from 'react'
import "./SingleBlogs.scss"
import punkte from "../../../img/punkte.png"
import BackIcon from '../../Icons/BackIcon'


function Punkte() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Punkte Sammeln</h1>
      <img src={punkte} alt="" className="single-blog-image" />
      <p className="single-blog-text">Sammle Punkte für gesunde Habits. Je nach Wichtigkeit oder Aufwand gibt es unterschiedliche Punkte. <br /><br />
        Du hast ein Habit absolviert? Dann markiere es in der App als erledigt. <br /><br />
        Deine gesammelten Punkte der DAILY HABITS werden am Ende eines Tages um 24:00 Uhr zu Deinen Gesamtpunkten addiert und ins Leaderboard übertragen. <br /><br />
        Deine gesammelten Punkte der WEEKLY HABITS werden am Ende der Woche sonntags um 24:00 zu Deinen Gesamtpunkten addiert und ins Leaderboard übertragen. <br /><br />
        Du hast einmal vergessen, Deine Habits abzuhaken? Kein Problem. Den VORTAG kannst Du in der App nachtragen. Weiter zurückliegende Tage und den LETZTEN Tag 28 kannst Du jedoch NICHT mehr nachholen. Also: Never Miss Twice!</p>


    </div>
  )
}

export default Punkte