import React from "react";

function RulesHomeIcon() {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-10 h-10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7365 4.39437H26.7886C27.7913 4.39437 28.6042 5.20726 28.6042 6.21V29.208C28.6042 30.2107 27.7913 31.0236 26.7886 31.0236H6.21144C5.2087 31.0236 4.39581 30.2107 4.39581 29.208V6.21C4.39581 5.20726 5.2087 4.39437 6.21144 4.39437H12.2635C12.3639 4.39106 12.4555 4.33613 12.5056 4.24912C13.3404 2.84034 14.8564 1.97644 16.494 1.97644C18.1315 1.97644 19.6475 2.84034 20.4823 4.24912C20.5394 4.33491 20.6336 4.38874 20.7365 4.39437ZM24.2467 23.628C24.7168 23.0782 24.9745 22.3783 24.973 21.655V8.63085C24.973 8.2966 24.702 8.02564 24.3678 8.02564H8.63228C8.29804 8.02564 8.02707 8.2966 8.02707 8.63085V26.7872C8.02707 27.1214 8.29804 27.3924 8.63228 27.3924H19.6229C20.5058 27.3907 21.3446 27.0066 21.9227 26.3393L24.2467 23.628Z"
        fill="black"
      />
      <path
        d="M21.3417 11.9595H15.8948C15.3934 11.9595 14.987 12.3659 14.987 12.8673C14.987 13.3687 15.3934 13.7751 15.8948 13.7751H21.3417C21.8431 13.7751 22.2495 13.3687 22.2495 12.8673C22.2495 12.3659 21.8431 11.9595 21.3417 11.9595Z"
        fill="black"
      />
      <path
        d="M21.3417 16.1959H15.8948C15.3934 16.1959 14.987 16.6024 14.987 17.1037C14.987 17.6051 15.3934 18.0116 15.8948 18.0116H21.3417C21.8431 18.0116 22.2495 17.6051 22.2495 17.1037C22.2495 16.6024 21.8431 16.1959 21.3417 16.1959V16.1959Z"
        fill="black"
      />
      <path
        d="M21.3417 20.4325H15.8948C15.3934 20.4325 14.987 20.8389 14.987 21.3403C14.987 21.8417 15.3934 22.2481 15.8948 22.2481H21.3417C21.8431 22.2481 22.2495 21.8417 22.2495 21.3403C22.2495 20.8389 21.8431 20.4325 21.3417 20.4325Z"
        fill="black"
      />
      <circle cx="11.6584" cy="12.8673" r="1.21042" fill="black" />
      <ellipse
        cx="11.6584"
        cy="17.1037"
        rx="1.21042"
        ry="1.21042"
        fill="black"
      />
      <circle cx="11.6584" cy="21.3403" r="1.21042" fill="black" />
    </svg>
  );
}

export default RulesHomeIcon;
