import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { useStore } from "../../../../../../utils/store";
import NatureIcon from "../../../../../Icons/NatureIcon";
import ResetIcon from "../../../../../Icons/ResetIcon";

function NatureModal({ setOpen, updateWeeklie, currentWeek, userChallenge }) {
  const [currentMinutes, setCurrentMinutes] = useState(0);

  const currentNatureLog = () => {
    return (
      userChallenge.weeklyHabits["nature"] &&
      userChallenge.weeklyHabits["nature"].find(
        (nature) => nature.week === currentWeek
      )
    );
  };

  const isHabitChecked = () => {
    const natureLog = currentNatureLog();
    return natureLog && natureLog.minutes >= 120;
  };

  const updateMinutes = (newMinutes) => {
    let existingMinutes = 0;
    const natureLog = currentNatureLog();
    if (natureLog) {
      existingMinutes = Number(natureLog.minutes);
    }

    const totalMinutes = existingMinutes + Number(newMinutes);

    let points = 0;
    if (totalMinutes >= 24 && totalMinutes < 48) {
      points = 1;
    } else if (totalMinutes >= 48 && totalMinutes < 72) {
      points = 2;
    } else if (totalMinutes >= 72 && totalMinutes < 96) {
      points = 3;
    } else if (totalMinutes >= 96 && totalMinutes < 120) {
      points = 4;
    } else if (totalMinutes >= 120) {
      points = 5;
    }

    updateWeeklie(points, "nature", null, totalMinutes);
    setOpen(false);
  };

  return (
    <>
      {!isHabitChecked() ? (
        <div>
          <div className="w-8 w-8 mx-auto mb-4">
            <NatureIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">NATUR</h1>

          <h5 className="text-sm mt-4 mb-6 font-extrabold text-center">
            Warst Du heute in der Natur?
          </h5>

          <p className="text-sm text-center">
            Wenn Du heute richtig “grün” gesehen hast, trage Deine Naturminuten
            hier ein.
            <br />
            <br /> Sammle bis zum Ende der Woche insgesamt 120 Minuten in der
            Natur und erhalte dafür 5 Punkte.
            {currentNatureLog() && currentNatureLog().minutes < 120 ? (
              <>
                <br />
                <br />
                <b>
                  Du warst bereits {currentNatureLog().minutes} Minuten in der
                  Natur.
                </b>
              </>
            ) : null}
          </p>

          <div className="flex items-center mt-5 gap-4 justify-center">
            <input
              onChange={(e) => setCurrentMinutes(e.target.value)}
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300 text-right"
              type="number"
              placeholder={currentNatureLog ? currentNatureLog.minutes : 0}
            />
            <p className="nature-input-text">Minuten</p>
          </div>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out mb-5"
            )}
            onClick={() =>
              updateMinutes(
                currentNatureLog()
                  ? Number(currentMinutes)
                  : Number(currentMinutes)
              )
            }
          >
            FERTIG
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="w-full text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PUNKTE WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du deine Punkte zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => {
              updateWeeklie(0, "nature", "reset", 0);
              setOpen(false);
            }}
          >
            PUNKTE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default NatureModal;
