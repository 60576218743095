import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

import ResetIcon from "../../../../../Icons/ResetIcon";
import LifestyleIcon from "../../../../../Icons/LifestyleIcon";
import Score from "../../../../../Score";
import classNames from "classnames";

function LifestyleCheckModal2({
  setOpen,
  checked,
  user,
  userChallenge,
  setUserChallenge,
}) {
  const [step, setStep] = useState(1);
  const [scale, setScale] = useState(5);
  const [lifestyleScore2, setLifestyleScore2] = useState([]);
  const [lifestyleTotal2, setLifestyleTotal2] = useState(0);

  useEffect(() => {
    if (lifestyleScore2) {
      let sum = 0;
      lifestyleScore2.forEach((e) => {
        sum = sum + e;
      });
      setLifestyleTotal2(sum);
    }
  }, [lifestyleScore2]);

  const deleteFromDatabase = () => {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/delete/single/info/string`,
        {
          userId: user._id,
          challengeId: userChallenge._id,
          path: "lifestyleScore2",
        }
      )
      .then((response) => {
        setStep(1);
        setLifestyleScore2([]);
        setUserChallenge({
          ...userChallenge,
          lifestyleScore2: [],
        });
        setOpen(false);
      });
  };

  function sendToDatabase() {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/add/single/info/string`,
        {
          path: "lifestyleScore2",
          info: lifestyleScore2,
          userId: user._id,
          challengeId: userChallenge._id,
        }
      )
      .then((response) => {
        setStep(1);
        setLifestyleScore2([]);
        setUserChallenge({
          ...userChallenge,
          lifestyleScore2: lifestyleScore2,
        });
        setOpen(false);
      });
  }

  return (
    <div className="w-full">
      {(user.lifestyleScore2 && user.lifestyleScore2.length < 10) ||
      user.lifestyleScore2 == undefined ? (
        <>
          <div className="w-8 w-8 mx-auto mb-4">
            <LifestyleIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">
            LIFESTYLE CHECK
          </h1>

          {step !== 11 && (
            <>
              <h3 className="mt-4 text-lg font-extrabold my-8 text-center">
                {step} von 10
              </h3>

              <div className="bg-white -mx-8 md:-mx-36 px-5 pt-8 pb-5">
                <h5 className="text-md font-extrabold text-center mb-4">
                  {step === 1 && "Wieviel Sport treibst du im Moment?"}
                  {step === 2 && "Wie ist Dein momentanes Energielevel?"}
                  {step === 3 && "Wie gut ernährst Du Dich momentan?"}
                  {step === 4 && "Wie gut schläfst Du momentan?"}
                  {step === 5 && "Wie viel Zeit verbringst Du in der Natur?"}
                  {step === 6 && "Wie ist Dein momentanes Stresslevel?"}
                  {step === 7 &&
                    "Wie oft duschst Du kalt, nutzt Sauna oder Eisbad?"}
                  {step === 8 && "Wie oft nimmst Du Dir einen Moment der Ruhe?"}
                  {step === 9 &&
                    "Wie viel Neues hast Du in letzter Zeit gelernt oder ausprobiert?"}
                  {step === 10 && "Wie ist Deine Grundstimmung?"}
                </h5>
                <input
                  className="w-full accent-green mb-3"
                  type="range"
                  value={scale}
                  min="0"
                  max="10"
                  onChange={(e) => setScale(e.target.value)}
                />
                <div className="flex justify-between text-xs gap-4">
                  {step === 1 && (
                    <>
                      <p>gar nicht</p>
                      <p>jeden Tag</p>
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <p>niedrig</p>
                      <p>sehr hoch</p>
                    </>
                  )}

                  {step === 3 && (
                    <>
                      <p>schlecht</p>
                      <p>sehr gesund</p>
                    </>
                  )}

                  {step === 4 && (
                    <>
                      <p>schlecht</p>
                      <p>super</p>
                    </>
                  )}

                  {step === 5 && (
                    <>
                      <p>keine</p>
                      <p>sehr viel</p>
                    </>
                  )}

                  {step === 6 && (
                    <>
                      <p>sehr gestresst</p>
                      <p>super entspannt</p>
                    </>
                  )}
                  {step === 7 && (
                    <>
                      <p>nie</p>
                      <p>jeden Tag</p>
                    </>
                  )}
                  {step === 8 && (
                    <>
                      <p>nie</p>
                      <p>jeden Tag</p>
                    </>
                  )}
                  {step === 9 && (
                    <>
                      <p>nichts</p>
                      <p>etwas</p>
                      <p>sehr viel</p>
                    </>
                  )}
                  {step === 10 && (
                    <>
                      <p>negativ</p>
                      <p>positiv</p>
                    </>
                  )}
                </div>
              </div>
              <div
                className={classNames("grid gap-4 mt-8", {
                  "grid-cols-2": step > 1,
                })}
              >
                {step > 1 && (
                  <button
                    onClick={() => {
                      {
                        step === 2 && setLifestyleScore2([]);
                      }
                      lifestyleScore2.pop();
                      setStep(step - 1);
                    }}
                    className="bg-gray-300 text-white w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                  >
                    ZURÜCK
                  </button>
                )}
                <button
                  className={classNames(
                    "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                  )}
                  onClick={() => {
                    setStep(step + 1);
                    setScale(5);
                    setLifestyleScore2((current) => [
                      ...current,
                      parseInt(scale),
                    ]);
                  }}
                >
                  WEITER
                </button>
              </div>
            </>
          )}

          {step === 11 && (
            <>
              <h5 className="mt-4 text-lg font-extrabold my-8 text-center">
                Das war's! Dein LifestyleScore ist nun fertig eingerichtet.
              </h5>
              <div className="max-w-[150px] mx-auto mb-12">
                <Score total={100} score={lifestyleTotal2} points />
              </div>
              <button
                className="bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                onClick={() => sendToDatabase()}
              >
                SPEICHERN
              </button>
            </>
          )}

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      ) : (
        <div className="text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">DATEN WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du dein Lifestyle Check zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => deleteFromDatabase()}
          >
            DATEN ZURÜCKSETZEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </div>
  );
}

export default LifestyleCheckModal2;
