import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

import "./styles/profile.scss";
import ResetIcon from "../../../../../Icons/ResetIcon";
import AvatarUpload from "./components/AvatarUpload";
import ProfileIcon from "../../../../../Icons/ProfileIcon";
import classNames from "classnames";
import EditIcon from "../../../../../Icons/EditIcon";

function ProfileModal({
  setOpen,
  user,
  challenge,
  setUserChallenge,
  userChallenge,
  setUser,
  checked,
}) {
  const [step, setStep] = useState(1);
  const [imageSwitch, setImageSwitch] = useState(false);
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [birthDay, setBirthDay] = useState(
    user.bday ? user.bday : "2000-02-02"
  );
  const [gender, setGender] = useState(user.gender);
  const genderOptions = ["female", "male", "diverse", "none"];
  const genderOptionsLabel = [
    "Weiblich",
    "Männlich",
    "Diverse",
    "Bitte auswählen",
  ];

  function saveToDatabase(bday, gender, email, completed) {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/add/info`,
        {
          bday: bday,
          gender: gender,
          email: email,
          user: user._id,
        }
      )
      .then(() => {
        setUser({
          ...user,
          avatar: user.avatar,
          bday: bday,
          gender: gender,
        });
        setOpen(false);
        setProfileCompleted(completed);
      });
  }

  useEffect(() => {
    if (user.bday && user.avatar && user.gender) {
      setProfileCompleted(true);
    } else {
      setProfileCompleted(false);
    }
  }, [user.bday, user.avatar, user.gender]);

  return (
    <div className="w-full text-center">
      {!user.bday || !user.gender || !user.avatar || !profileCompleted ? (
        <>
          <div className="w-12 w-12 mx-auto">
            <ProfileIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PROFIL EINRICHTEN</h1>
          <h3 className="mt-4 text-lg font-extrabold">{step} von 4</h3>
          {step === 1 && (
            <div className="profile-first-step">
              <h5 className="text-lg my-8 font-extrabold">
                Lade ein Bild hoch
              </h5>
              <div className="max-w-[144px] mx-auto relative">
                {!user.avatar ? (
                  <AvatarUpload
                    user={user}
                    setUser={setUser}
                    setImageSwitch={setImageSwitch}
                  />
                ) : (
                  <>
                    <img
                      src={user.avatar}
                      alt=""
                      className="rounded-full border-2 aspect-square w-full h-full bg-white"
                      onClick={() => {
                        setUser({
                          ...user,
                          avatar: undefined,
                        });
                      }}
                    />
                    <div className="absolute top-0 right-0">
                      <EditIcon />
                    </div>
                  </>
                )}
              </div>

              <button
                className={classNames(
                  "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out",
                  { "bg-slate-50 text-slate-200": imageSwitch }
                )}
                disabled={imageSwitch}
                onClick={() => setStep(2)}
              >
                {imageSwitch ? "BILD BESTÄTIGEN" : "WEITER"}
              </button>
            </div>
          )}

          {step === 2 && (
            <div>
              <h5 className="font-extrabold text-lg mt-6">
                Gib dein Geburtsdatum ein
              </h5>
              <div className="profile-birthday">
                <div className="relative my-14">
                  <input
                    type="date"
                    className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300 appearance-none bg-white"
                    placeholder={birthDay}
                    value={birthDay}
                    onChange={(e) => {
                      setBirthDay(e.target.value);
                    }}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <button
                    className="bg-gray-300 text-white w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                    onClick={() => setStep(1)}
                  >
                    ZURÜCK
                  </button>

                  <button
                    className={classNames(
                      "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                    )}
                    onClick={() => {
                      setStep(3);
                    }}
                  >
                    WEITER
                  </button>
                </div>
              </div>
            </div>
          )}

          {step === 3 && (
            <div>
              <h5 className="font-extrabold text-lg mt-6">
                Wähle dein Geschlecht
              </h5>

              <div className="relative my-14">
                <select
                  name="gender"
                  id="genderLifestyle"
                  className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300 appearance-none bg-white"
                  defaultValue={user.gender ? user.gender : "none"}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                >
                  {genderOptions.map((entrie, index) => (
                    <option key={index} value={entrie}>
                      {genderOptionsLabel[index]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <button
                  className="bg-gray-300 text-white w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                  onClick={() => setStep(2)}
                >
                  ZURÜCK
                </button>

                <button
                  className={classNames(
                    "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                  )}
                  onClick={() => {
                    setStep(4);
                  }}
                >
                  WEITER
                </button>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="profile-done">
              <h5 className="font-extrabold text-lg mt-6">
                Das war's! Dein Profil ist nun fertig eingerichtet.
              </h5>
              <div className="my-14">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-20 h-20 mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <button
                  className="bg-gray-300 text-white w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                  onClick={() => setStep(3)}
                >
                  ZURÜCK
                </button>

                <button
                  className={classNames(
                    "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                  )}
                  onClick={() =>
                    saveToDatabase(birthDay, gender, user.email, true)
                  }
                >
                  SPEICHERN
                </button>
              </div>
            </div>
          )}

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      ) : (
        <>
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">DATEN WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du dein Profil zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => {
              saveToDatabase(null, null, null, false);
              setUser({
                ...user,
                avatar: null,
                bday: null,
                gender: null,
              });
            }}
          >
            DATEN ZURÜCKSETZEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      )}
    </div>
  );
}

export default ProfileModal;
