import React from "react";

function RankingIcon() {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.07 20.07"
    >
      <path
        className="cls-2"
        d="M20.07,10.03c0,5.54-4.49,10.03-10.03,10.03S0,15.57,0,10.03,4.49,0,10.03,0s10.03,4.49,10.03,10.03Zm-1.67,0c0-4.62-3.74-8.36-8.36-8.36S1.67,5.42,1.67,10.03s3.74,8.36,8.36,8.36,8.36-3.74,8.36-8.36Z"
      />
      <path
        className="cls-1"
        d="M5.43,13.15H15.05c.46,0,.84-.37,.84-.84V7.8c0-.16-.09-.31-.24-.38-.15-.07-.33-.04-.45,.07l-2.51,2.18s-.1,.05-.15,.05c-.06,0-.11-.03-.14-.08l-2.04-2.4c-.08-.09-.2-.14-.32-.14s-.24,.05-.32,.14l-2.04,2.39s-.09,.07-.15,.07c-.06,0-.12-.02-.16-.06l-2.06-2.05c-.12-.12-.3-.15-.45-.08-.16,.06-.26,.22-.26,.38v4.42c0,.46,.38,.82,.84,.82Z"
      />
    </svg>
  );
}

export default RankingIcon;
