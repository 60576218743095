import React from "react";
import "./PasswordChanged.scss";

function PasswordChanged() {
  return (
    <div className="password-changed">
      <h1 className="password-changed-title">PASSWORT ZURÜCKSETZEN</h1>
      <h3 className="password-changed-sub">Dein Passwort wurde erfolgreich zurück gesetzt.</h3>
      <p className="password-changed-text">
        Logge dich ein, und starte deine Challenge.
      </p>

      <button
        onClick={() => (window.location = "/login")}
        className="password-changed-button"
      >
        LOGIN
      </button>
    </div>
  );
}

export default PasswordChanged;
