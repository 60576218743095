import React from "react";
import "./SingleBlogs.scss";
import gutes from "../../../img/gutes.png";
import BackIcon from "../../Icons/BackIcon";

function Gutes() {
  return (
    <div className="single-blog">
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">TUE GUTES!</h1>
      <img src={gutes} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">WOCHE 2</h2>
      <p className="single-blog-text">
        Die Glücksforschung ist sich einig: Wer sich ehrenamtlich engagiert oder
        anderweitig GUTES FÜR ANDERE tut, ist glücklicher! Und weil es den
        meisten von uns vergleichsweise VERDAMMT GUT GEHT sollst Du in dieser
        Woche eine richtig gute Tat tun für andere Menschen in Not.
        <br /> <br />{" "}
      </p>
      <h2 className="single-blog-sub">EHRENAMTLICHES ENGAGEMENT</h2>
      <p className="single-blog-text">
        Eine tolle Seite für Hamburg, München und Berlin ist: <br />
        <a
          href="https://www.gute-tat.de/helfen/ehrenamtliches-engagement/projekte-berlin/ "
          className="single-blog-link"
        >
          https://www.gute-tat.de/helfen/ehrenamtliches-engagement/projekte-berlin/
        </a>{" "}
        <br /> <br />
        Hier kannst Du aus diversen Bereichen etwas aussuchen. Ist aber alles
        eher langfristig gedacht. Doch wenn Du Dich in dieser Woche für eines
        der Projekte anmeldest, sind die Punkte natürlich mehr als verdient
      </p>
      <h2 className="single-blog-sub">SPENDEN</h2>
      <ul className="single-blog-list">
        <p className="single-blog-text">
          Nimm Dir 1 Stunde Zeit und suche Dir eine Spendenorganisation heraus,
          die eine Sache unterstützt, die DIR persönlich am Herzen liegt und
          spende eine Summe Deiner Wahl. Hier mal nur ein paar Optionen zur
          Inspiration:
        </p>
        <li>
          <a
            href="https://www.kinderprojekt-arche.de/"
            className="single-blog-link"
          >
            https://www.kinderprojekt-arche.de/
          </a>
        </li>
        <li>
          <a
            href="https://netzwerk-wunschtraeume.de/"
            className="single-blog-link"
          >
            https://netzwerk-wunschtraeume.de/
          </a>
        </li>

        <li>
          <a href="https://gangway.de/" className="single-blog-link">
            https://gangway.de/
          </a>
        </li>
        <li>
          <a
            href="https://www.berliner-stadtmission.de/spenden/urlaub-vom-alltag"
            className="single-blog-link"
          >
            https://www.berliner-stadtmission.de/spenden/urlaub-vom-alltag
          </a>
        </li>
      </ul>
      <br /> <br />
      <ul className="single-blog-list">
        <p className="single-blog-text">
          Miste Deinen Schrank aus oder kaufe bestimmte Dinge und spende (vorher
          Bedarfslisten checken!):
        </p>
        <li>
          <a
            href="https://www.berliner-stadtmission.de/komm-sieh/kleiderspenden"
            className="single-blog-link"
          >
            https://www.berliner-stadtmission.de/komm-sieh/kleiderspenden
          </a>
        </li>
        <li>
          <a
            href="https://www.moabit-hilft.com/unterst%C3%BCtzen/sachspenden/"
            className="single-blog-link"
          >
            https://www.moabit-hilft.com/unterst%C3%BCtzen/sachspenden/
          </a>
        </li>
      </ul>
      <h2 className="single-blog-sub">PRIVATE HILFSDIENSTE</h2>
      <ul className="single-blog-list">
        <p className="single-blog-text">
          Hilf in Deiner Umgebung / näheren Umfeld::
        </p>
        <li>
          Du kennst eine Schwangere, die demnächst entbindet? Organisiere einen
          Koch-Kreisel, wo jede*r für die ersten Tage und Wochen nach der Geburt
          selbstgekochte Gerichte vor die Tür stellt (und nicht reingeht und
          erwartet, von der frischgebackenen Mutter auch noch bewirtet zu
          werden! Außer, Du räumst gleich mit auf und machst den Abwasch und
          schmeißt ne Wäsche an).
        </li>
        <li>
          Du kennst ein*en Alleinerziehende* n / Pflegende* n mit Kindern? Biete
          an, die Kids einen Nachmittag zu betreuen und geh mit ihnen ins Kino.
        </li>
        <li>
          Du hast eine* n Familienangehörige* n im Altersheim? Besuche ihn/sie
          und bring den Mitbewohner*innen einen selbstgebackenen Kuchen mit.
        </li>
        <li>
          Dein*e Nachbar*in ist alt und allein? Lade ihn/sie zum Kaffee zu Dir
          ein.
        </li>{" "}
      </ul>
      <p className="single-blog-text">
        Nutze diese Aufgabe um in dieser Woche über den Tellerrand unseres doch
        sehr eng gewordenen Alltags zu schauen und tue einfach etwas MEHR als Du
        sonst für andere tust. Und ganz nach dem Motto: “Tue Gutes und rede
        darüber” - erzähl uns davon in unserem Discord Channel (wenn Du magst.)
        Wir freuen uns auf viele wunderbare Ideen und Erlebnisse, die
        inspirieren weiterzumachen.
      </p>
    </div>
  );
}

export default Gutes;
