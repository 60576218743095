import React from 'react'
import "./SingleBlogs.scss"
import bewegung2 from "../../../img/bewegung2.png"
import BackIcon from '../../Icons/BackIcon'


function Bewegung() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Bewegung</h1>
      <img src={bewegung2} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Bewege Dich täglich ca. 15 Minuten mit Intention.</h2>
      <p className="single-blog-text">Der Mensch ist genetisch auf Bewegung programmiert. Doch wir leben in einer Welt, in der wir uns theoretisch kaum bewegen müssen. Vieles ist automatisiert, die meisten Wege legen wir mit dem Auto zurück, das Essen lassen wir uns liefern und die meisten Berufe erfordern keine körperlichen Anstrengungen mehr. Laut einer Studie sind 5 Stunden körperliche Bewegung pro Woche erforderlich, um ein tägliches Sitzen von 8 und mehr Stunden zu kompensieren. Das heisst: wir müssen es uns also freiwillig ungemütlich machen, um unserem Körper die Anstrengung zu geben, die er braucht, um fit, gesund und stark zu sein. <br />
        Sport aktiviert so viele und komplexe Mechanismen im Körper, dass Mediziner sie bis heute nur zu einem Bruchteil verstanden haben. Doch eins ist klar: Regelmäßige Bewegung ist wichtig und gesund. Vermutlich kennst Du die Vorteile von Sport und Bewegung bereits genau.</p> <br />
      <ul className="single-blog-list">
        <p className="single-blog-text">Hier aber nochmal das wichtigste zusammengefasst:</p>
        <li>längere Lebenserwartung</li>
        <li>Verbesserung der räumliche
          Vorstellungskraft und
          Konzentrationsfähigkeit</li>
        <li>Vergrößerung der Plastizität des Gehirns</li>
        <li>Stressabbau, verbesserte Laune</li>
        <li>Muskelaufbau</li>
        <li>Stärkung des Immunssystems</li>
        <li>erhöhter Stoffwechsel</li>
        <li>verbesserte Insulinsensitivität</li>
        <li>Stärkung des Herz-Kreislauf-Systems</li>
        <li>dichtere Knochen</li>
        <li>Fettabbau</li>
        <li>besserer Schlaf</li>
      </ul>

      <p className="single-blog-text">
        Tägliche Bewegung ist für die Gesundheit essenziell. Egal ob Yoga, CrossFit, Joggen, Fussball, Boxen oder Wandern. Mach den Sport, der Dir Spaß macht und trainiere möglichst alle Facetten Deiner Fitness. Alles ist erlaubt! <br />
        An manchen Tagen fühlst Du Dich nach einer ruhigen Stretchingeinheit. An anderen möchtest Du Dich beim Spinning richtig auspowern. Höre auf Deinen Körper und gib ihm Zeit zum Erholen. Als Fausformel gilt: Für die besten Ergebnisse solltest Du die Dauer der Sporteinheit von der Intensität abhängig machen. Je intensiver, desto kürzer kann sie sein. <br />
        Die WHO empfiehlt folgende Dauer für Aktivitäten & Bewegung:
      </p> <br />

      <h2 className="single-blog-sub">Ausdaueraktivität:</h2>

      <ul className="single-blog-list">
        <li>2,5 Stunden (150min) pro Woche moderate Aktivität oder</li>
        <li>1,25 Stunden (75min) pro Woche intensive Aktivität oder</li>
        <li>eine Kombination aus moderater & intensiver Aktivität in Einheiten mit einer Mindestdauer von je 10 Minuten</li>
        <li>Muskelkräftigungsaktivität an mindestens 2 Tagen pro Woche</li>
      </ul>

      <p className="single-blog-text">
        Newbie Tipp: Geh nicht von 0 auf 100. Starte mit 1-2 intensiveren Einheiten pro Woche und nutze den Rest der Zeit für Mobilitäts- und Flexibilitätseinheiten sowie low-intensity Training z.B. Walking.
      </p> <br />
    </div>
  )
}

export default Bewegung