import React from "react";

import Modal from "../../../Modal";
import NourishModal from "./components/NourishModal";
import SleepModal from "./components/SleepModal";
import TrainingModal from "./components/TrainingModal";
import ShowerModal from "./components/ShowerModal";
import BreathModal from "./components/BreathModal";
import BreathingModal from "./components/BreathingModal";
import GratitudeModal from "./components/GratitudeModal";
import KeepUpModal from "./components/KeepUpModal";

function DailiesModals({
  open,
  currentDay,
  setOpen,
  updateHabit,
  userChallenge,
}) {
  return (
    <Modal open={open} keepUpModal={open === "keepup"}>
      {open === "nourish" && (
        <NourishModal
          currentDay={currentDay}
          setOpen={setOpen}
          updateHabit={(points, habit, data) => {
            updateHabit(points, "nourish", data);
          }}
          userChallenge={userChallenge}
        />
      )}

      {open === "training" && (
        <TrainingModal
          currentDay={currentDay}
          setOpen={setOpen}
          updateHabit={(points) => updateHabit(points, "training")}
          userChallenge={userChallenge}
        />
      )}
      {open === "sleep" && (
        <SleepModal
          currentDay={currentDay}
          setOpen={setOpen}
          updateHabit={(points) => updateHabit(points, "sleep")}
          userChallenge={userChallenge}
        />
      )}
      {open === "shower" && (
        <ShowerModal
          currentDay={currentDay}
          setOpen={setOpen}
          updateHabit={(points) => updateHabit(points, "shower")}
          userChallenge={userChallenge}
        />
      )}
      {open === "breath" && (
        <BreathModal
          currentDay={currentDay}
          setOpen={setOpen}
          updateHabit={(points) => updateHabit(points, "breathing")}
          userChallenge={userChallenge}
        />
      )}
      {open === "breathing" && (
        <BreathingModal
          currentDay={currentDay}
          setOpen={setOpen}
          updateHabit={(points) => updateHabit(points, "breathing")}
          userChallenge={userChallenge}
        />
      )}
      {open === "gratitude" && (
        <GratitudeModal
          currentDay={currentDay}
          setOpen={setOpen}
          updateHabit={updateHabit}
          userChallenge={userChallenge}
        />
      )}
      {open === "keepup" && <KeepUpModal setOpen={setOpen} />}
    </Modal>
  );
}

export default DailiesModals;
