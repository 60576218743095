import React from 'react'
import "./SingleBlogs.scss"
import schlaf2 from "../../../img/schlaf2.png"
import BackIcon from '../../Icons/BackIcon'


function Schlaf2() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">SCHLAF: </h1>
      <h2 className="single-blog-sub">Tipps für richtig guten Schlaf</h2>
      <img src={schlaf2} alt="" className="single-blog-image" />
      <p className="single-blog-text">Deine Nächte sind noch nicht optimal? Es gibt eine Menge, was Du für Deine Schlafhygiene tun kannst.
        Hier sind unsere 10 Tipps für richtig guten Schlaf:
      </p>

      <h2 className="single-blog-sub">1. Helligkeit am Morgen</h2>
      <p className="single-blog-text">
        Verbringe viel Zeit draußen, wenn es hell ist. Das morgendliche Licht mit niedrigem Sonnenwinkel ist der beste Stimulus für Deinen zirkadianen Rhythmus. Das gilt sogar an bewölkten Tagen. 10 Minuten natürliches Licht am Morgen reichen aus. Möglichst ohne Sonnenbrille, Fenster- oder
        Windschutzscheiben.        </p>
      <h2 className="single-blog-sub">2. Kein künstliches Licht</h2>
      <p className="single-blog-text">
        Reduziere Deine Exposition zu künstlichem Licht ca. 2 Stunden vor dem Schlafen. D.h. kein Handy, kein Fernseher, kein Bildschirm. Aber auch keine Deckenlampen. Nutze Kerzenlicht oder Kaminfeuer für beruhigendes Licht, dass Dich auch am Abend nicht wach macht.      </p>

      <h2 className="single-blog-sub">4. frühe Bettzeit</h2>
      <p className="single-blog-text">Es ist so simpel und doch nicht einfach. Der beste Tipp für guten Schlaf: Geh früher ins Bett. Der Schlaf vor Mitternacht ist bekanntermaßen besonders erholsam und je mehr Du davon bekommen kannst, desto besser.</p>

      <h2 className="single-blog-sub">5. Ruhe fürs Nervensystem</h2>
      <p className="single-blog-text">
        Beruhige Dein Nervensystem durch einen Spaziergang, Buch lesen, eine Meditation oder angenehme Gespräche. Schreibe Sorgen und Ängste in ein Tagebuch und versuche die letzte Stunde Deines Tages in Ruhee und Stille zu verbringen.      </p>

      <h2 className="single-blog-sub">6. Keine Aufregung</h2>
      <p className="single-blog-text">
        Vermeide aktivierende Substanzen wie Koffein und Stress verursachende Tätigkeiten wie Fernsehen oder Social Media. Mit aktiviertem Nervensystem im Fight, Flight, Freeze oder Fawn Modus kommst Du nicht zur Ruhe und an entspannten Schlaf ist nicht zu denken.      </p>

      <h2 className="single-blog-sub">7. Abendroutine</h2>
      <p className="single-blog-text">
        Entwickle eine Abendroutine, die Du jeden Tag durchführst. Zu einer guten Abendroutine gehören feste Rituale, die Deinen Körper und Geist auf die Nacht vorbereiten. Das können eine Tasse Tee, eine Meditation oder der Eintrag ins Dankbarkeitstagebuch vor dem Zubettgehen sein. Ganz wichtiger Bestandteil ist auch eine feste Schlafenszeit. Wenn Du immer zu ungefähr gleichen Zeit ins Bett gehst, wird sich Dein Körper daran gewöhnen und zur richtigen Zeit in den
        Ruhemodus fahren. Wenn möglich, solltest Du auch am Wochenende Deine Schlafenszeit ungefähr einhalten.
      </p>
      <h2 className="single-blog-sub">8. Kühles Schlafzimmer</h2>
      <p className="single-blog-text">
        Die optimale Temperatur im Schlafzimmer liegt bei rund 16-19°C, oder sogar noch kälter, wenn Du es magst. Lüfte für frische, sauerstoffreiche Luft vor dem Schlafengehen und morgens nach dem Aufwachen gut durch.
      </p>
      <h2 className="single-blog-sub">9. Natürliche Schlafkleidung</h2>
      <p className="single-blog-text">
        Deine nächtliche Kleidung sollte Dich weder überhitzen noch auskühlen. Dafür sind am besten Naturfasern wie Baumwolle, Wolle-Seide oder Merinowolle geeignet.      </p>
      <h2 className="single-blog-sub">10. Kohlenhydrate am Abend</h2>
      <p className="single-blog-text">
        Iss Deine Kohlenhydrate abends. Kaum zu glauben aber wahr, Studien zeigen: wer morgens eher Protein- und Fettreich isst und abends mehr Kohlenhydrate, schläft besser.        </p>



    </div>
  )
}

export default Schlaf2