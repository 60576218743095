import React, { useState } from "react";
import { Button, Upload, Alert } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const LogoUpload = ({ image, setImage, preview }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const beforeUpload = (file) => {
    const fileSizeInMB = file.size / (1024 * 1024);
    const validFileTypes = ["image/jpeg", "image/png"];
    const isValidFileType = validFileTypes.includes(file.type);

    if (!isValidFileType) {
      setErrorMessage(
        "Invalid file type. Only jpeg and png files are allowed."
      );
    } else if (fileSizeInMB > 2) {
      setErrorMessage("File size exceeds limit of 2MB.");
    } else {
      setErrorMessage("");
      setSelectedFile(file);
      setImage(file); // update the image state in the parent component
    }

    return false; // prevent auto uploading
  };

  return (
    <div className="logo-upload">
      <Upload beforeUpload={beforeUpload} maxCount={1} showUploadList={false}>
        <Button icon={<UploadOutlined />}>Select Image</Button>
      </Upload>
      {selectedFile && (
        <img src={URL.createObjectURL(selectedFile)} alt="Selected" />
      )}
      {!selectedFile && preview && <img src={preview} alt="Preview" />}
      {errorMessage && (
        <Alert message={errorMessage} type="error" showIcon closable />
      )}
    </div>
  );
};

export default LogoUpload;
