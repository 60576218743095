import React from "react";

function ChatIcon() {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.39 18.39"
    >
      <path
        className="cls-1"
        d="M16.27,0c1.17,0,2.12,.95,2.12,2.12V12.02c0,1.17-.95,2.12-2.12,2.12h-5.76l-5.12,4.09c-.21,.17-.5,.2-.75,.08-.24-.12-.4-.36-.4-.63v-3.54H2.12c-1.17,0-2.12-.95-2.12-2.12V2.12C0,.95,.95,0,2.12,0h14.15ZM2.83,7.78c0,.78,.63,1.41,1.41,1.41s1.41-.63,1.41-1.41-.63-1.41-1.41-1.41-1.41,.63-1.41,1.41Zm6.37,1.41c-.78,0-1.41-.63-1.41-1.41s.63-1.41,1.41-1.41,1.41,.63,1.41,1.41-.63,1.41-1.41,1.41Zm3.54-1.41c0,.78,.63,1.41,1.41,1.41s1.41-.63,1.41-1.41-.63-1.41-1.41-1.41-1.41,.63-1.41,1.41Z"
      />
    </svg>
  );
}

export default ChatIcon;
