import React from "react";
import "./SingleBlogs.scss";
import ernaehrung from "../../../img/ernaehrung.png";
import ernaehrungsplan from "../../../img/ernaehrungsplan.png";

import BackIcon from "../../Icons/BackIcon";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import ernaehrungsregeln from "./pdf/ernaehrungsregeln.pdf";

import ernaehrungsregeln1 from "./img/ernaehrungsregeln1.png";
import ernaehrungsregeln2 from "./img/ernaehrungsregeln2.png";
import ernaehrungsregeln3 from "./img/ernaehrungsregeln3.png";
import ernaehrungsregeln4 from "./img/ernaehrungsregeln4.png";

function Ernaehrung() {
  const newplugin = defaultLayoutPlugin();
  return (
    <div className="single-blog">
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Ernährungsplan wählen</h1>
      <img src={ernaehrung} alt="" className="single-blog-image" />
      <p className="single-blog-text">
        Die HiLife Challenge ist inklusiv - wir möchten, dass Du unabhängig von
        Deiner bevorzugten Ernährungsform mitmachen kannst und dabei alle
        Benefits einer gesunden Ernährung erfährst. <br /> <br />
        Du kannst aus drei unterschiedlichen Ernährungsleveln wählen, welche für
        die nächsten 4 Wochen Deine persönlichen Ernährungsregeln enthalten
        sollen.
      </p>{" "}
      <br /> <br />
      <ul className="single-blog-list">
        <li>BASIC</li>
        <li>PRO</li>
      </ul>
      <p className="single-blog-text">
        Erstelle Deinen eigenen Ernährungsplan! <br /> <br />
        Du möchtest den BASIC Plan aber ohne Milchprodukte machen? Du möchtest
        omnivor essen aber ohne Fleisch, nur mit Fisch? Alles kein Problem. Nimm
        den Plan der am besten zu Dir passt und streiche alle Lebensmittel aus
        der "YES" Kategorie, die Du nicht dabei haben willst. <br /> <br />
        ABER: Aus der “NO” Kategorie darfst Du nichts zu Deinem individuellen
        Plan hinzufügen! <br /> <br />
      </p>
      <div className="single-blog-pdf">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
          <Viewer fileUrl={ernaehrungsregeln} plugins={[newplugin]} />
        </Worker>
      </div>
      <div className="single-blog-images">
        <img src={ernaehrungsregeln1} alt="" className="single-block-image" />
        <img src={ernaehrungsregeln2} alt="" className="single-block-image" />
        <img src={ernaehrungsregeln3} alt="" className="single-block-image" />
        <img src={ernaehrungsregeln4} alt="" className="single-block-image" />
      </div>
    </div>
  );
}

export default Ernaehrung;
