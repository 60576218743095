import React from "react";
import "./SingleBlogs.scss";
import erklaerung from "../../../img/erklaerung.png";
import BackIcon from "../../Icons/BackIcon";

function Erklaerung() {
  return (
    <div className="single-blog">
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Was ist die HiLife Challenge?</h1>
      <h2 className="single-blog-sub">Der Schweinehund</h2>
      <img src={erklaerung} alt="" className="single-blog-image" />
      <p className="single-blog-text">
        Der Mensch ist ein Gewohnheitstier und Faulheit liegt uns in den Genen.
        Das dritte Mal Snooze drücken, endloses Scrollen am Smartphone,
        tägliches Netflixen oder Homeoffice, bei dem Dein Arbeitsplatz 3 Meter
        vom Kühlschrank entfernt ist, sind ganz sicher bequem, tun aber nicht
        gut - weder körperlich noch mental. Der persönliche Kampf mit dem
        Schweinehund ist in unserer heutigen Zeit größer denn je.
        <br />
        Und wir wissen: den Schweinehund auf Trab zu halten ist verdammt schwer.
        Hat sich ein Denk- oder Handlungsmuster zur festen Gewohnheit
        entwickelt, kannst Du es nur noch mit großer Mühe und Selbstdisziplin
        wieder ändern. <br />
        Und weil wir Experten im Kampf gegen den Schweinehund sind, haben wir
        ein Spiel daraus gemacht!
      </p>
      <h2 className="single-blog-sub">Das Spiel</h2>
      <p className="single-blog-text">
        Die HiLife Challenge ist ein Spiel für einen gesünderen Lifestyle.
        Spiele gegen Deinen Schweinehund und mit Deiner Community. <br />
        Unsere wissenschaftlich fundierten, simplen, aber effektiven Habits
        machen Dich in 4 Wochen gesünder, fitter, leistungsfähiger und
        ausgeglichener! Mit den Daily Habits und Weekly Habits wirst Du immer
        wieder Deinen Schweinehund überwinden, aus Deinem Alltagstrott
        herauskommen und neue Routinen entwickeln. Manche Aufgaben erfordern
        mehr Überwindung als andere - aber alle haben eins gemeinsam: sie geben
        Dir neue Impulse und langfristige Motivation für ein gesünderes Leben
        mit mehr Self-Care und Spaß im Alltag.
      </p>
      <h2 className="single-blog-sub">Die Habits</h2>
      <p className="single-blog-text">
        Ein gesunder Lifestyle besteht aus so viel mehr als nur Ernährung und
        Sport. Deswegen wirst Du während der 4-wöchigen HiLife Challenge in
        folgenden Bereiche gesunde Gewohnheiten (Habits) aufbauen: <br />
        Ernährung, Bewegung, Schlaf, Atmung, Dankbarkeit, Hitze & Kälte, Natur,
        Wissen und Action. <br />
        In der Challenge App hakst Du alle erledigten Habits ab und sammelst so
        täglich Punkte.
      </p>

      <h2 className="single-blog-sub">Alles kann, nichts muss!</h2>
      <p className="single-blog-text">
        Rausfliegen oder scheitern ist in der Challenge nicht möglich. Keiner
        erwartet von Dir 100% - Du brauchst nur zwei Dinge: den Mut mitzumachen
        und die Bereitschaft, neue Dinge auszuprobieren.
      </p>

      <h2 className="single-blog-sub">Die Community</h2>
      <p className="single-blog-text">
        In der Gemeinschaft macht die Challenge natürlich noch mehr Spaß und Du
        hast größeren Erfolg, wenn Du durch Deine Community motiviert am Ball
        bleibst. Hol Dir Tipps im Chat oder supporte Deine Mitstreiter*innen
        wenn's schwer wird. Also finde Verbündete - egal ob Zuhause, im Büro
        oder in Deinem Gym. <br /> <br />
        In der HiLife Challenge App kannst Du im Community Chat Fragen stellen,
        Bilder posten und Deine Mitspieler*innen anfeuern.
      </p>

      <h2 className="single-blog-sub">Die Analyse</h2>
      <p className="single-blog-text">
        Im Leaderboard kannst Du sehen, wie Du im täglichen Kampf gegen den
        Schweinehund abschneidet. <br /> <br />
        Vor und nach der Challenge ermittelst Du außerdem Deinen Lifestyle Score
        um Deinen persönlichen Fortschritt und Erfolg zu messen.
      </p>
    </div>
  );
}

export default Erklaerung;
