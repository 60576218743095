import React from "react";

function TeamIcon() {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.8 9.8"
    >
      <path
        className="cls-1"
        d="M7,7.69a.26.26,0,0,0-.07-.35,4.34,4.34,0,0,0-2.72-1A4.41,4.41,0,0,0,0,9.44a.28.28,0,0,0,0,.25.27.27,0,0,0,.22.11H6a.3.3,0,0,0,.28-.21V9.44A6.22,6.22,0,0,1,7,7.69Z"
        transform="translate(0.01 -0.01)"
      />
      <path
        className="cls-1"
        d="M19.69,6.37a4.23,4.23,0,0,0-2.34.7.22.22,0,0,0-.12.17.26.26,0,0,0,0,.21,6.05,6.05,0,0,1,.85,2v.15a.29.29,0,0,0,.28.21h5.18a.27.27,0,0,0,.22-.11.28.28,0,0,0,0-.25,4.41,4.41,0,0,0-4.07-3.06Z"
        transform="translate(0.01 -0.01)"
      />
      <path
        className="cls-1"
        d="M16.14,9.8a.3.3,0,0,0,.23-.11.28.28,0,0,0,0-.25A4.42,4.42,0,0,0,8,9.44a.26.26,0,0,0,0,.21.26.26,0,0,0,.19.1l7.92.06Z"
        transform="translate(0.01 -0.01)"
      />
      <circle className="cls-1" cx="4.23" cy="2.7" r="2.7" />
      <circle className="cls-1" cx="19.7" cy="2.7" r="2.7" />
      <path
        className="cls-1"
        d="M13.73,4.93a2.7,2.7,0,1,0-3,0,2.69,2.69,0,0,0,3,0Z"
        transform="translate(0.01 -0.01)"
      />
    </svg>
  );
}

export default TeamIcon;
