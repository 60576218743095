import React from "react";

function BreathIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      style={{ fill: props.color }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.96387 14.1551C1.96387 15.4203 2.98954 16.446 4.25478 16.446H28.3093C31.4724 16.446 34.0366 13.8818 34.0366 10.7187C34.0366 7.55564 31.4724 4.99146 28.3093 4.99146C27.0441 4.99146 26.0184 6.01713 26.0184 7.28236C26.0184 8.5476 27.0441 9.57327 28.3093 9.57327C28.9419 9.57327 29.4548 10.0861 29.4548 10.7187C29.4548 11.3513 28.9419 11.8642 28.3093 11.8642H4.25478C2.98954 11.8642 1.96387 12.8899 1.96387 14.1551Z" />
      <path d="M23.7275 18.7369H4.25478C2.98954 18.7369 1.96387 19.7626 1.96387 21.0278C1.96387 22.2931 2.98954 23.3188 4.25478 23.3188H23.7275C24.3601 23.3188 24.873 23.8316 24.873 24.4642C24.873 25.0968 24.3601 25.6097 23.7275 25.6097C22.4623 25.6097 21.4366 26.6353 21.4366 27.9006C21.4366 29.1658 22.4623 30.1915 23.7275 30.1915C26.8906 30.1915 29.4548 27.6273 29.4548 24.4642C29.4548 21.3011 26.8906 18.7369 23.7275 18.7369V18.7369Z" />
    </svg>
  );
}

export default BreathIcon;
