import React from "react";
import "./ConfirmationDialog2.scss";

const ConfirmationDialog2 = ({ show, onConfirm, onCancel, content }) => {
  if (!show) return null;

  let title, text, confirmButtonText, cancelButtonText;

  switch (content) {
    case "deleteTeam":
      title = "TEAM LÖSCHEN";
      text =
        "Bist du sicher das du das Team löschen willst? Bitte beachte, dass dieser Schritt nicht rückgängig gemacht werden kann.";
      confirmButtonText = "TEAM LÖSCHEN";
      cancelButtonText = "Abbrechen";
      break;
    case "makeAdmin":
      title = "MAKE ADMIN";
      text = "Dieses Mitglied zum Admin machen?";
      confirmButtonText = "Ja";
      cancelButtonText = "Abbrechen";
      break;
    case "deleteFromTeam":
      title = "AUS DEM TEAM WERFEN";
      text =
        "Bist du sicher das du dieses Mitglied aus dem Team entfernen möchtest?";
      confirmButtonText = "Ja";
      cancelButtonText = "Abbrechen";
      break;
    case "leaveTeam":
      title = "TEAM VERLASSEN";
      text = "Bist du sicher das du dieses Team verlassen willst?";
      confirmButtonText = "Ja";
      cancelButtonText = "Abbrechen";
      break;
    default:
      title = "";
      text = "";
      confirmButtonText = "Ja";
      cancelButtonText = "Nein";
  }

  return (
    <div className="confirmation-dialog">
      <div className="confirmation-dialog-content">
        <h2>{title}</h2>
        <p>{text}</p>
        <div className="confirmation-dialog-actions">
          <button onClick={onConfirm}>{confirmButtonText}</button>
          <button onClick={onCancel}>{cancelButtonText}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog2;
