import React from "react";

import Modal from "../../../Modal";
import ActionModal from "./components/ActionModal";
import NatureModal from "./components/NatureModal";
import ReflectionModal from "./components/ReflectionModal";
import WorkshopModal from "./components/WorkshopModal";

function WeekliesModals({
  open,
  setOpen,
  userChallenge,
  updateWeeklie,
  currentWeek,
  user,
}) {
  return (
    <Modal open={open}>
      {open === "workshop" && (
        <WorkshopModal
          userChallenge={userChallenge}
          setOpen={setOpen}
          updateWeeklie={(points) => updateWeeklie(points, "workshop", 0)}
          currentWeek={currentWeek}
        />
      )}
      {open === "action" && (
        <ActionModal
          userChallenge={userChallenge}
          setOpen={setOpen}
          updateWeeklie={(points) => updateWeeklie(points, "action", 1)}
          currentWeek={currentWeek}
        />
      )}
      {open === "nature" && (
        <NatureModal
          userChallenge={userChallenge}
          setOpen={setOpen}
          updateWeeklie={updateWeeklie}
          currentWeek={currentWeek}
          user={user}
        />
      )}
      {open === "reflection" && (
        <ReflectionModal
          userChallenge={userChallenge}
          setOpen={setOpen}
          updateWeeklie={updateWeeklie}
          currentWeek={currentWeek}
          user={user}
        />
      )}
    </Modal>
  );
}

export default WeekliesModals;
