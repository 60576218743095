import React from "react";

function HomeIcon() {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.7 12.88"
    >
      <rect className="cls-1" y="6.82" width="4.55" height="6.06" rx=".5" ry=".5" />
      <rect
        className="cls-1"
        x="7.58"
        y="3.79"
        width="4.55"
        height="9.09"
        rx=".5"
        ry=".5"
      />
      <rect
        className="cls-1"
        x="15.15"
        width="4.55"
        height="12.88"
        rx=".5"
        ry=".5"
      />
    </svg>
  );
}

export default HomeIcon;
