import React, { useEffect, useRef, useState } from "react";
import "./Analyse.scss";
import DailyScore from "../DailyScore/DailyScore.js";
import HeaderNavigation from "../HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../FooterNavigation/FooterNavigation";
import TrainingIcon from "../Icons/TrainingIcon";
import NourishIcon from "../Icons/NourishIcon";
import ShowerIcon from "../Icons/ShowerIcon";
import SleepIcon from "../Icons/SleepIcon";
import BreathIcon from "../Icons/BreathIcon";
import GratitudeIcon from "../Icons/GratitudeIcon";
import KnowledgeIcon from "../Icons/KnowledgeIcon";
import ActionIcon from "../Icons/ActionIcon";
import NatureIcon from "../Icons/NatureIcon";
import ReflectionIcon from "../Icons/ReflectionIcon";
import CrownIcon from "../Icons/CrownIcon";

import { useStore } from "../../utils/store";
import Footer from "../Footer/Footer";

function Analyse() {
  const footerNavigationRef = useRef(null);
  const challenge = useStore((state) => state.challenge);
  const userChallenge = useStore((state) => state.userChallenge);

  const challengeStartDate = new Date(challenge.startDate);
  const challengeEndDate = new Date(challenge.endDate);

  const [numOfDays, setNumOfDays] = useState(0);
  const [numOfWeeks, setNumOfWeeks] = useState(0);
  const [switchToggle, setSwitchToggle] = useState(false);

  const [weeklyPercent, setWeeklyPercent] = useState("0%");

  useEffect(() => {
    setTimeout(() => {
      setWeeklyPercent("59%");
    }, 100);
  }, []);

  useEffect(() => {
    const oneDay = 24 * 60 * 60 * 1000;
    const days = Math.round(
      Math.abs((challengeStartDate - challengeEndDate) / oneDay)
    );
    setNumOfDays(days);
  }, [challenge]);

  useEffect(() => {
    const daysInAWeek = 7;
    const totalWeeks = Math.ceil(numOfDays / daysInAWeek);
    setNumOfWeeks(totalWeeks);
  }, [numOfDays]);

  const renderDays = () => {
    let daysArray = [];
    for (let i = 0; i < numOfDays; i++) {
      let currentDate = new Date(challengeStartDate);
      currentDate.setDate(challengeStartDate.getDate() + i);
      daysArray.push(
        <div className="analyse-dailies-header-day" key={i}>
          <p>{i + 1}</p>
          {switchToggle ? (
            <p>{currentDate.getDate() + "." + (currentDate.getMonth() + 1)}</p>
          ) : null}
        </div>
      );
    }
    return daysArray;
  };

  const renderWeeks = () => {
    let weeksArray = [];
    for (let i = 0; i < numOfWeeks; i++) {
      weeksArray.push(
        <div className="analyse-weeklies-header-week" key={i}>
          <p>{i + 1}</p>
        </div>
      );
    }
    return weeksArray;
  };

  const computeGridStyle = (numOfDays) => {
    const totalColumns = numOfDays + 1; // +1 for TG or NourishIcon
    return {
      display: "grid",
      gridTemplateColumns: `repeat(${totalColumns}, minmax(min-content, max-content))`,
      gap: "0.15rem",
    };
  };

  const habits = [
    { name: "nourish", Icon: NourishIcon },
    { name: "training", Icon: TrainingIcon },
    { name: "shower", Icon: ShowerIcon },
    { name: "sleep", Icon: SleepIcon },
    { name: "breathing", Icon: BreathIcon },
    { name: "gratitude", Icon: GratitudeIcon },
  ];

  const weeklyHabits = [
    { name: "workshop", Icon: KnowledgeIcon },
    { name: "action", Icon: ActionIcon },
    { name: "nature", Icon: NatureIcon, type: "array" },
    { name: "reflection", Icon: ReflectionIcon, type: "array" },
  ];

  const checkWeeklyHabitCompleted = (habit, weekIndex) => {
    if (habit.type === "array") {
      const weekData = userChallenge.weeklyHabits[habit.name].find(
        (item) => item.week === weekIndex + 1
      );
      return weekData && weekData.points ? true : false;
    }
    return userChallenge.weeklyHabits[habit.name] &&
      userChallenge.weeklyHabits[habit.name][weekIndex + 1]
      ? true
      : false;
  };

  const calculateDailyPercentage = (dayIndex) => {
    let totalPointsForTheDay = 0;

    habits.forEach((habit) => {
      if (habit.name === "nourish") {
        // Handle nourish with new array structure
        const nourishData = userChallenge.dailyHabits.nourish?.find(
          (entry) => entry.day === dayIndex
        );
        if (nourishData) {
          totalPointsForTheDay += nourishData.points || 0;
        }
      } else if (habit.name === "gratitude") {
        const gratitudeData = userChallenge.dailyHabits[habit.name]?.find(
          (entry) => entry.day === dayIndex
        );
        if (gratitudeData) {
          totalPointsForTheDay += gratitudeData.points;
        }
      } else if (
        userChallenge.dailyHabits[habit.name] &&
        userChallenge.dailyHabits[habit.name][dayIndex]
      ) {
        totalPointsForTheDay += userChallenge.dailyHabits[habit.name][dayIndex];
      }
    });

    const percentage = (totalPointsForTheDay / 16) * 100;
    return Math.round(percentage);
  };

  const getFormattedDate = (dayIndex) => {
    let currentDate = new Date(challengeStartDate);
    currentDate.setDate(challengeStartDate.getDate() + dayIndex);
    return `${currentDate.getDate()}.${currentDate.getMonth() + 1}`;
  };

  const calculateWeeklyPercentage = (weekIndex) => {
    let totalPointsForTheWeek = 0;

    // Loop through weeklyHabits to get points for the week
    weeklyHabits.forEach((habit) => {
      if (habit.type === "array") {
        const weekData = userChallenge.weeklyHabits[habit.name]?.find(
          (item) => item.week === weekIndex + 1
        );
        if (weekData && weekData.points) {
          totalPointsForTheWeek += weekData.points;
        }
      } else if (
        userChallenge.weeklyHabits[habit.name] &&
        userChallenge.weeklyHabits[habit.name][weekIndex + 1]
      ) {
        totalPointsForTheWeek +=
          userChallenge.weeklyHabits[habit.name][weekIndex + 1];
      }
    });

    // Calculate the weekly percentage
    const percentage = (totalPointsForTheWeek / 22) * 100;
    return `${Math.round(percentage)}%`;
  };

  const renderDailiesBoxes = (weekIndex) => {
    const startDayIndex = weekIndex * 7;
    const endDayIndex = startDayIndex + 6;

    return [...Array(7)].map((_, dayOffset) => {
      const currentDayIndex = startDayIndex + dayOffset;

      if (currentDayIndex < numOfDays) {
        const currentDate = new Date();
        const habitDate = new Date(challengeStartDate);
        habitDate.setDate(challengeStartDate.getDate() + currentDayIndex);

        if (habitDate > currentDate) {
          // Future date
          return (
            <div
              className="analyse-woche-single-dailies-box future-habit2"
              key={currentDayIndex}
            >
              <p className="analyse-woche-single-dailies-box-date future-habit2-date">
                {getFormattedDate(currentDayIndex)}
              </p>
              <svg viewBox="0 0 11 13" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.42035 5.03712H3.19567V3.73722C3.19567 2.57056 4.14135 1.62488 5.30801 1.62488C6.47467 1.62488 7.42035 2.57056 7.42035 3.73722V5.03712ZM9.04523 5.10591V3.73722C9.04523 1.67308 7.37215 0 5.30801 0C3.24387 0 1.57079 1.67308 1.57079 3.73722V5.10591C0.712312 5.32771 0.111648 6.10034 0.108398 6.98698V10.8867C0.108398 11.9634 0.9815 12.8365 2.05825 12.8365H8.55777C9.63452 12.8365 10.5076 11.9634 10.5076 10.8867V6.98698C10.5044 6.10034 9.90371 5.32771 9.04523 5.10591Z"
                  fill="#D3D3D3"
                />
              </svg>
            </div>
          );
        } else {
          // Current or past date
          const dailyPercentage = calculateDailyPercentage(currentDayIndex + 1); // +1 because days are 1-indexed

          return (
            <div
              className="analyse-woche-single-dailies-box"
              key={currentDayIndex}
            >
              <p className="analyse-woche-single-dailies-box-date">
                {getFormattedDate(currentDayIndex)}
              </p>
              {dailyPercentage >= 80 && <CrownIcon />}
              <p className="analyse-woche-single-dailies-box-percent">
                {dailyPercentage}%
              </p>
            </div>
          );
        }
      } else {
        // Render an empty box for days exceeding the total number of challenge days
        return (
          <div
            className="analyse-woche-single-dailies-box"
            key={currentDayIndex}
          ></div>
        );
      }
    });
  };

  const renderSingleWeek = () => {
    return [...Array(numOfWeeks)].map((_, weekIndex) => {
      const weekPercentage = calculateWeeklyPercentage(weekIndex);

      return (
        <div className="analyse-woche-single" key={weekIndex}>
          <div className="analyse-woche-single-number">
            <p>{weekIndex + 1}</p>
          </div>

          <div className="analyse-woche-single-dailies">
            <h2 className="analyse-woche-single-dailies-title">DAILIES</h2>
            {renderDailiesBoxes(weekIndex)}
          </div>

          <div className="analyse-woche-single-weeklies">
            <h2 className="analyse-woche-single-weeklies-title">WEEKLIES</h2>
            <div className="analyse-woche-single-weeklies-box">
              {parseInt(weekPercentage, 10) >= 80 && <CrownIcon />}
              <div className="analyse-woche-single-weeklies-box-progress">
                <div
                  className="analyse-woche-single-weeklies-box-progress-fill"
                  style={{ width: `${weekPercentage}` }}
                >
                  <p className="analyse-woche-single-weeklies-box-progress-fill-percent">
                    {`${weekPercentage}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="analyse">
      <HeaderNavigation
        analyse
        setSwitchToggle={setSwitchToggle}
        switchToggle={switchToggle}
      />
      {switchToggle ? (
        <div className="analyse-content-habit">
          <div className="analyse-dailies">
            <div
              className="analyse-dailies-header"
              style={computeGridStyle(numOfDays)}
            >
              <p className="analyse-dailies-header-tag">Tg</p>
              {renderDays()}
            </div>

            {habits.map((habit) => (
              <div
                className="analyse-dailies-habits"
                key={habit.name}
                style={computeGridStyle(numOfDays)}
              >
                <div className="analyse-dailies-habits-icon">
                  <habit.Icon />
                </div>

                {[...Array(numOfDays)].map((_, dayIndex) => {
                  let bgColor = {}; // Default empty style
                  let futureClassName = "";
                  let SvgIcon = null;

                  // Get current date and compare with the date of the habit
                  const currentDate = new Date();
                  const habitDate = new Date(challengeStartDate);
                  habitDate.setDate(challengeStartDate.getDate() + dayIndex);

                  if (habitDate > currentDate) {
                    futureClassName = "future-habit"; // This is for future dates
                    SvgIcon = (
                      <svg
                        viewBox="0 0 11 13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.42035 5.03712H3.19567V3.73722C3.19567 2.57056 4.14135 1.62488 5.30801 1.62488C6.47467 1.62488 7.42035 2.57056 7.42035 3.73722V5.03712ZM9.04523 5.10591V3.73722C9.04523 1.67308 7.37215 0 5.30801 0C3.24387 0 1.57079 1.67308 1.57079 3.73722V5.10591C0.712312 5.32771 0.111648 6.10034 0.108398 6.98698V10.8867C0.108398 11.9634 0.9815 12.8365 2.05825 12.8365H8.55777C9.63452 12.8365 10.5076 11.9634 10.5076 10.8867V6.98698C10.5044 6.10034 9.90371 5.32771 9.04523 5.10591Z"
                          fill="#D3D3D3"
                        />
                      </svg>
                    );
                  }

                  if (habit.name !== "gratitude") {
                    if (
                      userChallenge.dailyHabits[habit.name] &&
                      userChallenge.dailyHabits[habit.name][dayIndex + 1]
                    ) {
                      bgColor = { backgroundColor: "#cbf73f" };
                    }
                  } else {
                    // Special case for gratitude
                    const gratitudeForDay =
                      userChallenge.dailyHabits.gratitude.find(
                        (entry) => entry.day === dayIndex + 1
                      );
                    if (gratitudeForDay && gratitudeForDay.points) {
                      bgColor = { backgroundColor: "#cbf73f" }; // Adjust color based on points if needed
                    }
                  }

                  return (
                    <span
                      key={dayIndex}
                      className={futureClassName}
                      style={bgColor}
                    >
                      {SvgIcon}
                    </span>
                  );
                })}
              </div>
            ))}

            <div className="analyse-dailies-boxes"></div>
          </div>

          <div className="analyse-weeklies">
            <div
              className="analyse-weeklies-header"
              style={computeGridStyle(numOfWeeks)}
            >
              <p className="analyse-weeklies-header-tag">Wo</p>
              {renderWeeks()}
            </div>

            {weeklyHabits.map((habit) => (
              <div
                className="analyse-weeklies-habits"
                key={habit.name}
                style={computeGridStyle(numOfWeeks)}
              >
                <div className="analyse-weeklies-habits-icon">
                  <habit.Icon />
                </div>

                {[...Array(numOfWeeks)].map((_, weekIndex) => {
                  let bgColor = {}; // Default empty style
                  let futureClassName = "";
                  let SvgIcon = null;

                  // Get current date and compare with the date of the habit
                  const currentDate = new Date();
                  const weekStartDate = new Date(challengeStartDate);
                  weekStartDate.setDate(
                    challengeStartDate.getDate() + weekIndex * 7
                  );

                  if (weekStartDate > currentDate) {
                    futureClassName = "future-habit"; // This is for future dates
                    SvgIcon = (
                      <svg
                        viewBox="0 0 11 13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.42035 5.03712H3.19567V3.73722C3.19567 2.57056 4.14135 1.62488 5.30801 1.62488C6.47467 1.62488 7.42035 2.57056 7.42035 3.73722V5.03712ZM9.04523 5.10591V3.73722C9.04523 1.67308 7.37215 0 5.30801 0C3.24387 0 1.57079 1.67308 1.57079 3.73722V5.10591C0.712312 5.32771 0.111648 6.10034 0.108398 6.98698V10.8867C0.108398 11.9634 0.9815 12.8365 2.05825 12.8365H8.55777C9.63452 12.8365 10.5076 11.9634 10.5076 10.8867V6.98698C10.5044 6.10034 9.90371 5.32771 9.04523 5.10591Z"
                          fill="#D3D3D3"
                        />
                      </svg>
                    );
                  }

                  if (checkWeeklyHabitCompleted(habit, weekIndex)) {
                    bgColor = { backgroundColor: "#cbf73f" };
                  }

                  return (
                    <span
                      key={weekIndex}
                      className={futureClassName}
                      style={bgColor}
                    >
                      {SvgIcon}
                    </span>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="analyse-content-woche">
          <div className="analyse-woche">
            <div className="analyse-woche-header">
              <p className="analyse-woche-header-tag">Tg</p>
              <div className="analyse-woche-header-days">
                <p>1</p>
                <p>2</p>
                <p>3</p>
                <p>4</p>
                <p>5</p>
                <p>6</p>
                <p>7</p>
              </div>
            </div>
          </div>

          {renderSingleWeek()}
        </div>
      )}
      <Footer />
      <FooterNavigation ref={footerNavigationRef} />
    </div>
  );
}

export default Analyse;
