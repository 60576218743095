import React from 'react'
import "./SingleBlogs.scss"
import burpees from "../../../img/burpees.png"
import BackIcon from '../../Icons/BackIcon'


function Burpees() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">DIE 350 BURPEE CHALLENGE</h1>
      <img src={burpees} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">WOCHE 3</h2>
      <p className="single-blog-text">
        Ja, Du hast richtig gelesen. 350 Burpees erwarten wir von Dir - gesammelt in dieser Woche. Mache jeden Tag 50 Stück. Oder an 5 Tagen jeweils 70. Oder einmal 350? Ganz, wie Du möchtest. Das MEHR an Bewegung verschafft Dir automatisch die Bewegungspunkte an den Tagen, an denen Du mindestens 15min lang Burpees machst. Und tut natürlich immer gut.
<br /> <br /> </p>

      <p className="single-blog-text">Falls Du Berliner*in bist und Du Kinder hast, die gerade Winterferien haben: Umso besser! Burpees gehen immer und überall und die Kids können gleich mitmachen.</p>
      <br />
      <h2 className="single-blog-sub">SCALED VERSION</h2> <br/>



        <p className="single-blog-text">
        <b>Wir wissen: Das sind echt viele Burpees!</b> Und deswegen haben wir für alle, die noch nicht ganz routiniert im Training sind und diesen Umfang nicht gewohnt sind, eine skalierte Version: 150 Burpees reichen für Deine Action Punkte diese Woche aus.
        <br />
        </p>

      <p className="single-blog-text">Wie bei der gesamten Challenge vertrauen wir Dir hier, dass <b>DU</b> für Dich schon das richtige Level an Schwierigkeit wählst (und nicht der Schweinehund die Auswahl getroffen hat!)</p>

      <br />

      <p className="single-blog-text">Wie ein Burpee geht, seht ihr nochmal in diesem VIDEO: <br/>
        </p>
      <a href="https://www.youtube.com/watch?v=auBLPXO8Fww" className="single-blog-link">https://www.youtube.com/watch?v=auBLPXO8Fww</a>

    <br/> <br/>
      <p className="single-blog-text">Wir freuen uns über Beweisfotos- und videos in unserer HiLife Gruppe!</p>
      
    </div>
  )
}

export default Burpees