import React from "react";

function NatureIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: props.color }}
    >
      <path d="M11.4063 3.91051C11.2235 3.3054 10.666 2.89142 10.0339 2.89142C9.40174 2.89142 8.8442 3.3054 8.66138 3.91051L3.37979 22.0959C3.1881 22.7667 3.3214 23.4886 3.74002 24.0468C4.15864 24.6049 4.81434 24.935 5.51203 24.9389H8.19572C8.53411 24.9389 8.80843 25.2132 8.80843 25.5516V31.066C8.80843 31.7428 9.35708 32.2914 10.0339 32.2914C10.7106 32.2914 11.2593 31.7428 11.2593 31.066V25.5516C11.2593 25.2132 11.5336 24.9389 11.872 24.9389H14.5557C15.2534 24.935 15.9091 24.6049 16.3277 24.0468C16.7463 23.4886 16.8796 22.7667 16.6879 22.0959L11.4063 3.91051Z" />
      <path d="M24.7393 6.55743C20.671 6.5564 17.2557 9.62137 16.8182 13.6661C16.3807 17.7108 19.0615 21.4352 23.0359 22.3042C23.3191 22.3631 23.5199 22.6155 23.5139 22.9046V31.066C23.5139 31.7428 24.0625 32.2914 24.7393 32.2914C25.4161 32.2914 25.9647 31.7428 25.9647 31.066V22.9046C25.9586 22.6155 26.1595 22.3631 26.4426 22.3042C30.4171 21.4352 33.0979 17.7108 32.6604 13.6661C32.2229 9.62137 28.8076 6.5564 24.7393 6.55743Z" />
    </svg>
  );
}

export default NatureIcon;
