import React, { useEffect, useState } from "react";
import CrownIcon from "../Icons/CrownIcon";
import "./styles/score.scss";
import classNames from "classnames";

function Score({
  score,
  total,
  title,
  subtitle,
  place,
  points,
  entireTotalScore,
  classes,
  mobileHighlight,
  placeholder,
}) {
  const [entireTotalScoreText, setEntireTotalScoreText] = useState("");
  useEffect(() => {
    if (score <= 9) {
      setEntireTotalScoreText("Spielst Du noch mit?");
    }
    if (score >= 10 && score < 19) {
      setEntireTotalScoreText("Hallo Schweinehund!");
    }
    if (score >= 20 && score < 29) {
      setEntireTotalScoreText("Jetzt aber kämpfen!");
    }
    if (score >= 30 && score < 39) {
      setEntireTotalScoreText("Du kannst mehr!");
    }
    if (score >= 40 && score < 49) {
      setEntireTotalScoreText("Auf geht’s!");
    }
    if (score >= 50 && score < 59) {
      setEntireTotalScoreText("Du schaffst das!");
    }
    if (score >= 60 && score < 69) {
      setEntireTotalScoreText("Da geht noch was!");
    }
    if (score >= 70 && score < 79) {
      setEntireTotalScoreText("Gib nochmal Gas!");
    }
    if (score >= 80 && score < 89) {
      setEntireTotalScoreText("Super, weiter so!");
    }
    if (score >= 90 && score <= 99) {
      setEntireTotalScoreText("WOW, perfekt!");
    }
  }, [score]);

  return (
    <div className={`score ${classes}`}>
      <div className="score-circle">
        <svg viewBox="0 0 36 36" className="score-progress">
          <path
            className="score-progress-background"
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className={`score-progress-bar ${placeholder && "placeholder"} `}
            strokeDasharray={
              place && score > 0
                ? Math.round(((total - score + 1) / total) * 100) + ",100"
                : score > 0
                ? Math.round((score / total) * 100) + ",100"
                : "0,100"
            }
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>

        <div
          className={`score-progress-content ${placeholder && "placeholder"}`}
        >
          {!place && score > 0 && Math.round((score * 100) / total) > 80 && (
            <CrownIcon />
          )}

          {placeholder ? (
            <p className="text-5xl lg:text-6xl font-extrabold">??</p>
          ) : (
            <>
              {place || points ? (
                <>
                  <p className="score-progress-content-rate text-5xl lg:text-6xl font-extrabold">
                    {score}
                    {place ? "." : ""}
                  </p>
                  <p className="text-sm font-extrabold">
                    {place ? "Platz" : "Punkte"}
                  </p>
                </>
              ) : (
                <>
                  <p
                    className={classNames(
                      "score-progress-content-rate font-extrabold",
                      {
                        "text-7xl md:text-8xl lg:text-6xl":
                          mobileHighlight && title !== "Gesamtscore",
                        "text-5xl lg:text-6xl":
                          !mobileHighlight && title !== "Gesamtscore",
                        "text-75xl": title === "Gesamtscore", // Use your custom class here
                      }
                    )}
                  >
                    {score ? `${Math.round((score * 100) / total)}` : 0}
                  </p>

                  <p className="text-lg lg:text-md font-extrabold">%</p>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className="text-center">
        {title && <h3 className="text-xl uppercase font-extrabold">{title}</h3>}
        {subtitle && (
          <p className="text-xs text-gray-500 font-extrabold">{subtitle}</p>
        )}
        {entireTotalScore && (
          <p className="text-xs text-gray-500 font-extrabold">
            {entireTotalScoreText}
          </p>
        )}
      </div>
    </div>
  );
}

export default Score;
