import React from "react";
import "./Blog.scss";

function Blog({ image, title, text, link }) {
  return (
    <div className="blog">
      <div className="blog-image">
        <img src={image} alt="" className="" />
      </div>

      <div className="blog-content">
        <h2 className="blog-content-title">{title}</h2>
        <p className="blog-content-text">{text}</p>
        <a href={`/blog/${link}`} className="blog-content-link">
          JETZT LESEN
        </a>
      </div>
    </div>
  );
}

export default Blog;
