import React from 'react'
import "./SingleBlogs.scss"
import action from "../../../img/action.png"
import BackIcon from '../../Icons/BackIcon'


function Action() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Action</h1>
      <img src={action} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Werde aktiv und stell Dich der wöchentlichen Action Aufgabe.</h2>
      <p className="single-blog-text">
        Wir plädieren für EUDAIMONIE! <br /> <br />
        Nein, das ist keine neue Käsesorte. Der Begriff Eudaimonie kommt aus der griechischen Philosophie und bezeichnet eine gelungene
        Lebensführung - Glück als aktive, kreative und von Werten geleitete Lebensaufgabe. <br /> <br />
        Wie Aristoteles erkannte, gibt es einen Unterschied zwischen den Freuden des Augenblicks (Hedonie) und der Befriedigung, die sich daraus ergibt, dass man sich ständig weiterentwickelt und sein Leben in vollen Zügen genießt (Eudaimonie). <br /> <br />
        Dazu gehört auch: Neue Dinge auszuprobieren. <br /> <br />
        Zu oft hält uns einerseits Faulheit und andererseits die Angst vor dem Scheitern davon ab, Neues zu versuchen. Doch jedes Mal, wenn Du Dich aus deiner Komfortzone wagst, tust Du Dir etwas Gutes: Du lernst etwas über die Sache und immer auch über Dich selbst. Menschen, die sich auf eine Vielzahl von Erfahrungen einlassen, behalten mit größerer Wahrscheinlichkeit positive Emotionen und minimieren negative Emotionen als Menschen mit weniger Erfahrungen. <br /> <br />
        Dabei musst Du kein Adrenalinjunkie sein, aus Flugzeugen springen oder ungesichert Berge erklimmen. Es reicht aus, kleine Dinge anders zu machen oder neu kennen zu lernen. <br /> <br />
        Und das hat nicht nur positive Effekte auf Deine Psyche, sondern auch körperliche! Die Forschung legt nahe, dass das größere Zielbewusstsein und das persönliche Wachstum, das mit Eudaimonia verbunden ist, mit niedrigeren Cortisolspiegeln, einer besseren Immunfunktion und einem effizienteren Schlaf korrelieren. <br /> <br />
        Jede Woche geben wir Dir daher eine andere “Action”-Aufgabe. Wir möchten, dass Du damit neue Dinge ausprobierst und auch mal aus Deiner Komfortzone rauskommst. Die Themen sind ganz unterschiedlich: Sport, Spiel, Verbindung, Achtsamkeit oder Wissen - lass Dich überraschen!        </p> <br />
    </div>
  )
}

export default Action