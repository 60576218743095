import React from "react";
import classNames from "classnames";

function Modal({ open, children, keepUpModal }) {
  return (
    open && (
      <div
        className={classNames(
          "fixed w-full h-full z-[100] top-0 left-0 flex md:items-center justify-center",
          { "bg-antracite/80": open }
        )}
      >
        <div
          className={classNames(
            "h-full w-full bg-beige px-8 py-8 overflow-auto md:rounded-xl md:max-w-2xl md:h-fit md:max-h-[60rem] md:px-36",
            { "bg-pink": keepUpModal }
          )}
        >
          {children}
        </div>
      </div>
    )
  );
}

export default Modal;
