import React from "react";

function DiceIcon() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-10 h-10"
    >
      <path
        d="M22.0586 5.93912C21.7916 6.58405 22.0975 7.32335 22.7421 7.59115C23.3866 7.85895 24.1263 7.55404 24.395 6.9098C24.6636 6.26555 24.3596 5.52547 23.7157 5.25602C23.4055 5.12618 23.0563 5.12524 22.7453 5.25343C22.4343 5.38161 22.1872 5.62835 22.0586 5.93912V5.93912Z"
        fill="black"
      />
      <path
        d="M14.2789 22.966C14.4476 22.9697 14.6153 22.9396 14.7722 22.8775C15.1751 22.7045 15.468 22.3455 15.5565 21.9161L16.2143 18.5891C16.2959 18.0944 16.0775 17.5982 15.6577 17.3241L12.8494 15.4013C12.4936 15.1399 12.0273 15.0828 11.619 15.2508C11.2107 15.4188 10.9195 15.7875 10.8507 16.2236L10.1929 19.5379C10.078 20.0574 10.3004 20.5934 10.7495 20.8788L13.5578 22.751C13.7708 22.8941 14.0223 22.9691 14.2789 22.966Z"
        fill="black"
      />
      <circle cx="14.3295" cy="11.3154" r="1.265" fill="black" />
      <ellipse cx="11.8754" cy="26.4955" rx="1.265" ry="1.265" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4261 19.8541H30.2811C31.3291 19.8541 32.1786 20.7037 32.1786 21.7516V30.6066C32.1786 31.6546 31.3291 32.5041 30.2811 32.5041H21.4261C20.3781 32.5041 19.5286 31.6546 19.5286 30.6066V21.7516C19.5286 20.7037 20.3781 19.8541 21.4261 19.8541ZM21.4261 29.3416C21.4261 30.0403 21.9925 30.6066 22.6911 30.6066C23.3897 30.6066 23.9561 30.0403 23.9561 29.3416C23.9561 28.643 23.3897 28.0766 22.6911 28.0766C21.9925 28.0766 21.4261 28.643 21.4261 29.3416ZM25.8536 27.4441C25.155 27.4441 24.5886 26.8778 24.5886 26.1791C24.5886 25.4805 25.155 24.9141 25.8536 24.9141C26.5522 24.9141 27.1186 25.4805 27.1186 26.1791C27.1186 26.8778 26.5522 27.4441 25.8536 27.4441ZM27.7511 23.0166C27.7511 23.7153 28.3175 24.2816 29.0161 24.2816C29.7147 24.2816 30.2811 23.7153 30.2811 23.0166C30.2811 22.318 29.7147 21.7516 29.0161 21.7516C28.3175 21.7516 27.7511 22.318 27.7511 23.0166Z"
        fill="black"
      />
      <path
        d="M17.1883 27.2544L11.0531 29.7844C10.9065 29.8333 10.746 29.7691 10.6736 29.6326L3.91849 13.428C3.89334 13.3498 3.89334 13.2658 3.91849 13.1876C3.95297 13.1083 4.01626 13.045 4.09559 13.0105L15.3541 8.34267C15.4946 8.28931 15.6532 8.34219 15.7336 8.46917L19.6677 17.9693C19.737 18.1237 19.9121 18.2003 20.0725 18.1464C20.4774 17.9948 20.9053 17.9135 21.3375 17.9061C21.4066 17.9034 21.4711 17.8711 21.5146 17.8175C21.5461 17.7582 21.5461 17.6871 21.5146 17.6278L17.3022 7.20417C16.9533 6.37935 16.0049 5.98982 15.177 6.33132L13.2036 7.20417C13.1071 7.24801 12.9964 7.24801 12.9 7.20417C12.8136 7.14314 12.7618 7.04429 12.7608 6.93852V4.40852C12.7635 4.32473 12.7947 4.24437 12.8494 4.18082C12.9129 4.12615 12.9933 4.0949 13.0771 4.09227H25.2717C25.4464 4.09227 25.5879 4.23386 25.5879 4.40852V17.691C25.5843 17.776 25.6164 17.8586 25.6765 17.9187C25.74 17.9734 25.8204 18.0046 25.9042 18.0073H27.1692C27.3439 18.0073 27.4854 17.8657 27.4854 17.691V3.77602C27.4854 2.87477 26.7548 2.14417 25.8536 2.14417H12.4699C11.5756 2.14417 10.8507 2.86911 10.8507 3.76337V7.95052C10.8534 8.07372 10.7843 8.18727 10.6736 8.24147L2.81794 11.4799C1.99313 11.8287 1.60359 12.7772 1.94509 13.6051L9.11764 30.9103C9.37636 31.5359 9.99702 31.9346 10.6736 31.9096C10.8867 31.9103 11.0977 31.8673 11.2934 31.7831V31.7831L17.5046 29.2531C17.6202 29.2027 17.6948 29.0883 17.6943 28.9622V27.558C17.698 27.4511 17.6451 27.3502 17.5552 27.2924C17.4522 27.2059 17.3069 27.1909 17.1883 27.2544V27.2544Z"
        fill="black"
      />
    </svg>
  );
}

export default DiceIcon;
