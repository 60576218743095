import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useStore } from "../../utils/store";
import HiLifeLogo from "../Icons/HiLifeLogo";
import "./AdminLogin.scss";
import { FaEnvelope, FaLock, FaSpinner } from "react-icons/fa";

function AdminLogin() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const setUser = useStore((state) => state.setUser);

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/login/signin/admin`,
        {
          email: username,
          password: password,
        }
      );

      toast.success("Login successful!");
      setUser(response.data);
      navigate("/admin/dashboard");
    } catch (err) {
      if (!err.response.data.isUsernameMatch)
        toast.error("Invalid email address");
      else if (!err.response.data.isVerified)
        toast.error("Account not verified");
      else if (!err.response.data.isAdmin)
        toast.error("Insufficient permissions");
      else toast.error("Invalid password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-login">
      <Toaster position="top-right" />
      <div className="admin-login-container">
        <div className="admin-login-box">
          <div className="admin-login-logo">
            <HiLifeLogo />
          </div>

          <h1>Admin Login</h1>

          <form onSubmit={handleLogin}>
            <div className="input-group">
              <FaEnvelope className="input-icon" />
              <input
                type="email"
                placeholder="Email Address"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>

            <div className="input-group">
              <FaLock className="input-icon" />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className={`login-button ${isLoading ? "loading" : ""}`}
              disabled={isLoading}
            >
              {isLoading ? <FaSpinner className="spinner" /> : "Login"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
