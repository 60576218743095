import React from 'react'
import "./SingleBlogs.scss"
import reflexion from "../../../img/reflexion.png"
import BackIcon from '../../Icons/BackIcon'


function Reflexion() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Reflexion</h1>
      <img src={reflexion} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Nimm an den wöchentlichen Workshops teil.</h2>
      <p className="single-blog-text">
        Jede Woche stellen wir Dir 3 Fragen, die Deine Woche Revue passieren und Dich mit Deinen
        Werten, Wünschen und Deiner Persönlichkeit in Verbindung kommen lassen. <br /> <br />
        Die erste Frage betrifft, was Du Gutes für einen anderen Menschen getan hast, denn altruistisches Handeln macht nachhaltig glücklich. Es müssen dabei nicht immer große Akte der Selbstlosigkeit sein. Es sind auch die kleinen alltäglichen Dinge, die Du für andere tust: Rufe Deine Eltern an und frage, wie es ihnen geht. Hilf Deinem Arbeitskollegen bei einer Aufgabe. Spiel das Lieblingsspiel Deines Kindes, Schreibe jemandem einen Brief etc. <br /> <br />
        Die zweite Frage betrifft im Prinzip wieder das Thema Dankbarkeit und was Du in der Woche Tolles erlebt hast. Fokussiere Deine Gedanken auf die schönen Dinge in Deinem Leben, denn eine positive Grundeinstellung verlängert Deine Lebensdauer! <br /> <br />
        Die dritte Frage befasst sich damit, wie Du Dich selber siehst, einschätzt und bewertest. Je genauer Du Dich selbst kennst, desto besser kannst Du Dich weiterentwickeln und Deine Lebensfreude steigern. Menschen mit qualitativ hochwertiger Selbstreflexion haben große Vorteile in allen Lebensbereichen: Disziplin, Fokus, Emotionen, Entscheidungen, Problembewältigung.
      </p> <br />
    </div>
  )
}

export default Reflexion