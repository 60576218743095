import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LogoUpload from "../LogoUpload/LogoUpload";
import ColorPicker from "../ColorPicker/ColorPicker";
import "./HomepageSettings.scss";
import axios from "axios";
import {
  FaPalette,
  FaImage,
  FaFont,
  FaSave,
  FaTimes,
  FaChevronDown,
} from "react-icons/fa";
import { useToast } from "../Toast/ToastManager";

function HomepageSettings() {
  const [globalButtonColor, setGlobalButtonColor] = useState("");
  const [globalButtonTextColor, setGlobalButtonTextColor] = useState("");
  const [heroImage, setHeroImage] = useState("");
  const [heroButtonColor, setHeroButtonColor] = useState("");
  const [heroTextColor, setHeroTextColor] = useState("");
  const [textColorModul, setTextColorModul] = useState("");
  const [imageModul, setImageModul] = useState("");
  const [textModul1, setTextModul1] = useState("");
  const [textModul2, setTextModul2] = useState("");
  const [textModul3, setTextModul3] = useState("");
  const [dataExists, setDataExists] = useState(false);
  const [heroText, setHeroText] = useState("");
  const [heroSubline, setHeroSubline] = useState("");
  const [globalButtonText, setGlobalButtonText] = useState("");
  const [footerImage, setFooterImage] = useState("");
  const [footerText, setFooterText] = useState("");
  const [footerTextColor, setFooterTextColor] = useState("");
  const [heroButtonTextColor, setHeroButtonTextColor] = useState("");
  const [logoColor, setLogoColor] = useState("");
  const [loginColor, setLoginColor] = useState("");
  const [habitIconColor, setHabitIconColor] = useState("");
  const [habitBackgroundColor, setHabitBackgroundColor] = useState("");
  const [activeSection, setActiveSection] = useState(null);
  const { showToast, ToastContainer, onClose } = useToast();
  const [heroButtonText, setHeroButtonText] = useState("");

  useEffect(() => {
    const fetchHomepage = async () => {
      try {
        const res = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/homepage`
        );
        const homepage = res.data;

        if (homepage) {
          // If there's data, set dataExists to true
          setDataExists(true);

          setGlobalButtonColor(homepage.globalButtonColor || "");
          setGlobalButtonTextColor(homepage.globalButtonTextColor || "");
          setHeroImage(homepage.heroImage || "");
          setHeroButtonColor(homepage.heroButtonColor || "");
          setHeroTextColor(homepage.heroTextColor || "");
          setTextColorModul(homepage.textColorModul || "");
          setImageModul(homepage.imageModul || "");
          setTextModul1(homepage.textModul1 || "");
          setTextModul2(homepage.textModul2 || "");
          setTextModul3(homepage.textModul3 || "");
          setHeroText(homepage.heroText || "");
          setHeroSubline(homepage.heroSubline || "");
          setGlobalButtonText(homepage.globalButtonText || "");
          setFooterImage(homepage.footerImage || "");
          setFooterText(homepage.footerText || "");
          setFooterTextColor(homepage.footerTextColor || "");
          setHeroButtonTextColor(homepage.heroButtonTextColor || "");
          setLogoColor(homepage.logoColor || "");
          setLoginColor(homepage.loginColor || "");
          setHabitIconColor(homepage.habitIconColor || "");
          setHabitBackgroundColor(homepage.habitBackgroundColor || "");
          setHeroButtonText(homepage.heroButtonText || "");
        } else {
          // If there's no data, set dataExists to false
          setDataExists(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchHomepage();
  }, []);

  const handleSave = async () => {
    const loadingId = showToast("Saving settings...", "loading", null);

    const homepageData = new FormData();
    homepageData.append("globalButtonColor", globalButtonColor);
    homepageData.append("globalButtonTextColor", globalButtonTextColor);
    homepageData.append("heroImage", heroImage); // assuming heroImage is a File object
    homepageData.append("heroButtonColor", heroButtonColor);
    homepageData.append("heroTextColor", heroTextColor);
    homepageData.append("textColorModul", textColorModul);
    homepageData.append("imageModul", imageModul); // assuming imageModul is a File object
    homepageData.append("textModul1", textModul1);
    homepageData.append("textModul2", textModul2);
    homepageData.append("textModul3", textModul3);
    homepageData.append("heroText", heroText);
    homepageData.append("heroSubline", heroSubline);
    homepageData.append("globalButtonText", globalButtonText);
    homepageData.append("footerImage", footerImage); // assuming footerImage is a File object
    homepageData.append("footerText", footerText);
    homepageData.append("footerTextColor", footerTextColor);
    homepageData.append("heroButtonTextColor", heroButtonTextColor);
    homepageData.append("logoColor", logoColor);
    homepageData.append("loginColor", loginColor);
    homepageData.append("habitIconColor", habitIconColor);
    homepageData.append("habitBackgroundColor", habitBackgroundColor);
    homepageData.append("heroButtonText", heroButtonText);

    try {
      if (dataExists) {
        await axios.put(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/homepage`,
          homepageData
        );
        onClose(loadingId);
        showToast("Homepage settings updated successfully!", "success");
      } else {
        await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/homepage`,
          homepageData
        );
        onClose(loadingId);
        showToast("Homepage settings created successfully!", "success");
      }
    } catch (error) {
      console.error(error);
      onClose(loadingId);
      showToast("An error occurred while saving the settings.", "error");
    }
  };

  const sections = [
    {
      title: "Brand",
      icon: <FaPalette />,
      fields: [
        {
          label: "Logo Color",
          type: "color",
          value: logoColor,
          setter: setLogoColor,
        },
        {
          label: "Login Color",
          type: "color",
          value: loginColor,
          setter: setLoginColor,
        },
      ],
    },
    {
      title: "Global Button",
      icon: <FaFont />,
      fields: [
        {
          label: "Button Text",
          type: "text",
          value: globalButtonText,
          setter: setGlobalButtonText,
        },
        {
          label: "Button Color",
          type: "color",
          value: globalButtonColor,
          setter: setGlobalButtonColor,
        },
        {
          label: "Button Text Color",
          type: "color",
          value: globalButtonTextColor,
          setter: setGlobalButtonTextColor,
        },
      ],
    },
    {
      title: "Hero",
      icon: <FaImage />,
      fields: [
        {
          label: "Hero Image",
          type: "image",
          value: heroImage,
          setter: setHeroImage,
        },
        {
          label: "Hero Text",
          type: "text",
          value: heroText,
          setter: setHeroText,
        },
        {
          label: "Hero Subline",
          type: "text",
          value: heroSubline,
          setter: setHeroSubline,
        },
        {
          label: "Text Color",
          type: "color",
          value: heroTextColor,
          setter: setHeroTextColor,
        },
        {
          label: "Button Color",
          type: "color",
          value: heroButtonColor,
          setter: setHeroButtonColor,
        },
        {
          label: "Button Text Color",
          type: "color",
          value: heroButtonTextColor,
          setter: setHeroButtonTextColor,
        },
        {
          label: "Hero Button Text",
          type: "text",
          value: heroButtonText,
          setter: setHeroButtonText,
        },
      ],
    },
    {
      title: "Habit",
      icon: <FaPalette />,
      fields: [
        {
          label: "Icon Color",
          type: "color",
          value: habitIconColor,
          setter: setHabitIconColor,
        },
        {
          label: "Background Color",
          type: "color",
          value: habitBackgroundColor,
          setter: setHabitBackgroundColor,
        },
      ],
    },
    {
      title: "Content",
      icon: <FaFont />,
      fields: [
        {
          label: "Module Image",
          type: "image",
          value: imageModul,
          setter: setImageModul,
        },
        {
          label: "Text Color",
          type: "color",
          value: textColorModul,
          setter: setTextColorModul,
        },
        {
          label: "Text Module 1",
          type: "text",
          value: textModul1,
          setter: setTextModul1,
        },
        {
          label: "Text Module 2",
          type: "text",
          value: textModul2,
          setter: setTextModul2,
        },
        {
          label: "Text Module 3",
          type: "text",
          value: textModul3,
          setter: setTextModul3,
        },
      ],
    },
    {
      title: "Footer",
      icon: <FaImage />,
      fields: [
        {
          label: "Footer Image",
          type: "image",
          value: footerImage,
          setter: setFooterImage,
        },
        {
          label: "Footer Text",
          type: "text",
          value: footerText,
          setter: setFooterText,
        },
        {
          label: "Text Color",
          type: "color",
          value: footerTextColor,
          setter: setFooterTextColor,
        },
      ],
    },
  ];

  const renderField = (field) => {
    switch (field.type) {
      case "color":
        return <ColorPicker color={field.value} setColor={field.setter} />;
      case "image":
        return (
          <LogoUpload
            image={field.value}
            setImage={field.setter}
            preview={field.value}
          />
        );
      case "text":
        return (
          <input
            type="text"
            value={field.value}
            onChange={(e) => field.setter(e.target.value)}
            className="settings-input"
          />
        );
      default:
        return null;
    }
  };

  const toggleSection = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  return (
    <div className="homepage-settings">
      <ToastContainer />
      <div className="settings-header">
        <h2>Homepage Settings</h2>
        <button className="save-button" onClick={handleSave}>
          <FaSave /> Save Changes
        </button>
      </div>

      <div className="settings-grid">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`settings-section ${
              activeSection === index ? "active" : ""
            }`}
            onClick={() => toggleSection(index)}
          >
            <div className="section-header">
              <div className="section-title">
                {section.icon}
                <h3>{section.title}</h3>
              </div>
              <button className="section-toggle">
                {activeSection === index ? (
                  <FaTimes className="close-icon" />
                ) : (
                  <FaChevronDown className="expand-icon" />
                )}
              </button>
            </div>

            {activeSection === index && (
              <div className="section-content">
                {section.fields.map((field, fieldIndex) => (
                  <div
                    key={fieldIndex}
                    className="settings-field"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label>{field.label}</label>
                    {renderField(field)}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomepageSettings;
