import React, { useEffect, useState } from "react";
import "./SingleBlogs.scss";
import BackIcon from "../../Icons/BackIcon";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function SingleBlogs() {
  const { link } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/blog/${link}`
      )
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => console.error(error));
  }, [link]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div className="single-blog">
      <div className="fixed" onClick={() => window.history.back()}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">{blog.title}</h1>
      <h2 className="single-blog-sub">{blog.subTitle}</h2>
      <img src={blog.image} alt="" className="single-blog-image" />

      <div
        className="single-blog-text"
        dangerouslySetInnerHTML={{ __html: blog.text }}
      />
    </div>
  );
}

export default SingleBlogs;
