import React from 'react'
import "./SingleBlogs.scss"
import natur from "../../../img/natur.png"
import BackIcon from '../../Icons/BackIcon'


function Natur() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Natur</h1>
      <img src={natur} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Verbringe jede Woche 120 min in der Natur.</h2>
      <p className="single-blog-text">
        Das Wetter ist manchmal ziemlich ungemütlich, Homeoffice und Amazon Fresh machen es Dir sogar möglich, gar nicht mehr raus zu gehen. Ist gemütlich, aber nicht sonderlich schlau. <br /> <br />
        Viele Studien zur Natur Exposition belegen es: Zeit in der Natur führt zu besserer Gesundheit und mehr Wohlbefinden. Dabei spielt es keine Rolle, ob Du in der Natur Sport treibst (Joggen, Radfahren) oder ob Du einfach einen gemütlichen Spaziergang machst. Wichtig dabei ist nur: Du bist umgeben von Natur, Du siehst “grün”. Das kann ein großer Park sein (z.B. Tiergarten), Wald (z.B. Grunewald) oder der Teufelsberg etc. <br /> <br />
        Warum 120 Minuten? Eine britische Studie hat herausgefunden, dass die 2-Stunden-Marke den Unterschied gemacht hat. Wer weniger Zeit in der Natur verbracht hat, hat deutlich weniger positive Effekte gespürt.  <br /> <br />
        Wichtig ist: Du bist umgeben von Natur, Du siehst “grün”. Dabei ist es egal, ob Du jeden Tag 20 Minuten oder nur einmal 2 Stunden raus
        gehst. Du kannst Deine Natur-Minuten in der App über die gesamte Woche sammeln. Geh dabei barfuß so oft Du kannst.
      </p> <br />

    </div>
  )
}

export default Natur