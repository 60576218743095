import React from 'react'
import "./SingleBlogs.scss"
import omnivor from "../../../img/omnivor.png"
import BackIcon from '../../Icons/BackIcon'


function Omnivor() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Die 8 Besten Rezepte Websites</h1>
      <img src={omnivor} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Omnivor</h2>
      <p className="single-blog-text">
        Es gibt unzählige tolle, leckere glutenfreie und getreidefreie Rezepte im World Wide Web. <br /> <br />
        Wir haben Dir hier ein paar Webseiten und Links zusammengestellt, auf denen Du ganz sicher fündig wirst. </p> <br />

      <h2 className="single-blog-sub"><a href="https://whole30.com/recipes/" className="single-blog-link">1. WHOLE30</a></h2>
      <p className="single-blog-text">
        Das WHOLE30 Ernährungsprogramm existiert seit über 10 Jahren und hat eine Unmenge an Wissen, Quellen und Rezepten zusammengetragen. Ganz klare Empfehlung!
      </p> <br />


      <h2 className="single-blog-sub"><a href="https://www.paleo360.de/paleo-rezepte-finder/" className="single-blog-link">2. PALEO 360</a></h2>
      <p className="single-blog-text">
        Die deutsche Rezept-Website was Paleo Ernährung angeht. Vielleicht nicht die ausgefallensten Gourmet-Ideen, aber solide Rezepte.
      </p> <br />

      <h2 className="single-blog-sub"><a href="https://www.primalkitchen.com/blogs/recipes/" className="single-blog-link">3. PRIMAL KITCHEN</a></h2>
      <p className="single-blog-text">
        Mark Sisson ist mit seiner Website “Mark’s Daily Apple” vor über 10 Jahren zum Anführer der Primal-Ernährung und Wissenschaft geworden. Mit Primal Kitchen produziert er hochwertige Dressings, Saucen, Öle, Nahrungsergänzungsmittel und Snacks, die wir uneingeschränkt empfehlen können. <br /> <br />
        Die Website enthält viele tolle Rezepte, die man auch nach vegan oder vegetarisch filtern kann.      </p> <br />


      <h2 className="single-blog-sub"><a href="https://nomnompaleo.com/recipeindex" className="single-blog-link">4. NOM NOM PALEO</a></h2>
      <p className="single-blog-text">
        Michelle Tam ist mehrfache Rezeptbuch-Autorin und teilt in ihrem Blog seit über 10 Jahren richtig leckere und optisch sehr ansprechende Rezepte - welche oft von ihren Asiatischen Wurzeln inspiriert sind.
      </p> <br />

      <h2 className="single-blog-sub"><a href="https://thepaleodiet.com/recipes" className="single-blog-link">5. THE PALEO DIET</a></h2>
      <p className="single-blog-text">
        Michelle Tam ist mehrfache Rezeptbuch-Autorin und teilt in ihrem Blog seit über 10 Jahren richtig leckere und optisch sehr ansprechende Rezepte - welche oft von ihren Asiatischen Wurzeln inspiriert sind.
      </p> <br />

      <h2 className="single-blog-sub"><a href="https://lowcarbkoestlichkeiten.de/rezepte/" className="single-blog-link">6. LOWCARB KÖSTLICHKEITEN</a></h2>
      <p className="single-blog-text">
        Viele Low Carb Gerichte und Desserts. Achtung: nicht immer Getreidefrei - Rezepte nochmal eigenständig scannen und gegebenenfalls Zutaten weglassen oder substituieren.
      </p> <br />
      <h2 className="single-blog-sub"><a href="https://www.amazingpaleo.com/" className="single-blog-link">7. AMAZING PALEO</a></h2>
      <p className="single-blog-text">
        Ästhetisch sehr anspruchsvolle Seite und Foodpics. Tolle auch außergewöhnliche Rezepte.
      </p> <br />

      <h2 className="single-blog-sub"><a href="https://daniellewalker.com/recipes" className="single-blog-link">8. AGAINST ALL GRAIN</a></h2>
      <p className="single-blog-text">
        Danielle Walker von Against all Grain hat mehrere New York Times Bestseller Rezeptbücher geschrieben und hat eine neue Website mit wirklich leckeren Mahlzeiten und Inspirationen.
      </p> <br />

    </div>
  )
}

export default Omnivor