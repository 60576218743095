import React from 'react'
import "./SingleBlogs.scss"
import warum from "../../../img/warum.png"
import BackIcon from '../../Icons/BackIcon'


function Warum() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">ERNÄHRUNG:</h1>
      <h2 className="single-blog-sub">Das WARUM hinter den Ernährungsreg eln</h2>
      <img src={warum} alt="" className="single-blog-image" />
      <p className="single-blog-text">Je nach Ernährungsplan hat Dein Ernährungs-Habit unterschiedliche Regeln. Hier stellen wir Dir alle vor.</p>

      <h2 className="single-blog-sub">1. KEIN ZUCKER</h2>
      <p className="single-blog-text">
        Zucker findet sich heutzutage leider überall: Tomatensauce, Bacon, Wurst, Brot und natürlich auch in Keksen, Süßigkeiten, Kuchen etc. <br /> <br />
        Leider essen die meisten Menschen viel zu viel davon. <br /> <br />
        Wenn Du Dich an unser Ernährungsmotto hältst, nur natürliche Lebensmittel zu essen, dann ist es relativ einfach,, keinen zusätzlichen Zucker zu essen. Über Obst und stärkehaltiges Gemüse (z.B. Kartoffeln) oder Reis bekommst Du genügend Kohlenhydrate um für Deinen Alltag und Dein Training fit zu sein. <br /> <br />
        Und wenn Du doch mal ein Lebensmittel mit einer längeren Zutatenliste kaufst, studiere sie vorher genau. Denn Zucker versteckt sich hinter vielen verschiedenen Begriffen: Saccharose, Dextrose, Raffinose, Glukose, Fruktosesirup, Glukosesirup, Reissirup, Agavendicksaft, Honig etc. <br /> <br />
        Künstliche Süßstoffe sind im PRO Level auch nicht erlaubt, da Du Deine Geschmacksnerven mal an etwas weniger süß gewöhnen sollst. Außerdem gibt es einige Studien, die darauf hindeuten, dass Süßstoffe Dein Mikrobiom im Darm verändern können. <br /> <br />
        Damit es aber nicht ZU schwierig wird, darfst Du natürliche, kalorienarme Süßstoffe wie Stevia und Xylit zu Dir nehmen.      </p>

      <h2 className="single-blog-sub">2. KEIN ALKOHOL, GEZUCKERTE GETRÄNKE UND SÄFTE</h2>
      <p className="single-blog-text">
        Das beste Getränk für jeden Menschen? WASSER! Kein Getränk versorgt Deinen Körper besser mit Feuchtigkeit und stillt Durst. Gewöhne Dich daran, Deinen Tee oder Kaffee ohne Süßungsmittel zu trinken, damit Dein Geschmackssinn runter kommt von der unnatürlichen Süße. <br /> <br />
        Lass die Finger von Säften (auch selbst gepresst), Limonaden oder anderen gesüßten Getränken und auch von Alkohol.<br /> <br />
        Davon abgesehen, dass diese zuckerhaltigen Getränke ziemlich schlecht für Deine Zähne sind, nimmst Du sehr schnell und unbemerkt eine große Menge an Kalorien (genauer gesagt Kohlenhydrate) zu Dir, die Du viel lieber essen könntest und die Dich in fester Form bei der
        Du auch kauen musst, viel satter machen würden. <br /> <br />
        Smoothies - also Frucht-Pürees, bei denen die GANZE Frucht verwendet wurde (inklusive Fasern = Ballaststoffe) sind erlaubt.      </p>

      <h2 className="single-blog-sub">3. KEINE KÜNSTLICHEN ZUSATZSTOFFE</h2>
      <p className="single-blog-text">
        Die Regel erschließt sich eigentlich von selbst, denn wir wollen ja ECHTE, NATÜRLICHE Lebensmittel essen. Verzichte also für 4 Wochen auf chemische Zusatzstoffe wie Verdickungsmittel (Xanthan, Carrageenan), Geschmacksverstärker oder Farbstoffe oder andere unaussprechliche Stoffe, die sich nicht nach Lebensmittel anhören. <br /> <br />
        Ausnahmen: Ascorbinsäure, natürliche Aromen, natürliche Farbstoffe (z.B. Betacarotin, Chlorophyll, Kurkumin etc.), Soja- oder Sonnenblumen Lecithin. <br /> <br />
        Am besten wäre es natürlich, wenn auch diese Zusatzstoffe möglichst selten Teil Deiner Ernährung sind. Denn auch wenn es z.B. “natürliche Aromen” heißt, sind diese meist alles andere als natürlich. Wir möchten die Challenge aber auch praktikabel und lebensnah gestalten und haben uns bewusst für diesen Kompromiss entschieden!      </p>

      <h2 className="single-blog-sub">4. KEINE INDUSTRIELLEN PFLANZENFETTE</h2>
      <p className="single-blog-text">
        Durch die starke industrielle Verarbeitung, die Instabilität und den hohen Omega-6 Gehalt dieser Fette, solltest Du Deine Finger von Margarine, Sonnenblumenöl, Rapsöl, Weizenkeimöl, Distelöl, Sojaöl etc. lassen.
        Halte Dich an die guten, traditionellen Fette: Olivenöl, Avocadöl und Kokosöl bei pflanzlichen Fetten und Butter, Butterschmalz, Ghee, Schmalz und Rindertalg bei den tierischen Fetten.      </p>

      <h2 className="single-blog-sub">5. KEIN GLUTENHALTIGES GETREIDE</h2>
      <p className="single-blog-text">
        Ja, die Sache mit dem Getreide...Wir wollen es nicht verteufeln, denn manche haben mit Getreide grundsätzlich keine Probleme. <br /> <br />
        Im Rahmen des PRO Levels möchten wir Dich aber dazu motivieren, mal 28 Tage darauf zu
        verzichten. Nur so kannst Du für Dich herausfinden, ob es Dir mit oder ohne Getreide besser geht. <br /> <br />
        Was kann an Getreide problematisch sein? <br /> <br />
        Der Hauptteil der Kalorien aus Getreide (Weizen, Roggen, Dinkel, Gerste etc.) kommt von Kohlenhydraten, so dass der Verzehr von Getreideprodukten meist eine große Insulinausschüttung im Körper verursacht. Im Gegensatz zu Proteinen und Fetten dienen Kohlenhydrate aber nicht als Baustoff für den Körper - sie sind lediglich Energieträger in Form von Glukose. Sie sind außerdem als einziger Makronährstoff nicht essentiell. Im Gegensatz zu unseren Vorfahren essen wir in unserer westlichen Zivilisation heute eindeutig viel zu viele Kohlenhydrate. Vor allem mit verarbeiteten Getreideprodukten wie Brot und Kuchen oder Nudeln erreichen wir sehr schnell eine Menge an Kohlenhydraten, die nicht mehr gesund ist. Und das Getreide nimmt uns zu viel Platz auf dem Teller für wirklich nährstoffreiche Lebensmittel weg. <br /> <br />
        Zudem enthalten die meisten Getreide so genannte Antinutrienten oder Antinährstoffe, die zum Teil verheerende Folgen für unseren Organismus haben. Bestimmte Proteine des Getreides (darunter Gluten und Lektin) machen unseren Darm permeabel für Parasiten und lösen so sehr effektiv Autoimmunerkrankungen wie Rheuma oder Asthma und viele andere aus. Der Körper ist in einem dauerhaften Zustand der Entzündung – oft unterschwellig. Neben diesen Krankheiten können aber auch ein schwaches Immunsystem, eine schlechte Regeneration nach dem Training, eine höhere Verletzungsanfälligkeit beim Sport oder Stimmungsschwankungen Folgen der andauernden Entzündung durch Getreide sein. <br /> <br />
        Des Weiteren enthält Getreide, vor allem die glutenhaltige Sorten, Moleküle, die mit denselben Rezeptoren interagieren wie Opiate (Heroine, Morphium etc.). Das heißt, Getreide kann buchstäblich süchtig machen.
        Kurzum, lass mal ein paar Wochen die Finger davon, es wird Dir vermutlich ziemlich gut tun. <br /> <br />

        Erlaubt sind beim PRO Level alle glutenfreien Getreide wie Reis, Mais, Quinoa, Amaranth etc.      </p>

      <h2 className="single-blog-sub">6. HOCHVERARBEITETE SOJAPRODUKTE</h2>
      <p className="single-blog-text">
        Während der Challenge sollen keine industriell hoch verarbeiteten Sojaprodukte gegessen werden. Dazu gehören vor allem Sojaöl, Sojaprotein, Sojamilch, Sojamehl etc. <br /> <br />
        Aber nun die eigentlich Frage: Warum auf Soja verzichten? <br /> <br />
        Wie alle anderen Hülsenfrüchte enthält auch Soja Pflanzengifte und Antinährstoffe und das in besonders hoher Konzentration. <br /> <br />
        Es ist hier wichtig, zwischen traditionellen und modernen Lebensmitteln aus Soja zu unterscheiden. In Asien wurden traditionelle Sojalebensmittel in kleinen Mengen verzehrt, meist als fermentiertes Gewürz. Zu den traditionellen fermentierten Sojaprodukten gehören Miso, Sojasauce, Tempeh und Natto. <br /> <br />
        Eine lange, langsame Fermentation (wie bei der traditionellen Herstellung von Miso, Tempeh und Sojasauce) entfernt übrigens die Phytinsäure und andere Verdauungshemmer, nicht aber die Phytoöstrogene (Isoflavone) in Soja. <br /> <br />
        Die meisten modernen Soja-Lebensmittel werden aus Sojaprotein-Isolat hergestellt. Einem proteinreichen Pulver, das durch einen industriellen Prozess aus dem Abfallprodukt der Sojaölherstellung gewonnen wird. Dieses Soja ist nicht traditionell verarbeitet und somit nicht unbedenklich was Antinährstoffe, Toxine und Phytoöstrogene angeht. <br /> <br />
        Da Sojaproteinisolat außerdem einen bitteren Eigengeschmack hat, wird es meist nur zusammen mit Zucker oder Süßstoffen verarbeitet. Auch wenn das Soja mit „Frei von Gentechnik“ deklariert ist, kann ein Anteil von 0,9% von gentechnisch veränderten Sojabohnen nicht verhindert werden. Weswegen gentechnisch veränderte Pflanzen bei Soja-Produkten heutzutage fast regulär anzunehmen sind. <br /> <br />
        Solange Soja nicht Deine einzige Proteinquelle ist, kannst Du normale Mengen Soja jedoch bedenkenlos verzehren. Guten Appetit!        </p>

    </div>
  )
}

export default Warum