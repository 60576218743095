import classNames from "classnames";
import React from "react";
import ActionIcon from "../../../../../Icons/ActionIcon";
import ResetIcon from "../../../../../Icons/ResetIcon";
import { Link } from "react-router-dom";
import { useStore } from "../../../../../../utils/store";
import "../../../../styles/weeklie.scss";

function ActionModal({ setOpen, updateWeeklie, currentWeek, userChallenge }) {
  const habitSettings = useStore((state) => state.habitSettings);
  const isHabitChecked = () => {
    return (
      userChallenge.weeklyHabits["action"] &&
      userChallenge.weeklyHabits["action"][currentWeek] &&
      userChallenge.weeklyHabits["action"][currentWeek] > 0
    );
  };

  const weekData = habitSettings[currentWeek - 1];

  return (
    <>
      {!isHabitChecked() ? (
        <div>
          <div className="w-8 w-8 mx-auto mb-4">
            <ActionIcon />
          </div>

          <div className="modal__content">
            {weekData && (
              <>
                <div
                  className="modal__text"
                  dangerouslySetInnerHTML={{ __html: weekData.action.text }}
                />

                <a
                  className="underline font-bold"
                  href={weekData.action.link}
                  rel="noopener noreferrer"
                >
                  {weekData.action.title}
                </a>
              </>
            )}
          </div>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out mb-5"
            )}
            onClick={() => {
              updateWeeklie(5);
              setOpen(false);
            }}
          >
            FERTIG
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="w-full text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PUNKTE WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du deine Punkte zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => {
              updateWeeklie(0);
              setOpen(false);
            }}
          >
            PUNKTE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default ActionModal;
