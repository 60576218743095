import React, { useEffect, useState } from "react";
import {
  FaCheck,
  FaTimes,
  FaExclamationCircle,
  FaSpinner,
  FaExclamationTriangle,
} from "react-icons/fa";
import "./Toast.scss";

const Toast = ({
  message,
  type = "info",
  duration = 3000,
  onClose,
  action,
  persistent = false,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (duration && type !== "loading") {
      const timer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => onClose(), 300); // Wait for fade out animation
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [duration, onClose, type]);

  const getIcon = () => {
    switch (type) {
      case "success":
        return <FaCheck />;
      case "error":
        return <FaTimes />;
      case "warning":
        return <FaExclamationTriangle />;
      case "loading":
        return <FaSpinner className="spinning" />;
      default:
        return <FaExclamationCircle />;
    }
  };

  return (
    <div
      className={`custom-toast ${type} ${isVisible ? "show" : "hide"} ${
        action ? "with-action" : ""
      } ${persistent ? "persistent" : ""}`}
    >
      <div className="toast-icon">{getIcon()}</div>
      <div className="toast-message">{message}</div>
      {action && (
        <button className="toast-action" onClick={action.onClick}>
          {action.label}
        </button>
      )}
      {!persistent && type !== "loading" && (
        <button className="toast-close" onClick={() => setIsVisible(false)}>
          <FaTimes />
        </button>
      )}
      {isVisible && !persistent && type !== "loading" && (
        <div
          className="toast-progress"
          style={{ animationDuration: `${duration}ms` }}
        />
      )}
    </div>
  );
};

export default Toast;
