import React, { useEffect, useState } from "react";
import { useStore } from "../../utils/store";
import HiLifeLogo from "../Icons/HiLifeLogo";
import "./Admin.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChallengeSettings from "../ChallengeSettings/ChallengeSettings";
import HabitSettings from "../HabitSettings/HabitSettings";
import HomepageSettings from "../HomepageSettings/HomepageSettings";
import BlogSettings from "../BlogSettings/BlogSettings";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import {
  FaHome,
  FaDumbbell,
  FaBlog,
  FaHeart,
  FaBars,
  FaTimes,
  FaTachometerAlt,
} from "react-icons/fa";

function Admin() {
  const { state, dispatch } = useStore();
  const [activePage, setActivePage] = useState("Dashboard");
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    // Fetch any global settings here and dispatch to the store
  }, []);

  const menuItems = [
    { id: "Dashboard", icon: <FaTachometerAlt />, label: "Dashboard" },
    { id: "Homepage", icon: <FaHome />, label: "Homepage" },
    { id: "Challenge", icon: <FaDumbbell />, label: "Challenge" },
    { id: "Habits", icon: <FaHeart />, label: "Habits" },
    { id: "Blog", icon: <FaBlog />, label: "Blog" },
  ];

  const handleMenuClick = (pageId) => {
    setActivePage(pageId);
    setMobileMenuOpen(false);
  };

  const renderPage = () => {
    switch (activePage) {
      case "Dashboard":
        return <AdminDashboard />;
      case "Challenge":
        return <ChallengeSettings />;
      case "Habits":
        return <HabitSettings />;
      case "Homepage":
        return <HomepageSettings />;
      case "Blog":
        return <BlogSettings />;
      default:
        return <AdminDashboard />;
    }
  };

  return (
    <div className="admin-dashboard">
      <ToastContainer />

      {/* Mobile Menu Button */}
      <button
        className="mobile-menu-toggle"
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      >
        {mobileMenuOpen ? <FaTimes /> : <FaBars />}
      </button>

      <aside
        className={`admin-sidebar ${sidebarCollapsed ? "collapsed" : ""} ${
          mobileMenuOpen ? "mobile-open" : ""
        }`}
      >
        <div className="admin-sidebar-header">
          <HiLifeLogo className="admin-logo" />
          <button
            className="sidebar-toggle desktop-only"
            onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          >
            {sidebarCollapsed ? "→" : "←"}
          </button>
        </div>

        <nav className="admin-navigation">
          {menuItems.map((item) => (
            <button
              key={item.id}
              className={`admin-navigation-item ${
                activePage === item.id ? "active" : ""
              }`}
              onClick={() => handleMenuClick(item.id)}
            >
              <span className="icon">{item.icon}</span>
              <span className="label">{item.label}</span>
            </button>
          ))}
        </nav>
      </aside>

      <main className={`admin-main ${mobileMenuOpen ? "blur" : ""}`}>
        <header className="admin-header">
          <h1>{activePage === "Initial" ? "Dashboard" : activePage}</h1>
        </header>

        <div className="admin-content">{renderPage()}</div>
      </main>

      {/* Mobile Menu Overlay */}
      {mobileMenuOpen && (
        <div
          className="mobile-overlay"
          onClick={() => setMobileMenuOpen(false)}
        />
      )}
    </div>
  );
}

export default Admin;
