import React from "react";
import "./SingleBlogs.scss";
import erfolg from "../../../img/erfolg.png";
import BackIcon from "../../Icons/BackIcon";

function Erfolg() {
  return (
    <div className="single-blog">
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Tipps für Deinen Erfolg</h1>
      <img src={erfolg} alt="" className="single-blog-image" />
      <p className="single-blog-text">
        Mit diesen 5 Tipps kannst Du selber dafür sorgen, dass Deine neuen
        Gewohnheiten leicht umzusetzen sind, Du dabei bleibst und Deine
        Challenge ein voller Erfolg wird..
      </p>
      <h2 className="single-blog-sub">1. INFORMIERE DICH</h2>
      <p className="single-blog-text">
        Lies Dir die Challenge Regeln vor Beginn der Challenge durch. Stöbere in
        der HiLife Challenge Library und informiere Dich über die Habits, die Du
        in den nächsten 4 Wochen absolvieren sollst. Notiere Dir die Termine der
        Live Workshops in Deinem Kalender.
      </p>
      <h2 className="single-blog-sub">2. FINDE VERBÜNDETE</h2>
      <p className="single-blog-text">
        Geteiltes Leid ist halbes Leid. Und geteilte Freude ist doppelte
        Freunde. Zusammen mit der Community zu spielen ist ein wichtiger Teil
        der Challenge. Such Dir Verbündete aus Freund*innen, Familie oder
        Arbeitskolleg*innen und macht die Challenge zusammen. Verabredet Euch
        für gemeinsames Training und Kochen.
      </p>
      <h2 className="single-blog-sub">3. ORGANISIERE DEINE KÜCHE</h2>
      <p className="single-blog-text">
        Nachdem Du Dich für einen Ernährungsplan für Deine Challenge entschieden
        hast, mach es Dir leicht: Entferne alle Lebensmittel, die Du in den
        nächsten 4 Wochen nicht zuhause haben möchtest - zumindest aus Deinem
        unmittelbaren Sichtfeld. Fülle Deinen Kühlschrank mit guten
        Lebensmitteln von der YES Liste, die DIR schmecken.
      </p>
      <h2 className="single-blog-sub">4. PLANUNG IST ALLES</h2>
      <p className="single-blog-text">
        Überlege Dir schon jetzt, WAS, WANN und WO Du regelmäßig Sport machen
        willst. Trage Dir diese Zeiten als feste Termine in Deinen Kalender ein.
        Pack Deine Sporttasche schon am Abend. Plane Deine Mahlzeiten und hab
        für den Notfall immer Snacks am Start. KURZ GESAGT: Rechne mit
        Hindernissen und überlege Dir vorher wie Du sie überwinden kannst.
      </p>

      <h2 className="single-blog-sub">5. NEVER GIVE UP</h2>
      <p className="single-blog-text">
        Niemand ist perfekt. Perfektion ist eine Illusion und vor allem nicht
        auf Dauer machbar. Erfolgreiche Menschen machen auch Fehler, sogar oft.
        Sie machen TROTZDEM weiter. Denn Erfolg kommt durch Kontinuität.
        Versuche, Dich an das Motto: NEVER MISS TWICE zu halten und Deine
        Vorsätze nicht zwei Mal hintereinander zu versäumen. <br /> <br />
        Du hast einen Tag keine Punkte geholt? KEIN PROBLEM - einfach morgen
        WEITERMACHEN. Du hast zwei Mal hintereinander nicht geschafft, was Du
        vorhattest? KEIN PROBLEM - einfach morgen WEITERMACHEN. Die ganze Woche
        lief schlecht? KEIN PROBLEM - einfach WEITERMACHEN. Verloren hast Du
        erst, wenn Du aufgegeben hast.
      </p>

      {/* <a href="" download="http://localhost:3000/client/src/img/ernaehrungsplan.pdf" className="single-blog-download">DOWNLOAD ERNÄHRUNGSPLAN</a> */}
    </div>
  );
}

export default Erfolg;
