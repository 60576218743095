import React from 'react'
import "./SingleBlogs.scss"
import wissen from "../../../img/wissen.png"
import BackIcon from '../../Icons/BackIcon'


function Wissen() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Wissen</h1>
      <img src={wissen} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Nimm an den wöchentlichen Workshops teil.</h2>
      <p className="single-blog-text">
        Wir wollen NEUROPLASTIZITÄT! <br /> <br />
        Das menschliche Gehirn ist in hohem Maße formbar, oder auch “plastisch”, wie Neurowissenschaftler es nennen. Neue Erfahrungen und intensives Lernen verändern sowohl die Verbindungen zwischen Nervenzellen als auch übergeordnete anatomische Strukturen. Diese Fähigkeit des Gehirns, sich selbst zu regenerieren und erneut zu strukturieren nennt man Neuroplastizität. Wie steigerst Du die Neuroplastizität Deines Gehirns? <br /> <br />
        Neben gesunder Ernährung mit vielen Omega 3 Fettsäuren, gutem Schlaf, viel Bewegung und Meditation ist es vor allem eines: NEUES LERNEN! <br /> <br />
        Damit Dein Gehirn auf Trab gehalten wird, planen wir für jede Challenge verschiedene spannende Workshops, interessante Vorträge, Filme oder Artikel. Das Absolvieren der Wissens-Aufgabe macht nicht nur Spaß, Du lernst sicher auch Neues dabei und steigerst Deine Neuroplastizität. <br /> <br />
        Falls du an einem Workshoptermin nicht live dabei sein kannst, kein Problem. Die meisten Vorträge können als Aufzeichnung innerhalb der jeweiligen Woche angeschaut werden.      </p> <br />
    </div>
  )
}

export default Wissen