import React from 'react'
import "./SingleBlogs.scss"
import dankbarkeit from "../../../img/dankbarkeit.png"
import BackIcon from '../../Icons/BackIcon'


function Dankbarkeit() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Dankbarkeit</h1>
      <img src={dankbarkeit} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Notiere täglich 3 Dinge, für die Du dankbar bist.</h2>
      <p className="single-blog-text">
        Wer sich gut fühlen will, muss auch gut denken. Studien haben bewiesen, dass Dankbarkeit von fundamentaler Bedeutung für das persönliche Wohlbefinden ist. Deswegen sollst Du in der HiLife Challenge eine neue Routine für Dein Glück aufbauen.      </p> <br />
      <ul className="single-blog-list">
        <p className="single-blog-text">Wer dankbar ist, der
        </p>
        <li>kann positive Gefühle mehr genießen</li>
        <li>erlebt langfristig weniger negative
          Gefühle wie Neid, Sorge, Schuld, Wut
          etc.</li>
        <li>hat ein erhöhtes Selbstwertgefühl</li>
        <li>kann besser mit Stress umgehen</li>
        <li>schläft besser
        </li>
      </ul>


      <p className="single-blog-text">
        Klicke auf das Dankbarkeits Habit in Deiner App und trage dort 3 Dinge ein, für die Du Dankbarkeit spürst. <br /> <br />
        Dabei musst Du die Dankbarkeit nicht anderen Menschen gegenüber zum Ausdruck bringen oder eine extrem starke Dankbarkeit spüren,
        um positive Effekte zu erzielen. Wichtig ist nur, dass Du die (wenn auch leichte) Dankbarkeit wirklich fühlst. Nimm Dir 1-2 Minuten Zeit, schließe die Augen und verweile einen Augenblick in diesem Gefühl, bevor Du Dich wieder Deinem Alltag widmest. <br /> <br />
        Damit es zur Gewohnheit werden kann, solltest Du Dein Dankbarkeitsritual immer zur gleichen Zeit erledigen, z.B. morgens im Bett oder abends vor dem Schlafengehen. <br /> <br />
        Newbie Tipp: Dir fällt nichts ein? Dann geh im Kopf einfach mal die "FORM" Merkhilfe durch - in diesen Bereichen gibt es sicher etwas, wofür Du dankbar bist. "FORM" steht für: <br /> <br />
        Family (Familie, Freunde)  <br />
        Occupation (Beruf, Arbeit)  <br />
        Recreation (Freizeit, Hobby)  <br />
        Motivation (Motivation, Inspiration, Spaß)
      </p> <br />
    </div>
  )
}

export default Dankbarkeit