import React, { useState, useEffect } from "react";
import { useToast } from "../Toast/ToastManager";
import ReactQuill from "react-quill";
import axios from "axios";
import {
  FaCalendarAlt,
  FaSave,
  FaChevronDown,
  FaTimes,
  FaQuestion,
  FaVideo,
  FaLink,
  FaTasks,
  FaClock,
} from "react-icons/fa";
import "./HabitSettings.scss";

function HabitSettings() {
  const [challenges, setChallenges] = useState([]);
  const [editingHabit, setEditingHabit] = useState(null);
  const [habitSettings, setHabitSettings] = useState([]);
  const [activeWeek, setActiveWeek] = useState(null);
  const [activeChallengeId, setActiveChallengeId] = useState(null);
  const { showToast, ToastContainer, onClose } = useToast();

  useEffect(() => {
    fetchChallenges();
  }, []);

  const fetchChallenges = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge`
      );
      setChallenges(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (weekIndex, field, value, subfield = null) => {
    // Create a deep copy of habitSettings
    const newHabitSettings = JSON.parse(JSON.stringify(habitSettings));

    if (subfield !== null) {
      if (Array.isArray(newHabitSettings[weekIndex][field])) {
        // If the field is an array (selfReflection), update the specific question
        newHabitSettings[weekIndex][field][subfield] = value;
      } else {
        // If the field is an object (zoomLink, action), update the specific subfield
        newHabitSettings[weekIndex][field] = {
          ...newHabitSettings[weekIndex][field],
          [subfield]: value,
        };
      }
    } else {
      newHabitSettings[weekIndex][field] = value;
    }
    setHabitSettings(newHabitSettings);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const handleSave = async () => {
    const loadingToastId = showToast("Saving changes...", "loading", null);

    try {
      await axios.put(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/habit/${editingHabit.challengeId}`,
        { weeks: habitSettings }
      );
      onClose(loadingToastId);
      showToast("Habit settings updated successfully!", "success");
      setEditingHabit(null);
      setActiveWeek(null);
      fetchChallenges();
    } catch (error) {
      console.error(error);
      onClose(loadingToastId);
      showToast("Failed to save habit settings", "error");
    }
  };

  const startEditingHabit = async (challenge) => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/habit/${challenge._id}`
      );
      let weeks;
      if (response.data && response.data.weeks.length > 0) {
        // If weeks data exists, use it directly
        weeks = response.data.weeks.map((week) => ({
          ...week,
          selfReflection: Array.isArray(week.selfReflection)
            ? week.selfReflection
            : Array(3).fill(""),
        }));
      } else {
        // If weeks data is empty, initialize it based on the challenge duration
        weeks = Array.from({ length: challenge.duration }, () => ({
          selfReflection: Array(3).fill(""),
          zoomLink: { link: "", text: "", afterLink: "", active: false },
          action: { link: "", title: "", text: "" },
        }));
      }
      setEditingHabit({ ...response.data, weeks });
      setHabitSettings(weeks);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleZoomLinkActiveStatus = async (weekIndex, newStatus) => {
    // Update the active status in the state
    handleInputChange(weekIndex, "zoomLink", newStatus, "active");

    try {
      // Send a PUT request to the server to update the habit settings
      await axios.put(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/habit/${editingHabit.challengeId}`,
        { weeks: habitSettings }
      );
      showToast("Zoom link status updated successfully!", "success");
    } catch (error) {
      console.error(error);
      showToast(
        "An error occurred while updating the Zoom link status.",
        "error"
      );
    }
  };

  console.log(challenges);

  return (
    <div className="habit-settings">
      <ToastContainer />
      <div className="settings-header">
        <h2>Habit Settings</h2>
        {editingHabit && (
          <button className="save-button" onClick={handleSave}>
            <FaSave /> Save Changes
          </button>
        )}
      </div>

      <div className="challenges-grid">
        {challenges
          .slice()
          .reverse()
          .map((challenge) => (
            <div
              key={challenge._id}
              className={`challenge-card ${
                activeChallengeId === challenge._id ? "active" : ""
              }`}
            >
              <div
                className="challenge-header"
                onClick={() => {
                  if (activeChallengeId === challenge._id) {
                    setActiveChallengeId(null);
                    setEditingHabit(null);
                    setActiveWeek(null);
                  } else {
                    startEditingHabit(challenge);
                    setActiveChallengeId(challenge._id);
                  }
                }}
              >
                <div
                  className="challenge-image"
                  style={{ backgroundImage: `url(${challenge.image})` }}
                >
                  <div className="challenge-info">
                    <h3>{challenge.name}</h3>
                    <span className="challenge-date">
                      <FaClock /> {formatDate(challenge.startDate)}
                    </span>
                  </div>
                </div>
              </div>

              {activeChallengeId === challenge._id && editingHabit && (
                <div className="weeks-container">
                  {Array(challenge.duration)
                    .fill()
                    .map((_, weekIndex) => (
                      <div
                        key={weekIndex}
                        className={`week-section ${
                          activeWeek === weekIndex ? "active" : ""
                        }`}
                      >
                        <div
                          className="week-header"
                          onClick={() =>
                            setActiveWeek(
                              activeWeek === weekIndex ? null : weekIndex
                            )
                          }
                        >
                          <h4>Week {weekIndex + 1}</h4>
                          <FaChevronDown
                            className={`week-toggle ${
                              activeWeek === weekIndex ? "active" : ""
                            }`}
                          />
                        </div>

                        {activeWeek === weekIndex && (
                          <div className="week-content">
                            <div className="section">
                              <div className="section-header">
                                <FaQuestion />
                                <h5>Self Reflection Questions</h5>
                              </div>
                              <div className="questions-grid">
                                {Array(3)
                                  .fill()
                                  .map((_, qIndex) => (
                                    <input
                                      key={qIndex}
                                      type="text"
                                      value={
                                        habitSettings[weekIndex]
                                          ?.selfReflection[qIndex] || ""
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          weekIndex,
                                          "selfReflection",
                                          e.target.value,
                                          qIndex
                                        )
                                      }
                                      placeholder={`Question ${qIndex + 1}`}
                                    />
                                  ))}
                              </div>
                            </div>

                            <div className="section">
                              <div className="section-header">
                                <FaVideo />
                                <h5>Zoom Settings</h5>
                              </div>
                              <input
                                type="text"
                                value={
                                  habitSettings[weekIndex]?.zoomLink.link || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    weekIndex,
                                    "zoomLink",
                                    e.target.value,
                                    "link"
                                  )
                                }
                                placeholder="Zoom Link"
                              />
                              <div className="rich-text-wrapper">
                                <ReactQuill
                                  value={
                                    habitSettings[weekIndex]?.zoomLink.text ||
                                    ""
                                  }
                                  onChange={(value) =>
                                    handleInputChange(
                                      weekIndex,
                                      "zoomLink",
                                      value,
                                      "text"
                                    )
                                  }
                                  placeholder="Zoom Description"
                                />
                              </div>
                            </div>

                            <div className="section">
                              <div className="section-header">
                                <FaLink />
                                <h5>After-Meeting Link</h5>
                              </div>
                              <div className="link-control">
                                <input
                                  type="text"
                                  value={
                                    habitSettings[weekIndex]?.zoomLink
                                      .afterLink || ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      weekIndex,
                                      "zoomLink",
                                      e.target.value,
                                      "afterLink"
                                    )
                                  }
                                  placeholder="After Meeting Link"
                                />
                                <button
                                  className={`toggle-button ${
                                    habitSettings[weekIndex]?.zoomLink.active
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    toggleZoomLinkActiveStatus(
                                      weekIndex,
                                      !habitSettings[weekIndex]?.zoomLink.active
                                    )
                                  }
                                >
                                  {habitSettings[weekIndex]?.zoomLink.active
                                    ? "Active"
                                    : "Inactive"}
                                </button>
                              </div>
                            </div>

                            <div className="section">
                              <div className="section-header">
                                <FaTasks />
                                <h5>Action Items</h5>
                              </div>
                              <input
                                type="text"
                                value={
                                  habitSettings[weekIndex]?.action.link || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    weekIndex,
                                    "action",
                                    e.target.value,
                                    "link"
                                  )
                                }
                                placeholder="Action Link"
                              />
                              <input
                                type="text"
                                value={
                                  habitSettings[weekIndex]?.action.title || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    weekIndex,
                                    "action",
                                    e.target.value,
                                    "title"
                                  )
                                }
                                placeholder="Action Title"
                              />
                              <div className="rich-text-wrapper">
                                <ReactQuill
                                  value={
                                    habitSettings[weekIndex]?.action.text || ""
                                  }
                                  onChange={(value) =>
                                    handleInputChange(
                                      weekIndex,
                                      "action",
                                      value,
                                      "text"
                                    )
                                  }
                                  placeholder="Action Description"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default HabitSettings;
