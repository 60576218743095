import { useEffect, useState } from "react";
import { differenceInDays, differenceInWeeks } from "date-fns";
import { useStore } from "../utils/store";
const useCalculatePoints = (startDate, endDate) => {
  const [days, setDays] = useState({});
  const habits = useStore((state) => state.habits);
  const challenge = useStore((state) => state.challenge); // Add this line
  const [currentDay, setCurrentDay] = useStore((state) => [
    state.currentDay,
    state.setCurrentDay,
  ]);

  let previousDay = differenceInDays(endDate, startDate);
  if (previousDay < 0) {
    previousDay = differenceInDays(new Date(), startDate);
  }

  let previousWeek = differenceInWeeks(endDate, startDate);
  if (previousWeek < 0) {
    previousWeek = differenceInWeeks(new Date(), startDate);
  }

  useEffect(() => {
    // Calculate currentDay based on challenge.startDate and the current date
    const challengeStartDate = new Date(challenge.startDate); // Use challenge from the store
    const currentDay = differenceInDays(new Date(), challengeStartDate) + 1;
    setCurrentDay(currentDay);
  }, [setCurrentDay, challenge]);

  useEffect(() => {
    let currentDayPoints = 0;
    let previousDayPoints = 0;
    let previousWeeklyPoints = 0;

    if (habits) {
      if (habits.hasOwnProperty(`day${previousDay + 1}`)) {
        for (const props in habits[`day${previousDay + 1}`]) {
          currentDayPoints =
            currentDayPoints + habits[`day${previousDay + 1}`][props];
        }
      }

      const daysArray = new Array(previousDay);
      const weekArray = new Array(previousWeek);

      for (const [index] of daysArray.entries()) {
        if (habits.hasOwnProperty(`day${index + 1}`)) {
          for (const props in habits[`day${index + 1}`]) {
            previousDayPoints =
              previousDayPoints + habits[`day${index + 1}`][props];
          }
        }
      }

      for (const [index] of weekArray.entries()) {
        if (habits.hasOwnProperty(`week${index + 1}`)) {
          for (const props in habits[`week${index + 1}`]) {
            if (props === "2") {
              previousWeeklyPoints =
                previousWeeklyPoints + habits[`week${index + 1}`][props][0];
            } else {
              previousWeeklyPoints =
                previousWeeklyPoints + habits[`week${index + 1}`][props];
            }
          }
        }
      }
    }

    let previousMaxScore = previousDay * 16 + previousWeek * 22;

    setDays({
      currentDay: previousDay + 1,
      currentWeek: previousWeek + 1,
      previousDay: previousDay,
      currentDayPoints: currentDayPoints,
      previousDayPoints: previousDayPoints,
      previousWeeklyPoints: previousWeeklyPoints,
      previousTotalScore: previousDayPoints + previousWeeklyPoints,
      previousMaxScore: previousMaxScore,
      leaderboard: currentDayPoints + previousDayPoints + previousWeeklyPoints,
    });
  }, [habits, previousDay, previousWeek]);

  return days;
};

export { useCalculatePoints };
