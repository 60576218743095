import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HiLifeLogo from "../Icons/HiLifeLogo";
import "./Paid.scss";

function Paid() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 5000);
  }, []);

  return (
    <div className="paid">
      <div className="flex justify-center mb-5 w-32 h-32 mx-auto">
        <HiLifeLogo />
      </div>
      <h2 className="paid-title">BEZAHLUNG</h2>
      <p className="paid-text">
        Vielen Dank! Deine Bezahlung war erfolgreich und du bist nun Teil der
        Challenge. <br /> <br /> Bitte logge dich erneut ein, um fortzufahren.
        Du wirst in Kürze automatisch weitergeleitet. Falls dies nicht der Fall
        ist, klicke bitte <a href="/login">hier</a>.
      </p>
    </div>
  );
}

export default Paid;
