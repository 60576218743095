import React from "react";

function ResetIcon() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
    >
      <g>
        <path d="M944.1,362.2H745c-25.4,0-45.9-20.6-45.9-45.9c0-25.4,20.6-45.9,45.9-45.9h79.9C752.8,168.4,634.3,101.9,500,101.9c-219.9,0-398.1,178.2-398.1,398.1S280.1,898.1,500,898.1c219.9,0,398.1-178.2,398.1-398.1c0-25.4,20.6-45.9,45.9-45.9c25.4,0,45.9,20.6,45.9,45.9c0,270.6-219.4,490-490,490C229.4,990,10,770.6,10,500C10,229.4,229.4,10,500,10c164.2,0,309.3,81,398.1,205v-82.5c0-25.4,20.6-45.9,45.9-45.9c25.4,0,45.9,20.6,45.9,45.9v183.8C990,341.6,969.4,362.2,944.1,362.2z" />
      </g>
    </svg>
  );
}

export default ResetIcon;
