import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./TeamInvite.scss";
import { useStore } from "../../utils/store";

function TeamInvite() {
  const { identifier } = useParams();
  const [teamData, setTeamData] = useState(null);
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const challenge = useStore((state) => state.challenge);
  const userChallenge = useStore((state) => state.userChallenge);
  const setUserChallenge = useStore((state) => state.setUserChallenge);
  const setUser = useStore((state) => state.setUser);

  useEffect(() => {
    const fetchUserChallengeData = async () => {
      try {
        const response = await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/register/get/user/challenge`,
          {
            userId: user._id,
            challengeId: challenge._id,
          }
        );

        if (response.data) {
          setUserChallenge(response.data);
          checkIfUserInTeam(response.data);
        }
      } catch (error) {
        console.error("ERROR FETCHING USER CHALLENGE DATA", error);
        toast.error(
          "Error fetching user challenge data. Please try again later."
        );
      }
    };

    const checkIfUserInTeam = async (userChallengeData) => {
      if (userChallengeData && userChallengeData.team) {
        toast.info("You are already in a team.");
        setTimeout(() => {
          navigate("/challenge-dashboard");
        }, 3000);
      } else {
        fetchTeamData();
      }
    };

    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/team/by-identifier/${identifier}`
        );

        if (response.data.members.includes(user._id)) {
          toast.info(`Du bist bereits im Team - ${response.data.teamName}`);
          setTimeout(() => {
            navigate("/challenge-dashboard");
          }, 5000);
        } else {
          setTeamData(response.data);
        }
      } catch (error) {
        console.error("ERROR FETCHING TEAM DATA", error);
        toast.error("Error fetching team data. Please try again later.");
      }
    };

    if (user && user._id && challenge && challenge._id) {
      fetchUserChallengeData();
    }
  }, [identifier, user._id, challenge._id, navigate, setUserChallenge]);

  const handleJoin = async () => {
    try {
      // API call to add the user to the team
      const response = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/team/add-member/${teamData._id}/challenge/${teamData.challenge._id}`, // Include challenge._id in URL
        {
          userId: user._id, // Assuming `user` contains the logged-in user’s data
        }
      );

      if (response.status === 200) {
        // Notify user of successful join
        toast.success("Successfully joined the team!");

        const updatedUserChallenge = response.data.userChallenge;
        if (updatedUserChallenge) {
          setUserChallenge(updatedUserChallenge);

          setUser((user) => ({
            ...user,
            challenge: updatedUserChallenge,
          })); // Reflect the change in the user object
        }
      } else {
        // Handle other statuses appropriately
        toast.error("Failed to join the team. Please try again.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error joining the team:", error);
      toast.error(
        "An error occurred while trying to join the team. Please try again."
      );
    }
  };

  const handleDecline = () => {
    navigate("/challenge-dashboard");
  };

  return (
    <div className="teamInvite">
      {teamData ? (
        <>
          <h1>Einladung zu einem Team</h1>
          <p>
            Das Team <b>{teamData.teamName}</b> hat dich eingeladen, möchtest du
            dem Team beitreten?
          </p>
          <button className="teamInvite-yes" onClick={handleJoin}>
            Ja
          </button>
          <button className="teamInvite-no" onClick={handleDecline}>
            Nein
          </button>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default TeamInvite;
