import React from "react";

function BackIcon() {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none">
      <circle cx="11.5" cy="12" r="11.5" fill="#1B1B1B" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0642 17.281C12.2701 17.1964 12.4041 16.9955 12.4029 16.773V14.0416C12.4029 13.9662 12.4641 13.905 12.5395 13.905H15.9537C16.2554 13.905 16.5 13.6604 16.5 13.3587V10.6274C16.5 10.3257 16.2554 10.0811 15.9537 10.0811H12.5395C12.4641 10.0811 12.4029 10.0199 12.4029 9.94451V7.21314C12.4042 6.99121 12.2711 6.79055 12.0661 6.70545C11.8612 6.62034 11.6251 6.6677 11.4688 6.82528L6.66159 11.627C6.55817 11.7296 6.5 11.8692 6.5 12.0149C6.5 12.1606 6.55817 12.3002 6.66159 12.4028L11.4688 17.2045C11.6342 17.3465 11.8683 17.3766 12.0642 17.281Z" fill="white" />
    </svg>
  );
}

export default BackIcon;
