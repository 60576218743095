import React, { useState, useEffect } from "react";
import "./SearchUser.scss";
import axios from "axios";
import { FaPlus, FaTimes, FaSearch, FaUserCircle } from "react-icons/fa";

const SearchUser = ({
  challengeId,
  onUserToggle,
  showHiddenOnly = false,
  customAction,
  actionIcon,
  placeholder = "Search users...",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [hiddenUsers, setHiddenUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!challengeId) {
      return;
    }
    fetchHiddenUsers();
  }, [challengeId]);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      fetchUsers();
    } else {
      setFilteredUsers([]);
    }
  }, [searchTerm, challengeId]);

  const fetchHiddenUsers = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge/${challengeId}/users?hidden=true`
      );
      setHiddenUsers(response.data);
    } catch (error) {
      setHiddenUsers([]);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge/${challengeId}/users?search=${searchTerm}&hidden=${showHiddenOnly}`
      );
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoading(false);
  };

  const handleUserAction = async (userId, currentHiddenState) => {
    if (customAction) {
      customAction(userId);
      return;
    }

    try {
      await axios.put(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/challenge/${challengeId}/user/${userId}/hidden`,
        {
          hidden: !currentHiddenState,
        }
      );
      fetchHiddenUsers();
      if (searchTerm.length >= 2) {
        fetchUsers();
      }
    } catch (error) {
      // Handle error silently or show user-friendly message
    }
  };

  const UserItem = ({ user, isHidden }) => (
    <div className="search-user__item">
      <div className="search-user__info">
        <div className="search-user__avatar">
          {user.avatar ? (
            <img src={user.avatar} alt={`${user.firstname} ${user.lastname}`} />
          ) : (
            <FaUserCircle />
          )}
        </div>
        <div className="search-user__details">
          <span className="search-user__name">
            {user.firstname} {user.lastname}
          </span>
          <span className="search-user__email">{user.email}</span>
        </div>
      </div>
      <button
        onClick={() => handleUserAction(user._id, isHidden)}
        className="search-user__action"
      >
        {customAction ? actionIcon : isHidden ? <FaTimes /> : <FaPlus />}
      </button>
    </div>
  );

  return (
    <div className="search-user">
      {hiddenUsers.length > 0 && (
        <div className="search-user__hidden-list">
          <h3>Hidden Users</h3>
          <div className="search-user__hidden-items">
            {hiddenUsers.map((user) => (
              <UserItem key={user._id} user={user} isHidden={true} />
            ))}
          </div>
        </div>
      )}

      <div className="search-user__input-wrapper">
        <FaSearch className="search-user__icon" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={placeholder}
          className="search-user__input"
        />
      </div>

      {searchTerm.length >= 2 && (
        <div className="search-user__results">
          {loading ? (
            <div className="search-user__loading">Loading...</div>
          ) : (
            filteredUsers.map((user) => (
              <UserItem key={user._id} user={user} isHidden={user.hidden} />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SearchUser;
