import React, { useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import { FaTimes, FaSave } from "react-icons/fa";
import "./ColorPicker.scss";

let currentOpenPicker = null;

const ColorPicker = ({ color, setColor }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [tempColor, setTempColor] = useState(color);
  const [pickerId] = useState(() => Math.random());

  useEffect(() => {
    setTempColor(color);
  }, [color]);

  useEffect(() => {
    if (showPicker) {
      if (currentOpenPicker && currentOpenPicker !== pickerId) {
        document.dispatchEvent(
          new CustomEvent("closePicker", {
            detail: { except: pickerId },
          })
        );
      }
      currentOpenPicker = pickerId;
    } else if (currentOpenPicker === pickerId) {
      currentOpenPicker = null;
    }
  }, [showPicker, pickerId]);

  useEffect(() => {
    const handleClosePicker = (e) => {
      if (e.detail.except !== pickerId) {
        setShowPicker(false);
        setTempColor(color);
      }
    };

    document.addEventListener("closePicker", handleClosePicker);
    return () => document.removeEventListener("closePicker", handleClosePicker);
  }, [pickerId, color]);

  const handleChange = (newColor) => {
    setTempColor(newColor.hex);
  };

  const handleSave = () => {
    setColor(tempColor);
    setShowPicker(false);
  };

  const handleClose = () => {
    setShowPicker(false);
    setTempColor(color);
    currentOpenPicker = null;
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleDisplayClick = () => {
    setShowPicker(!showPicker);
  };

  return (
    <div className="compact-color-picker">
      <div className="color-display" onClick={handleDisplayClick}>
        <div className="color-preview" style={{ backgroundColor: color }} />
        <input
          type="text"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          onClick={handleInputClick}
        />
      </div>
      {showPicker && (
        <div className="color-picker-popover">
          <div className="color-picker-cover" onClick={handleClose} />
          <div className="picker-controls">
            <button className="save-picker" onClick={handleSave}>
              <FaSave />
            </button>
            <button className="close-picker" onClick={handleClose}>
              <FaTimes />
            </button>
          </div>
          <ChromePicker color={tempColor} onChange={handleChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
