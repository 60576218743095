import React from "react";

function UploadIcon() {
  return (
    <svg
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="48" cy="47.7738" r="47.25" fill="#D3D3D3" />
      <mask
        id="mask0_48_491"
        style={{maskType:" alpha"}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
      >
        <circle cx="48" cy="47.7738" r="47.25" fill="white" />
      </mask>
      <g mask="url(#mask0_48_491)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.1533 46.2365C49.2823 46.1294 49.3509 45.9647 49.3363 45.7973C49.3043 45.6389 49.1936 45.5081 49.0435 45.4495L47.0853 44.7175L45.9323 44.2966C45.7969 44.1337 45.709 43.9379 45.6761 43.7292C45.5086 42.9395 45.5471 42.1206 45.7859 41.3501C47.7606 39.2134 48.7379 36.3411 48.4762 33.444C48.8001 29.566 45.9195 26.1602 42.0415 25.8354C41.8933 25.8235 41.7441 25.8152 41.595 25.8125C37.7042 25.8903 34.6131 29.1067 34.6909 32.9975C34.6936 33.1466 34.701 33.2958 34.7138 33.444C34.4466 36.3274 35.4174 39.1887 37.3857 41.3135C37.641 42.0931 37.6849 42.9267 37.5138 43.7292C37.4809 43.9379 37.393 44.1337 37.2576 44.2966L36.1046 44.7175C31.6209 46.3646 28.3999 47.5542 27.4116 49.6039C26.5396 51.6289 26.0729 53.8049 26.0391 56.0092C26.0391 56.5144 26.449 56.9243 26.9541 56.9243H43.0407C43.2796 56.9252 43.4791 56.7413 43.4983 56.5034C43.8716 52.4433 45.9204 48.7218 49.1533 46.2365"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.1699 57.8393C46.1699 51.2693 51.4955 45.9437 58.0656 45.9437C64.6311 45.9537 69.9512 51.2738 69.9613 57.8393C69.9613 64.4094 64.6357 69.735 58.0656 69.735C51.4955 69.735 46.1699 64.4094 46.1699 57.8393ZM59.7938 56.1112C59.6929 56.0103 59.6374 55.8728 59.6401 55.7302V51.898C59.6604 51.3222 59.3648 50.7813 58.8693 50.4874C58.3738 50.1935 57.7574 50.1935 57.2619 50.4874C56.7664 50.7813 56.4708 51.3222 56.4911 51.898V55.7302C56.4938 55.8728 56.4383 56.0103 56.3374 56.1112C56.2366 56.212 56.099 56.2675 55.9564 56.2649H52.1242C51.5485 56.2446 51.0075 56.5401 50.7136 57.0356C50.4197 57.5311 50.4197 58.1476 50.7136 58.6431C51.0075 59.1386 51.5485 59.4341 52.1242 59.4138H55.9564C56.099 59.4112 56.2366 59.4667 56.3374 59.5675C56.4383 59.6684 56.4938 59.8059 56.4911 59.9485V63.7807C56.4708 64.3565 56.7664 64.8974 57.2619 65.1913C57.7574 65.4852 58.3738 65.4852 58.8693 65.1913C59.3648 64.8974 59.6604 64.3565 59.6401 63.7807V59.9485C59.6374 59.8059 59.6929 59.6684 59.7938 59.5675C59.8946 59.4667 60.0322 59.4112 60.1748 59.4138H64.007C64.8549 59.3839 65.5269 58.6878 65.5269 57.8393C65.5269 56.9909 64.8549 56.2948 64.007 56.2649H60.1748C60.0322 56.2675 59.8946 56.212 59.7938 56.1112Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default UploadIcon;
