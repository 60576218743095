import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TotalRankTeam.scss";
import { useStore } from "../../utils/store";
import ProfileIcon from "../Icons/ProfileIcon";
import { differenceInDays } from "date-fns";

const MAX_DAILY_POINTS = 4 + 3 + 3 + 2 + 2 + 2; // Sum of daily points
const MAX_WEEKLY_POINTS = 5 + 5 + 2 + 10; // Sum of weekly points

function TotalRankTeam({ ownRankRef, footerNavigationHeight }) {
  const [teamRankings, setTeamRankings] = useState([]);
  const [userTeam, setUserTeam] = useState(null);
  const [userTeamMaxScore, setUserTeamMaxScore] = useState(0);
  const user = useStore((state) => state.user);
  const challenge = useStore((state) => state.challenge);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/ranking/get/challenge/aggregate/${challenge._id}`
        );

        const aggregatedTeams = response.data.aggregatedTeams;

        if (Array.isArray(aggregatedTeams)) {
          // Sort teams by computedTotalScore
          aggregatedTeams.sort((a, b) => {
            const aMaxScore = calculateMaxScore(challenge, a.members.length);
            const bMaxScore = calculateMaxScore(challenge, b.members.length);

            const aPercentage = a.computedTotalScore / aMaxScore;
            const bPercentage = b.computedTotalScore / bMaxScore;

            return bPercentage - aPercentage;
          });

          setTeamRankings(aggregatedTeams);
        } else {
          console.warn("No teams data received or not in the expected format.");
        }
      } catch (error) {
        console.error("Error fetching team rankings:", error);
      }
    };

    fetchData();
  }, [challenge._id]);

  useEffect(() => {
    const foundUserTeam = teamRankings.find(
      (team) => team.members && team.members.includes(user._id)
    );

    setUserTeam(foundUserTeam);
  }, [teamRankings, user._id]);

  useEffect(() => {
    if (userTeam) {
      const score = calculateMaxScore(challenge, userTeam.members.length);
      setUserTeamMaxScore(score);
    }
  }, [userTeam, challenge]);

  const calculateMaxScore = (challenge, memberCount) => {
    const currentDate = new Date();
    const challengeStartDate = new Date(challenge.startDate);
    const challengeEndDate = new Date(challenge.endDate);

    // If the current date is after the challenge's end date, then limit the date used for calculations to the end date
    const effectiveDate =
      currentDate > challengeEndDate ? challengeEndDate : currentDate;

    const currentDay = differenceInDays(effectiveDate, challengeStartDate);
    const currentWeek = Math.floor(currentDay / 7); // Only increment the week if a full week has passed

    const maxDailyScore = currentDay * MAX_DAILY_POINTS;
    const maxWeeklyScore = currentWeek * MAX_WEEKLY_POINTS;

    return (maxDailyScore + maxWeeklyScore) * memberCount;
  };

  return (
    <>
      <div className="totalrank lg:max-w-3xl lg:w-full lg:mx-auto lg:border-r lg:border-l">
        {teamRankings.length ? (
          <div className="pb-32">
            {teamRankings.map((team, index) => {
              const teamMaxScore = calculateMaxScore(
                challenge,
                team.members.length
              );

              return (
                <div key={team._id}>
                  <div className="totalrank-single">
                    <p className="totalrank-rank">{index + 1}</p>

                    {team.avatar ? (
                      <img
                        src={team.avatar}
                        alt=""
                        className="totalrank-avatar"
                      />
                    ) : (
                      <div className="totalrank-single-profile">
                        <ProfileIcon />
                      </div>
                    )}

                    <h2 className="totalrank-name font-extrabold">
                      {team.teamName}
                    </h2>
                    <div className="totalrank-score">
                      <h4 className="totalrank-score-percent font-extrabold">
                        {team.computedTotalScore && team.computedTotalScore > 0
                          ? `${Math.round(
                              (team.computedTotalScore * 100) / teamMaxScore
                            )}%`
                          : `0%`}
                      </h4>
                      <p className="totalrank-score-points">
                        {team.computedTotalScore} Pkt
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center py-24 px-4">
            <p className="reflectionlog-empty">Das Ranking ist aktuell leer.</p>
          </div>
        )}
      </div>

      {userTeam && (
        <div
          className="fixed w-full"
          style={{ bottom: footerNavigationHeight + "px" }}
          ref={ownRankRef}
        >
          <div className="totalrank-self lg:max-w-3xl lg:w-full lg:mx-auto">
            <p className="totalrank-rank">
              {teamRankings.findIndex((team) => team._id === userTeam._id) + 1}
            </p>

            {userTeam.avatar ? (
              <img src={userTeam.avatar} alt="" className="totalrank-avatar" />
            ) : (
              <div className="totalrank-single-profile">
                <ProfileIcon />
              </div>
            )}

            <h2 className="totalrank-name font-extrabold">
              {userTeam.teamName}
            </h2>
            <div className="totalrank-score">
              <h4 className="totalrank-score-percent font-extrabold">
                {userTeam.computedTotalScore && userTeam.computedTotalScore > 0
                  ? `${Math.round(
                      (userTeam.computedTotalScore * 100) / userTeamMaxScore
                    )}%`
                  : `0%`}
              </h4>
              <p className="totalrank-score-points">
                {userTeam.computedTotalScore} Pkt
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TotalRankTeam;
