import React from "react";
import "./styles/action-tile.scss";
import classNames from "classnames";
import CheckIcon from "../Icons/CheckIcon";

function ActionTile({
  onClick,
  classes,
  label,
  icon,
  checked,
  smallIcon,
  disabled,
  nature,
  nourish,
}) {
  return (
    <button
      className={classNames(
        `action-tile relative overflow-hidden aspect-[2/1.25] ${classes}`,
        {
          "action-tile--checked": checked,
          "action-tile--disabled": disabled,
        }
      )}
      onClick={onClick}
    >
      {/* Progress bar for nourish - lowest z-index */}
      {typeof nourish === "number" && nourish > 0 && (
        <span
          style={{
            width: `${nourish}%`,
            backgroundColor: checked ? "#cbf73f" : "rgba(203, 247, 63, 0.5)",
          }}
          className="absolute inset-0 z-0"
        />
      )}

      {/* Icon container - maintain original positioning */}
      <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
        {!!checked && (
          <div className="absolute right-1 top-1">
            <CheckIcon />
          </div>
        )}
        <span
          className={classNames("action-tile-icon", {
            "action-tile-icon-small": smallIcon,
          })}
        >
          {icon}
        </span>
        {label && <span className="action-tile-label">{label}</span>}
      </div>

      {/* Nature progress - highest z-index */}
      {nature > 0 && (
        <span
          style={{
            width: `${Math.floor(
              ((nature > 120 ? 120 : nature) / 120) * 100
            )}%`,
          }}
          className={`absolute bg-black h-4 flex items-center justify-end px-2 bottom-0 left-0 min-w-fit z-20`}
        >
          <span className="font-extrabold text-white">{nature}</span>
        </span>
      )}
    </button>
  );
}

export default ActionTile;
