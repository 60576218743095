import React from "react";

function KnowledgeIcon() {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 36 36"
      width={36}
      height={36}
    >
      <path
        d="M25.8,19.6l-6.7,2.2c-0.6,0.2-1.2,0.2-1.8,0l-6.7-2.2c-0.4-0.1-0.8-0.1-1.1,0.2c-0.3,0.2-0.5,0.6-0.5,1v2.7
	c-0.1,1.2,0.7,2.4,1.9,2.8l5.8,2c0.9,0.3,2,0.3,2.9,0l5.8-2c1.1-0.4,1.9-1.5,1.9-2.7v-2.8c0-0.4-0.2-0.8-0.5-1
	C26.6,19.5,26.2,19.4,25.8,19.6L25.8,19.6z"
      />
      <path
        d="M33.8,12.8c0-0.5-0.3-1-0.8-1.1L18.7,6.8c-0.3-0.1-0.5-0.1-0.8,0L3.6,11.7c-0.9,0.3-1.4,1.1-1.4,2v7.1c0,1,0.8,1.8,1.8,1.8
	s1.8-0.8,1.8-1.8v-5.2c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.4-0.1,0.6,0l11.2,3.8c0.3,0.1,0.5,0.1,0.8,0L33,13.9
	C33.5,13.8,33.8,13.3,33.8,12.8z"
      />
    </svg>
  );
}

export default KnowledgeIcon;
