import React from "react";

function CalendarIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1705 2.02253H12.7678C13.4094 2.02253 13.9295 2.54263 13.9295 3.1842V13.0584C13.9295 13.6999 13.4094 14.22 12.7678 14.22H1.73198C1.09041 14.22 0.570312 13.6999 0.570312 13.0584V3.1842C0.570312 2.54263 1.09041 2.02253 1.73198 2.02253H2.60323C2.76362 2.02253 2.89365 2.15255 2.89365 2.31295V3.61982C2.89365 3.86041 3.08868 4.05545 3.32927 4.05545C3.56986 4.05545 3.7649 3.86041 3.7649 3.61982V0.860863C3.7649 0.540077 4.02494 0.280029 4.34573 0.280029C4.66651 0.280029 4.92656 0.540077 4.92656 0.860863V1.87732C4.92656 1.95752 4.99157 2.02253 5.07177 2.02253H8.70198C8.86237 2.02253 8.9924 2.15255 8.9924 2.31295V3.61982C8.9924 3.86041 9.18743 4.05545 9.42802 4.05545C9.66861 4.05545 9.86365 3.86041 9.86365 3.61982V0.860863C9.86365 0.540077 10.1237 0.280029 10.4445 0.280029C10.7653 0.280029 11.0253 0.540077 11.0253 0.860863V1.87732C11.0253 1.95752 11.0903 2.02253 11.1705 2.02253ZM2.0224 13.0584H12.4774C12.6378 13.0584 12.7678 12.9283 12.7678 12.7679V5.79795C12.7678 5.63755 12.6378 5.50753 12.4774 5.50753H2.0224C1.862 5.50753 1.73198 5.63755 1.73198 5.79795V12.7679C1.73198 12.9283 1.862 13.0584 2.0224 13.0584Z"
        fill="black"
      />
    </svg>
  );
}

export default CalendarIcon;
