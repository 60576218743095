import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Don't forget the CSS import for toastify
import MyRoutes from "./myRoutes";
import CookieBot from "react-cookiebot";
import "./App.scss";

function App() {
  const yourDomainGroupId = "bd3ad1af-40bb-413c-be1c-b7902bf4e927";
  return (
    <>
      <ToastContainer />
      <CookieBot domainGroupId={yourDomainGroupId} />
      <BrowserRouter>
        <MyRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
