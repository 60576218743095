import React from "react";

function ShowerIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: props.color }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2473 22.2262C22.2485 22.5815 22.3842 22.9231 22.627 23.1825C23.7389 24.3502 24.3583 25.9014 24.3567 27.5138C24.3567 31.0088 21.5235 33.8421 18.0285 33.8421C14.5335 33.8421 11.7002 31.0088 11.7002 27.5138C11.6957 25.9008 12.3155 24.3486 13.4299 23.1825C13.6728 22.9231 13.8084 22.5815 13.8096 22.2262V4.31026C13.8096 1.98026 15.6985 0.0914307 18.0285 0.0914307C20.3585 0.0914307 22.2473 1.98026 22.2473 4.31026V22.2262ZM15.3168 28.2421C15.646 29.4698 16.7575 30.3241 18.0285 30.3264C19.2995 30.3241 20.411 29.4698 20.7401 28.2421C21.0692 27.0145 20.5342 25.7187 19.4347 25.081C19.2183 24.9495 19.0853 24.7154 19.0832 24.4622V16.9667C19.0832 16.3843 18.611 15.912 18.0285 15.912C17.446 15.912 16.9738 16.3843 16.9738 16.9667V24.4622C16.9716 24.7154 16.8386 24.9495 16.6222 25.081C15.5228 25.7187 14.9877 27.0145 15.3168 28.2421Z"
      />
      <path d="M32.091 16.9668C32.091 17.7434 31.4614 18.373 30.6847 18.373H25.7627C24.9861 18.373 24.3564 17.7434 24.3564 16.9668C24.3564 16.1901 24.9861 15.5605 25.7627 15.5605H30.6847C31.4614 15.5605 32.091 16.1901 32.091 16.9668Z" />
      <path d="M27.169 5.71654H25.7627C24.9861 5.71654 24.3564 5.08693 24.3564 4.31027C24.3564 3.5336 24.9861 2.90399 25.7627 2.90399H27.169C27.9457 2.90399 28.5753 3.5336 28.5753 4.31027C28.5753 5.08693 27.9457 5.71654 27.169 5.71654Z" />
      <path d="M27.169 12.0448H25.7627C24.9861 12.0448 24.3564 11.4152 24.3564 10.6385C24.3564 9.86185 24.9861 9.23224 25.7627 9.23224H27.169C27.9457 9.23224 28.5753 9.86185 28.5753 10.6385C28.5753 11.4152 27.9457 12.0448 27.169 12.0448Z" />
    </svg>
  );
}

export default ShowerIcon;
