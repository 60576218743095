import React from "react";

function TrainingIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      style={{ fill: props.color }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.582 35.5914C20.6732 35.2713 20.3586 34.606 20.2736 33.6695C20.0717 31.4466 19.7815 29.2314 19.5012 27.0162C19.4753 26.8109 19.2935 26.6024 19.1377 26.4398C17.5146 24.7472 15.8802 23.0653 14.2523 21.3771C12.7005 19.7683 12.507 18.2187 13.6365 16.3024C14.498 14.8408 15.3824 13.3925 16.2551 11.9376C16.3743 11.739 16.4858 11.5357 16.6477 11.2524C15.5275 11.0614 14.4861 10.8673 13.4379 10.7234C13.2818 10.702 13.065 10.8885 12.924 11.0272C11.8047 12.129 10.7136 13.26 9.58023 14.3468C8.69812 15.1926 7.41279 14.8494 7.0614 13.7154C6.88972 13.1611 7.05259 12.677 7.43119 12.2922C8.9101 10.7895 10.3846 9.28024 11.9222 7.83903C12.2371 7.54387 12.8155 7.41586 13.2756 7.40847C15.7927 7.36818 18.3109 7.37556 20.8287 7.39293C24.1251 7.4156 25.8147 10.3912 24.1411 13.2455C22.9027 15.3578 21.6248 17.4474 20.3412 19.5328C20.1177 19.8959 20.1104 20.1395 20.3695 20.4923C21.2219 21.6527 22.0682 22.8213 22.8386 24.0363C23.1205 24.481 23.315 25.0618 23.3246 25.5846C23.3727 28.2301 23.3281 30.877 23.3565 33.523C23.3671 34.5065 23.0828 35.2446 22.0987 35.5914H21.582Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5155 20.915C12.5901 21.878 13.6401 22.8189 14.7386 23.8034C13.5143 24.9142 12.393 25.9747 11.2144 26.9675C10.9546 27.1865 10.4934 27.2388 10.1236 27.2426C7.82307 27.2664 5.52217 27.2628 3.22166 27.251C2.15388 27.2456 1.47856 26.6567 1.46884 25.7722C1.45964 24.9331 2.12511 24.3564 3.12785 24.2341C5.10781 23.9927 7.08439 23.7218 9.05761 23.4302C9.30055 23.3944 9.56021 23.1992 9.73241 23.0067C10.3888 22.2728 11.0126 21.5099 11.5155 20.915"
      />
      <mask
        id="mask0_10_6380"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="22"
        y="0"
        width="9"
        height="9"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6553 0.5914H30.1185V8.05286H22.6553V0.5914Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10_6380)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3557 8.05274C24.3264 8.03693 22.6183 6.29292 22.6559 4.27501C22.6932 2.26318 24.3908 0.58888 26.391 0.591342C28.4243 0.593933 30.1274 2.30762 30.1184 4.34225C30.1095 6.37844 28.3956 8.06868 26.3557 8.05274"
        />
      </g>
      <mask
        id="mask1_10_6380"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="24"
        y="12"
        width="11"
        height="6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1592 12.6768H34.5302V17.333H24.1592V12.6768Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_10_6380)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1592 15.8269C24.8005 14.7727 25.4128 13.7664 26.0755 12.6768C26.6413 13.1404 27.1609 13.6092 27.728 14.0111C27.985 14.1933 28.334 14.3297 28.6449 14.3374C30.042 14.3717 31.4405 14.3497 32.8384 14.3532C33.9125 14.3559 34.5374 14.9154 34.5302 15.8572C34.5232 16.7632 33.8957 17.3193 32.8672 17.321C31.1036 17.3238 29.339 17.3561 27.577 17.3015C27.0275 17.2844 26.4572 17.0907 25.9472 16.8639C25.3445 16.5957 24.7967 16.204 24.1592 15.8269"
        />
      </g>
    </svg>
  );
}

export default TrainingIcon;
