import React from 'react'
import "./SingleBlogs.scss"
import ernaehrung3 from "../../../img/ernaehrung3.png"
import BackIcon from '../../Icons/BackIcon'


function Ernaehrung3() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">ERNÄHRUNG:</h1>
      <h2 className="single-blog-sub">Tipps für Deinen Erfolg</h2>
      <img src={ernaehrung3} alt="" className="single-blog-image" />
      <p className="single-blog-text">Wir wissen es: Eine gesunde Ernährung ist vielleicht simpel - aber nicht einfach. Deswegen gibt dieses Habit auch am meisten Punkte. Du musst täglich so viele Entscheidungen für Deine Ernährung treffen, das ist anstrengend. Deswegen haben wir Dir 6 Tipps für Deinen Erfolg im Bereich Ernährung für die Challenge zusammengestellt.</p>

      <h2 className="single-blog-sub">1. Eat real Food.</h2>
      <p className="single-blog-text">
        Das ist das Motto ALLER Ernährungspläne, egal ob omnivor, vegetarisch oder plant-based. Versuche, möglichst unverarbeitete Lebensmittel zu essen. Das geht am einfachsten, wenn Du Dinge kaufst, die keine Verpackung und wenig Zutaten haben und Du viel SELBER ZUBEREITEST. Wenn Du es Dir leisten kannst, bevorzuge Lebensmittel aus biologischer, nachhaltiger Landwirtschaft.        </p>
      <h2 className="single-blog-sub">2. Iss 2-3  Mahlzeiten am Tag</h2>
      <p className="single-blog-text">
        Iss 2-3 Mahlzeiten am Tag und gib Deinem Körper ein paar Stunden Zeit zwischen den Mahlzeiten und belaste ihn nicht mit ständigem Snacken. 6 Mahlzeiten am Tag, wie früher oft empfohlen wurde, ist eher unphysiologisch. <br />
        Ein leichtes Bauchgrummeln ist ein gutes Zeichen, die nächste Mahlzeit einzunehmen. Aber wenn gerade mal nichts Vernünftiges zur Hand ist, wirst Du es auch noch ein Weilchen länger aushalten. Unsere Körper sind evolutionär auch an längere Fastenzeiten gewöhnt. <br />
        Das Phänomen der Autophagie, das „Recyclingprogramm der Zellen“, beginnt erst dann, wenn kein Insulin ausgeschüttet wird und die Verdauung abgeschlossen ist.
      </p>

      <h2 className="single-blog-sub">3. Priorisiere Protein</h2>
      <p className="single-blog-text">Protein macht satt und ist essenziell für viele Prozesse in Deinem Körper! Plane Deine Mahlzeiten daher immer um eine Proteinquelle herum. Das heißt: Step 1: Die Basis Deiner Mahlzeit ist die Proteinquelle. Step 2: Danach kommt eine große Portion Gemüse Step 3: gesunde Fette dürfen nicht fehlen. Step 4: Zum Schluss noch eventuell stärkehaltiges Gemüse wie Kartoffeln oder reine Kohlenhydratquellen wie Reis und Obst.</p>

      <h2 className="single-blog-sub">4. Hörea auf deinen Körper</h2>
      <p className="single-blog-text">Kalorienzählen ist ausdrücklich nicht Teil dieser Challenge. Wenn Du hauptsächlich natürliche
        Lebensmittel zu Dir nimmst, lasse Dich von Deinem Sättigungsgefühl leiten. Wenn Du Hunger hast, iss. Wenn Du satt bist, hör auf. Stell Dich während der Challenge nicht auf die Waage - es geht um so viel mehr. Wir möchten, dass Du auf andere Dinge achtest: Fühlst Du Dich besser? Schläfst Du besser? Ist das Nachmittags-Tief verschwunden? etc.
      </p>

      <h2 className="single-blog-sub">5. Preparation is Key</h2>
      <p className="single-blog-text">
        Der Schlüssel zum Erfolg? Sei vorbereitet! Du bist zu einer Geburtstagsfeier eingeladen? Backe einen Kuchen mit gesunden Zutaten und bring ihn mit - für Dich und die anderen. Du kriegst regelmäßig Heißhungerattacken am Nachmittag im Büro? Bring Dir gesunde Snacks mit. Du wirst schwach, wenn Du die Kekse im Küchenschrank siehst? Räume alle verlockenden Dinge aus Deinem Sichtfeld oder besser noch: verschenke sie!
      </p>

      <h2 className="single-blog-sub">6. K.I.S.S.</h2>
      <p className="single-blog-text">

        Neue Gewohnheiten zu etablieren ist hart genug, Du musst ganz sicher kein*e Gourmetköchin /-koch werden, um leckeres, gesundes Essen zuzubereiten.Deswegen halte Dich beim Kochen an das K.I.S.S.-Prinzip: Keep It Simple & Stupid.Suche Dir einfache Rezepte mit Zutaten die DIR schmecken und koche gleich etwas mehr, damit Du mehrmals davon essen kannst.Dabei reichen 2-4 Lieblingsgerichte, die Du rotierst.Mach Dir also nicht unnötig Stress.Außer: Kochen ist Dein Hobby - dann ran an die Rezeptbücher und Experimentierküche!      </p>

    </div>
  )
}

export default Ernaehrung3