import React, { useEffect, useMemo } from "react";
import "./styles/dashboard.scss";
import { useNavigate } from "react-router-dom";

import HeaderNavigation from "../../Components/HeaderNavigation/HeaderNavigation.js";
import Countdown from "../../Components/Countdown/Countdown";
import LifestyleSetup from "../../Components/LifestyleSetup";
import Footer from "../../Components/Footer/Footer";
import { getTime } from "date-fns";
import { useStore } from "../../utils/store";
import axios from "axios";
import { useLocation } from "react-router-dom";

function Dashboard() {
  const location = useLocation();
  const challengeId = location.state?.challengeId;
  const selectedDate = useStore((state) => state.selectedDate);
  const user = useStore((state) => state.user);
  const userChallenge = useStore((state) => state.userChallenge);
  const setUserChallenge = useStore((state) => state.setUserChallenge);
  const [challenge, setChallenge] = useStore((state) => [
    state.challenge,
    state.setChallenge,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (challengeId) {
        try {
          const response = await axios.get(
            `${
              process.env.NODE_ENV === "production"
                ? "api"
                : "http://localhost:5001/api"
            }/challenge/${challengeId}`
          );
          setChallenge(response.data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [user, challengeId, setUserChallenge, setChallenge]);

  const allTasksCompleted = useMemo(
    () =>
      userChallenge &&
      user &&
      userChallenge.rules &&
      userChallenge.diet &&
      userChallenge.lifestyleScore?.length === 10 &&
      userChallenge.fbgroup &&
      userChallenge.bookmark &&
      user.bday &&
      user.avatar &&
      user.gender,
    [userChallenge, user]
  );

  useEffect(() => {
    if (
      challenge &&
      getTime(new Date(challenge.startDate)) <= Date.now() &&
      allTasksCompleted
    ) {
      navigate("/home");
    }
  }, [challenge, allTasksCompleted, navigate]);

  if (!challenge || !userChallenge) {
    return null; // or some loading spinner
  }

  return (
    <>
      <HeaderNavigation selectedDate={selectedDate} dashboard />
      <main className="page-container">
        <div className="dashboard px-4 pt-8 pb-7">
          <h2 className="text-lg font-extrabold text-center mb-4 lg:text-2xl lg:mb-10">
            NÄCHSTE CHALLENGE IN
          </h2>
          {challenge && (
            <Countdown
              countdownTimestampMs={getTime(new Date(challenge.startDate))}
              parentClasses={"lg:max-w-screen-md mx-auto"}
              numberClasses={"lg:text-8xl"}
              textClasses={"lg:text-lg lg:uppercase"}
              dashboard
              onCountdownEnd={() => {
                if (allTasksCompleted) {
                  navigate("/home");
                }
              }}
            />
          )}

          <h3 className="text-lg font-extrabold text-center mt-12 lg:text-2xl lg:max-w-screen-sm lg:mx-auto">
            DEINE AUFGABEN VOR DEM START
          </h3>
          <p className="mt-4 text-sm break-normal text-center lg:max-w-screen-sm lg:mx-auto">
            Bevor Deine HiLife Challenge losgehen kann, haben wir noch ein paar
            kleine Hausaufgaben für Dich. Klicke auf die verschiedenen Symbole
            und befolge die Anweisungen.
          </p>
          <LifestyleSetup challenge={challenge} />
        </div>
        <Footer />
      </main>
    </>
  );
}

export default Dashboard;
