import React from "react";

function ProfileHomepageIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="w-8 h-8"
      style={{ fill: props.color }}
    >
      <path d="M12.9912 11.195C13.0588 11.1389 13.0947 11.0527 13.087 10.965C13.0703 10.8821 13.0123 10.8136 12.9337 10.7829L11.9083 10.3996L11.3045 10.1792C11.2336 10.0939 11.1876 9.99133 11.1704 9.88208C11.0827 9.46856 11.1028 9.03971 11.2279 8.63625C12.2619 7.5174 12.7737 6.01329 12.6366 4.49625C12.8062 2.46554 11.2978 0.682083 9.26712 0.511979C9.18949 0.50575 9.11139 0.501437 9.03328 0.5C6.99587 0.540729 5.37724 2.225 5.41797 4.26242C5.41941 4.34052 5.42324 4.41862 5.42995 4.49625C5.29003 6.0061 5.79843 7.50446 6.82912 8.61708C6.96281 9.02533 6.98581 9.46185 6.8962 9.88208C6.87895 9.99133 6.83295 10.0939 6.76203 10.1792L6.15828 10.3996C3.81037 11.2621 2.1237 11.885 1.6062 12.9583C1.14956 14.0187 0.90518 15.1582 0.887451 16.3125C0.887451 16.577 1.10212 16.7917 1.36662 16.7917H9.79037C9.91543 16.7921 10.0199 16.6958 10.03 16.5713C10.2255 14.4452 11.2983 12.4964 12.9912 11.195" />
      <path d="M11.4291 17.2708C11.4291 13.8304 14.2179 11.0417 17.6583 11.0417C21.0963 11.0469 23.8822 13.8328 23.8875 17.2708C23.8875 20.7113 21.0987 23.5 17.6583 23.5C14.2179 23.5 11.4291 20.7113 11.4291 17.2708ZM22.0234 16.7945L18.9827 13.7538C18.8558 13.6259 18.6831 13.554 18.5029 13.554C18.3228 13.554 18.1501 13.6259 18.0232 13.7538C17.7612 14.0174 17.7612 14.443 18.0232 14.7066L19.6246 16.308C19.6684 16.3585 19.6799 16.4294 19.654 16.4911C19.6282 16.5527 19.5697 16.5944 19.503 16.5986H13.773C13.3998 16.5986 13.0973 16.9011 13.0973 17.2743C13.0973 17.6475 13.3998 17.95 13.773 17.95H19.503C19.5704 17.9498 19.6314 17.9896 19.6584 18.0514C19.6865 18.1132 19.673 18.1861 19.6246 18.2338L18.0232 19.8352C17.7601 20.1002 17.7616 20.5283 18.0266 20.7914C18.2915 21.0545 18.7196 21.0529 18.9827 20.788L22.0234 17.7473C22.1482 17.6214 22.2186 17.4516 22.2193 17.2743C22.2204 17.0947 22.1499 16.9221 22.0234 16.7945Z" />
    </svg>
  );
}

export default ProfileHomepageIcon;
