import React from "react";

function SleepIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: props.color }}
    >
      <path d="M29.7931 20.8937C25.6007 22.4703 20.8737 21.4487 17.7065 18.2816C14.5394 15.1144 13.5178 10.3874 15.0944 6.19501C15.2351 5.77919 15.1249 5.31951 14.8108 5.01277C14.4968 4.70603 14.0346 4.60662 13.6222 4.7571C7.69577 6.96089 4.04857 12.9357 4.79716 19.2141C5.54575 25.4926 10.4955 30.4424 16.774 31.191C23.0525 31.9396 29.0272 28.2924 31.231 22.3659C31.3815 21.9535 31.2821 21.4913 30.9754 21.1773C30.6686 20.8633 30.2089 20.753 29.7931 20.8937Z" />
    </svg>
  );
}

export default SleepIcon;
