import React from "react";

function Footer() {
  return (
    <div className="flex gap-4 text-xs pb-6 pt-14 justify-center">
      <a href="/impressum" target="_blank">
        Impressum
      </a>
      <a href="/agb" target="_blank">
        AGB
      </a>
      <a href="/datenschutz" target="_blank">
        Datenschutz
      </a>
    </div>
  );
}

export default Footer;
