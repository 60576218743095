import React from "react";

function NourishIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: props.color }}
    >
      <path
        clipRule="evenodd"
        d="M17.9997 9.27653C25.7143 9.27653 30.4426 11.7651 30.4426 13.6315C30.4426 15.4979 25.7143 17.9865 17.9997 17.9865C10.2852 17.9865 5.55692 15.4979 5.55692 13.6315C5.55692 11.7651 10.2852 9.27653 17.9997 9.27653M17.9997 6.78796C10.758 6.78796 3.06836 9.18943 3.06836 13.6315V14.8758C3.25189 22.945 9.92995 29.3449 17.9997 29.185C26.0695 29.3449 32.7476 22.945 32.9311 14.8758V13.6315C32.9311 9.18943 25.2415 6.78796 17.9997 6.78796"
      />
    </svg>
  );
}

export default NourishIcon;
