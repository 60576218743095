import React from "react";
import LogoUpload from "../../LogoUpload/LogoUpload";
import ReactQuill from "react-quill";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./BlogForm.scss";

function BlogForm({
  blogForm,
  onInputChange,
  onSubmit,
  uniqueCategories,
  quillRef,
  modules,
  handleCategoryClick,
}) {
  return (
    <form onSubmit={onSubmit} className="blog-settings-form">
      <div className="header-image-section">
        <div className="image-upload-container">
          <LogoUpload
            image={blogForm.image}
            setImage={(image) =>
              onInputChange({ target: { name: "image", value: image } })
            }
            buttonText={
              blogForm.image ? "Change Header Image" : "Select Header Image"
            }
          />
        </div>
        {blogForm.image && (
          <div className="preview-container">
            <img src={blogForm.image} alt="Header" />
          </div>
        )}
      </div>

      <div className="blog-settings-form-wrapper">
        <div className="form-section">
          <input
            type="text"
            name="title"
            value={blogForm.title}
            onChange={onInputChange}
            placeholder="Titel"
            className="blog-settings-form-input"
            required
          />
          <div className="form-divider"></div>
        </div>

        <div className="form-section">
          <input
            type="text"
            name="subTitle"
            value={blogForm.subTitle}
            onChange={onInputChange}
            placeholder="Sub Titel"
            className="blog-settings-form-input"
          />
          <div className="form-divider"></div>
        </div>

        <div className="form-section">
          <div className="category-container">
            <input
              type="text"
              name="category"
              value={blogForm.category}
              onChange={onInputChange}
              placeholder="Kategorie"
              className="blog-settings-form-input"
              required
            />
            <div className="existing-categories">
              {uniqueCategories.map((category) => (
                <button
                  type="button"
                  key={category}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
          <div className="form-divider"></div>
        </div>

        <div className="form-section">
          <div className="date-section">
            <p className="label">Veröffentlichungsdatum</p>
            <DatePicker
              selected={
                blogForm.releaseDate ? new Date(blogForm.releaseDate) : null
              }
              onChange={(date) =>
                onInputChange({
                  target: { name: "releaseDate", value: date.toISOString() },
                })
              }
              className="blog-settings-form-input"
              dateFormat="dd.MM.yyyy"
              placeholderText="Wähle ein Datum"
              required
            />
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  name="showOnDashboard"
                  checked={blogForm.showOnDashboard}
                  onChange={(e) =>
                    onInputChange({
                      target: {
                        name: "showOnDashboard",
                        value: e.target.checked,
                      },
                    })
                  }
                />
                Auf Dashboard anzeigen
              </label>

              <label>
                <input
                  type="checkbox"
                  name="hidden"
                  checked={blogForm.hidden}
                  onChange={(e) =>
                    onInputChange({
                      target: {
                        name: "hidden",
                        value: e.target.checked,
                      },
                    })
                  }
                />
                Blog nicht anzeigen
              </label>
            </div>
          </div>
          <div className="form-divider"></div>
        </div>
      </div>

      <ReactQuill
        ref={quillRef}
        className="blog-settings-form-rich"
        value={blogForm.text}
        onChange={(value) => onInputChange({ target: { name: "text", value } })}
        modules={modules}
        theme="snow"
        placeholder="Blog content here..."
      />

      <button type="submit" className="blog-settings-form-submit">
        {blogForm.editing ? "Blog aktualisieren" : "Blog erstellen"}
      </button>
    </form>
  );
}

export default BlogForm;
