import React from 'react'
import "./SingleBlogs.scss"
import vegane from "../../../img/vegane.png"
import BackIcon from '../../Icons/BackIcon'


function Vegane() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Die 5 Besten Rezepte Websites</h1>
      <img src={vegane} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Vegane</h2>
      <p className="single-blog-text">
        Auch im Plant-based Universum gibt es immer mehr Webseiten, die zucker- soja- und glutenfreie Gerichte zusammenstellen. <br /> <br />
        Wir haben Dir hier ein paar Webseiten und Links zusammengestellt, auf denen Du ganz sicher fündig wirst.
      </p> <br />

      <h2 className="single-blog-sub"><a href="https://whole30.com/recipes/?categories=plant-based" className="single-blog-link">1. WHOLE 30 PLANT-BASED</a></h2>
      <p className="single-blog-text">
        Seit 2022 bietet the WHOLE30 auch eine komplette plant-based Variante inklusive einiger Rezeptideen an.      </p> <br />


      <h2 className="single-blog-sub"><a href="https://theveganmonster.de/" className="single-blog-link">2. THE VEGANE MONSTER</a></h2>
      <p className="single-blog-text">
        Hier findest Du viele vegane und glutenfreie Rezepte - aber Achtung: nicht immer zuckerfrei/lowcarb und auch nicht “oil-conscious”. Also gerne mal selbständig das Rapsöl gegen Olivenöl oder Avocadoöl tauschen. Zum Stöbern trotzdem toll mit sehr ansprechenden Bildern.
      </p> <br />

      <h2 className="single-blog-sub"><a href="https://www.primalkitchen.com/blogs/recipes/tagged/vegan" className="single-blog-link">3. PRIMAL KITCHEN</a></h2>
      <p className="single-blog-text">
        Mark Sisson ist mit seiner Website “Mark’s Daily Apple” vor über 10 Jahren zum Anführer der artgerechten Ernährung und Wissenschaft geworden. <br /> <br />
        Mit Primal Kitchen produziert er hochwertige Dressings, Saucen, Öle, Nahrungsergänzungsmittel und Snacks, die wir uneingeschränkt empfehlen können. <br /> <br />
        Die Website enthält viele tolle Rezepte, die man auch nach vegan oder vegetarisch filtern kann.</p> <br />


      <h2 className="single-blog-sub"><a href="https://cookieandkate.com/dairy-free-gluten-free-soy-free-and-sugar-free-recipes/" className="single-blog-link">4. COOKIE AND KATE</a></h2>
      <p className="single-blog-text">
        Ein vegetarisch-veganer Rezepteblog mit vielen gluten-, zucker- uns sojafreien Rezepten.      </p> <br />

      <h2 className="single-blog-sub"><a href="https://simple-veganista.com/recipes/diet/gluten-free/" className="single-blog-link">5. SIMPLE VEGANISTA</a></h2>
      <p className="single-blog-text">
        Hier findest Du Glutenfreie vegane Rezepte. Scanne das Rezept vorher nach Zucker und industriellen Pflanzen- und Getreideölen.      </p> <br />


    </div>
  )
}

export default Vegane