import React from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import '@react-pdf-viewer/core/lib/styles/index.css'
import "@react-pdf-viewer/default-layout/lib/styles/index.css"

import impressum from "./pdf/impressum.pdf"
import BackIcon from '../Icons/BackIcon';
import "./Law.scss"

function Impressum() {
    const newplugin = defaultLayoutPlugin();
    return (
        <div className='law'>
            <div className='fixed' onClick={() => (window.location = "/")}>
                <BackIcon />
            </div>
            <h1 className="law-title">
                IMPRESSUM
            </h1>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                <Viewer fileUrl={impressum} plugins={[newplugin]} />
            </Worker>
        </div>
    )
}

export default Impressum