import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// TwicPics Components importation
// import { installTwicPics } from "@twicpics/components/react";
// import "@twicpics/components/style.css";

// // TwicPics Components configuration (see Setup Options)
// installTwicPics({
//   // domain is mandatory
//   domain: "https://myleo.twic.pics",
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
