import classNames from "classnames";
import React, { useState } from "react";
import { useStore } from "../../../../../../utils/store";
import ReflectionIcon from "../../../../../Icons/ReflectionIcon";
import ResetIcon from "../../../../../Icons/ResetIcon";
import "../../../../styles/weeklie.scss";

function ReflectionModal({
  setOpen,
  updateWeeklie,
  currentWeek,
  userChallenge,
}) {
  const habitSettings = useStore((state) => state.habitSettings);
  const isHabitChecked = () => {
    const reflectionLog = userChallenge.weeklyHabits["reflection"].find(
      (log) => log.week === currentWeek
    );
    return reflectionLog && reflectionLog.points > 0;
  };

  const [reflectionLogs, setReflectionLogs] = useState(["", "", ""]);

  const weekData = habitSettings[currentWeek - 1];

  const handleInputChange = (index, value) => {
    setReflectionLogs((prevLogs) => {
      const newLogs = [...prevLogs];
      newLogs[index] = value;
      return newLogs;
    });
  };

  function sendReflectionToBackend() {
    updateWeeklie(2, "reflection", reflectionLogs);
    setOpen(false);
  }

  function removeReflectionFromBackend() {
    updateWeeklie(0, "reflection", "reset");
    setOpen(false);
  }

  return (
    <>
      {!isHabitChecked() ? (
        <div>
          <div className="w-8 w-8 mx-auto mb-4">
            <ReflectionIcon />
          </div>

          <p className="text-sm text-center pt-8">
            Reflektiere Dich und Dein Leben mit 3 wöchentlichen Fragen" (2
            Punkte)
          </p>

          <div className="modal__content">
            {weekData &&
              weekData.selfReflection.map((question, index) => (
                <div className="mb-3 mt-5" key={index}>
                  <label
                    htmlFor={`reflection${index + 1}`}
                    className="text-xs block text-left"
                  >
                    {question}
                  </label>

                  <div className="relative mt-2">
                    <input
                      type="text"
                      name={`reflection${index + 1}`}
                      className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                      placeholder="..."
                      value={reflectionLogs[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                  </div>
                </div>
              ))}
          </div>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out mb-5"
            )}
            onClick={sendReflectionToBackend}
          >
            FERTIG / ANALOG
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="w-full text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PUNKTE WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du deine Punkte zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={removeReflectionFromBackend}
          >
            PUNKTE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default ReflectionModal;
