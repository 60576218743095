import React, { Component } from "react";
import axios from "axios";
import HiLifeLogo from "../Icons/HiLifeLogo";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      passwordRepeat: "",
    };

    this.changeFirstName = this.changeFirstName.bind(this);
    this.changeLastName = this.changeLastName.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changePasswordRepeat = this.changePasswordRepeat.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  changeFirstName(event) {
    this.setState({
      firstname: event.target.value,
    });
  }

  changeLastName(event) {
    this.setState({
      lastname: event.target.value,
    });
  }

  changeEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }

  changePassword(event) {
    this.setState({
      password: event.target.value,
    });
  }

  changePasswordRepeat(event) {
    this.setState({
      passwordRepeat: event.target.value,
    });
  }

  onSubmit(event) {
    event.preventDefault();

    if (this.state.password === this.state.passwordRepeat) {
      const registered = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        password: this.state.password,
        passwordRepeat: this.state.passwordRepeat,
      };

      axios
        .post(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/register/signup`,
          registered
        )

        // .then((response) => clg)
        .then((response) => {
          toast.success("Registrierung erfolgreich!");
          window.location = "/registered";
        })
        .catch((err) => {
          toast.error("E-Mail Adresse ist bereits registriert!");
        });

      // window.location = "/";

      this.setState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        passwordRepeat: "",
      });
    } else {
    }
  }

  render() {
    return (
      <div className="bg-beige w-full h-full flex items-center justify-center">
        <Toaster />
        <div className="max-w-md w-full p-5">
          <div className="flex justify-center mb-5 w-32 h-32 mx-auto">
            <HiLifeLogo />
          </div>
          <h1 className="text-2xl font-extrabold text-center mb-3">
            REGISTRIERUNG
          </h1>
          <h3 className="login-box-sub text-center font-extrabold mb-4">
            Schön dass du dabei bist!
          </h3>
          {/* '          <h5 className="login-box-sub text-center font-bold mb-4">
            Registriere Dich jetzt und spiel für 99€ (inkl 19% MwSt.) mit!
          </h5> */}
          <form onSubmit={this.onSubmit}>
            <div className="mb-5">
              <label htmlFor="email" className="text-sm">
                Vorname
              </label>

              <div className="relative mt-2">
                <input
                  type="text"
                  className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                  placeholder="Vorname"
                  onChange={this.changeFirstName}
                  value={this.state.firstname}
                  required
                />
              </div>
            </div>

            <div className="mb-5">
              <label htmlFor="email" className="text-sm">
                Nachname
              </label>

              <div className="relative mt-2">
                <input
                  type="text"
                  className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                  placeholder="Nachname"
                  onChange={this.changeLastName}
                  value={this.state.lastname}
                  required
                />
              </div>
            </div>

            <div className="mb-5">
              <label htmlFor="email" className="text-sm">
                E-Mail Adresse
              </label>

              <div className="relative mt-2">
                <input
                  type="email"
                  className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                  placeholder="E-Mail Adresse"
                  onChange={this.changeEmail}
                  value={this.state.email}
                  required
                />
              </div>
            </div>

            <div className="mb-5">
              <label htmlFor="email" className="text-sm">
                Passwort
              </label>

              <div className="relative mt-2">
                <input
                  type="password"
                  className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                  placeholder="Passwort"
                  onChange={this.changePassword}
                  value={this.state.password}
                  required
                />
              </div>
            </div>

            <div className="mb-8">
              <label htmlFor="email" className="text-sm">
                Passwort wiederholen
              </label>

              <div className="relative mt-2">
                <input
                  type="password"
                  className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                  placeholder="Passwort wiederholen"
                  onChange={this.changePasswordRepeat}
                  value={this.state.passwordRepeat}
                  required
                />
              </div>
            </div>

            <div className="flex gap-4 mb-3 items-start">
              <input
                className="min-w-[24px] min-h-[24px] accent-pink"
                id="checkbox_first"
                type="checkbox"
                name="checkbox_first"
                required
              ></input>
              <label
                htmlFor="checkbox_first"
                className="text-sm cursor-pointer"
              >
                Ich habe die AGB und Datenschutzerklärung zur Kenntnis genommen.
                Ich stimme zu, dass meine Angaben und Daten zur Registrierung
                bei der HiLife Challenge elektronisch erhoben und gespeichert
                werden. Ich verstehe, dass ich meine Einwilligung jederzeit für
                die Zukunft per E-Mail an mail@hilifechallenge.net widerrufen
                kann.*
              </label>
            </div>

            <div className="flex gap-4 mb-12 items-start">
              <input
                className="min-w-[24px] min-h-[24px] accent-pink"
                id="checkbox_second"
                type="checkbox"
                name="checkbox_second"
                required
              ></input>
              <label
                htmlFor="checkbox_second"
                className="text-sm cursor-pointer"
              >
                Ich möchte den HiLife Challenge Newsletter erhalten. Während der
                Challenge erhältst Du für die Teilnahme relevante Emails.
                Außerhalb der Challenge informieren wir Dich über zukünftige
                Challenges und andere spannende Infos. Ich verstehe, dass ich
                meine Anmeldung zum Newsletter jederzeit für die Zukunft
                widerrufen kann.*
              </label>
            </div>

            <button
              className="bg-pink w-full block text-white font-extrabold p-4 rounded-full mb-8"
              type="submit"
            >
              JETZT REGISTRIEREN
            </button>

            <p className="text-center">Du hast schon einen Account?</p>
            <Link
              to={"/login"}
              className="text-pink font-extrabold w-full block text-center"
            >
              ZUM LOGIN
            </Link>
          </form>
        </div>
      </div>
    );
  }
}

export default Register;
