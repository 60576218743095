import React, { useState } from "react";

import ResetIcon from "../../../../../Icons/ResetIcon";
import axios from "axios";
import BookmarkIcon from "../../../../../Icons/BookmarkIcon";
import classNames from "classnames";
import { ReactComponent as ShareIcon } from "./share.svg";
import "./Bookmark.scss";

function BookmarkModal({
  setOpen,
  checked,
  user,
  userChallenge,
  setUserChallenge,
  challenge,
}) {
  const deleteFromDatabase = () => {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/delete/single/info/string`,
        {
          userId: user._id,
          challengeId: challenge._id,
          path: "bookmark",
        }
      )
      .then((response) => {
        const updatedUserChallenge = {
          ...userChallenge,
          bookmark: false,
        };

        setUserChallenge(updatedUserChallenge);
        setOpen(false);
      });
  };

  function saveToDatabase() {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/add/single/info/string`,
        {
          path: "bookmark",
          userId: user._id,
          challengeId: challenge._id,
          info: true,
        }
      )
      .then((response) => {
        const updatedUserChallenge = {
          ...userChallenge,
          bookmark: true,
        };

        setUserChallenge(updatedUserChallenge);
        setOpen(false);
      });
  }

  return (
    <div className="w-full">
      {!checked ? (
        <>
          <div className="w-8 w-8 mx-auto mb-4">
            <BookmarkIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">APP BOOKMARK</h1>

          <h5 className="text-sm mt-4 mb-6 font-extrabold text-center">
            Lege Dir die High Life Challenge App als Bookmark auf Deinen
            Homescreen.
          </h5>

          <p className="text-sm text-center share-icon">
            Da Du in den nächsten 4 Wochen diese App täglich verwenden wirst,
            mach es Dir so einfach wie möglich, Deine erledigten Habits zu
            tracken. <br />
            <br /> Iphone: Klicke dazu auf das <ShareIcon /> Symbol und wähle
            “zum Home-Bildschirm hinzufügen” aus. <br />
            <br />
            Android: Klicke auf die drei Punkte “...” und wähle “Zum
            Startbildschirm hinzufügen” aus.
          </p>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out"
            )}
            onClick={() => saveToDatabase()}
          >
            ERLEDIGT
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      ) : (
        <>
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">
            EINGABE WIDERRUFEN
          </h1>
          <h3 className="mt-4 text-sm text-center mb-8">
            Klicke auf den Button um deine Eingabe zu widerrufen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => deleteFromDatabase()}
          >
            EINGABE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      )}
    </div>
  );
}

export default BookmarkModal;
