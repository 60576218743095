import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useStore } from "./utils/store";

import HomeView from "./Views/HomeView/HomeView";
import Home from "./Components/Home/Home";
import Register from "./Components/Register";
import Login from "./Components/Login";
import Registered from "./Components/Registered/Registered";
import Verified from "./Components/Verified/Verified";
import Analyse from "./Components/Analyse/Analyse";
import Paid from "./Components/Paid/Paid";
import NotPaid from "./Components/NotPaid/NotPaid";
import PayWall from "./Components/PayWall/PayWall";
import RankView from "./Views/RankView/RankView";
import Dashboard from "./Views/Dashboard";
import Admin from "./Components/Admin/Admin";
import AdminLogin from "./Components/AdminLogin/index.jsx";

import ChallengeRegeln from "./Components/Blog/SingleBlogs/ChallengeRegeln";
import Knowledge from "./Components/Knowledge/Knowledge";
import Erklaerung from "./Components/Blog/SingleBlogs/Erklaerung";
import Punkte from "./Components/Blog/SingleBlogs/Punkte";
import Erfolg from "./Components/Blog/SingleBlogs/Erfolg";
import Ernaehrung from "./Components/Blog/SingleBlogs/Ernaehrung";

import Ernaehrung2 from "./Components/Blog/SingleBlogs/Ernaehrung2";
import Bewegung from "./Components/Blog/SingleBlogs/Bewegung";
import Schlaf from "./Components/Blog/SingleBlogs/Schlaf";
import Atmung from "./Components/Blog/SingleBlogs/Atmung";
import Dankbarkeit from "./Components/Blog/SingleBlogs/Dankbarkeit";
import HitzeUndKaelte from "./Components/Blog/SingleBlogs/HitzeUndKaelte";
import Natur from "./Components/Blog/SingleBlogs/Natur";
import Wissen from "./Components/Blog/SingleBlogs/Wissen";
import Action from "./Components/Blog/SingleBlogs/Action";
import Reflexion from "./Components/Blog/SingleBlogs/Reflexion";

import Ernaehrung3 from "./Components/Blog/SingleBlogs/Ernaehrung3";
import Warum from "./Components/Blog/SingleBlogs/Warum";
import Superprotokoll from "./Components/Blog/SingleBlogs/Superprotokoll";
import Atmung2 from "./Components/Blog/SingleBlogs/Atmung2";
import Schlaf2 from "./Components/Blog/SingleBlogs/Schlaf2";

import Workout1 from "./Components/Blog/SingleBlogs/Workout1";
import Workout2 from "./Components/Blog/SingleBlogs/Workout2";
import Workout3 from "./Components/Blog/SingleBlogs/Workout3";
import Workout4 from "./Components/Blog/SingleBlogs/Workout4";

import Omnivor from "./Components/Blog/SingleBlogs/Omnivor";
import Vegane from "./Components/Blog/SingleBlogs/Vegane";
import BucketList from "./Components/Blog/SingleBlogs/BucketList";

import ChatView from "./Views/ChatView/ChatView";

import Impressum from "./Components/Law/Impressum";
import Datenschutz from "./Components/Law/Datenschutz";
import Agb from "./Components/Law/Agb";
import ForgotPassword from "./Components/ForgotPassword";
import PasswordChanged from "./Components/PasswordChanged/PasswordChanged";
import Gutes from "./Components/Blog/SingleBlogs/Gutes";
import Burpees from "./Components/Blog/SingleBlogs/Burpees";
import NoScreen from "./Components/Blog/SingleBlogs/NoScreen";
import AfterChallenge from "./Components/AfterChallenge/AfterChallenge";
import ChallengeDashboard from "./Components/ChallengeDashboard/ChallengeDashboard";

import SingleBlogs from "./Components/Blog/SingleBlogs/SingleBlogs";
import TeamView from "./Components/TeamView/TeamView";
import TeamInvite from "./Components/TeamInvite/TeamInvite";

const MyRoutes = () => {
  const user = useStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !user.email &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/register" &&
      window.location.pathname !== "/registered" &&
      window.location.pathname !== "/verified" &&
      window.location.pathname !== "/password-changed" &&
      window.location.pathname !== "/admin" &&
      window.location.pathname !== "/admin/dashboard" &&
      window.location.pathname !== "/impressum" &&
      window.location.pathname !== "/datenschutz" &&
      window.location.pathname !== "/agb" &&
      window.location.pathname !== "/forgot-password" &&
      window.location.pathname !== "/challenge-dashboard" &&
      window.location.pathname !== "/paid"
    ) {
      navigate("/login");
    }
  }, [navigate, user.email, user.admin]);

  return (
    <Routes>
      <Route
        path="/challenge-dashboard"
        element={<ChallengeDashboard />}
        exact
      />
      <Route path="/paid" element={<Paid />} exact />
      {user.email && (
        <>
          <Route path="/analyse" element={<Analyse />} exact />

          <Route path="/notpaid" element={<NotPaid />} exact />
          <Route path="/dashboard" element={<Dashboard />} exact />
          <Route path="/ranking" element={<RankView />} exact />
          <Route path="/home" element={<HomeView />} exact />
          <Route path="/chat" element={<ChatView />} exact />
          <Route path="/team" element={<TeamView />} exact />
          <Route
            path="/challenge-dashboard"
            element={<ChallengeDashboard />}
            exact
          />
        </>
      )}
      {user.admin && (
        <>
          <Route path="/admin/dashboard" element={<Admin />} exact />
        </>
      )}
      {user.email && !user.payWall && (
        <Route path="/paywall" element={<PayWall />} exact />
      )}
      <Route path="/" element={<Home />} exact />
      <Route path="/teams/:teamId" element={<Login />} />
      <Route path="/join-team/:identifier" element={<TeamInvite />} />
      <Route path="/login" element={<Login />} exact />
      <Route path="/forgot-password" element={<ForgotPassword />} exact />
      <Route path="/register" element={<Register />} exact />
      <Route path="/registered" element={<Registered />} exact />
      <Route path="/verified" element={<Verified />} exact />
      <Route path="/password-changed" element={<PasswordChanged />} exact />
      <Route path="/admin" element={<AdminLogin />} exact />
      <Route path="/blog" element={<Knowledge />} exact />

      <Route path="/blog/challengeregeln" element={<ChallengeRegeln />} exact />
      <Route path="/blog/erklaerung" element={<Erklaerung />} exact />
      <Route path="/blog/punkte" element={<Punkte />} exact />
      <Route path="/blog/erfolg" element={<Erfolg />} exact />
      <Route path="/blog/ernaehrung" element={<Ernaehrung />} exact />
      <Route path="/blog/habits/ernaehrung" element={<Ernaehrung2 />} exact />
      <Route path="/blog/habits/bewegung" element={<Bewegung />} exact />
      <Route path="/blog/habits/schlaf" element={<Schlaf />} exact />
      <Route path="/blog/habits/atmung" element={<Atmung />} exact />
      <Route path="/blog/habits/dankbarkeit" element={<Dankbarkeit />} exact />
      <Route
        path="/blog/habits/hitzeundkaelte"
        element={<HitzeUndKaelte />}
        exact
      />
      <Route path="/blog/habits/natur" element={<Natur />} exact />
      <Route path="/blog/habits/wissen" element={<Wissen />} exact />
      <Route path="/blog/habits/action" element={<Action />} exact />
      <Route path="/blog/habits/reflexion" element={<Reflexion />} exact />
      <Route path="/blog/wissen/ernaehrung" element={<Ernaehrung3 />} exact />
      <Route path="/blog/wissen/warum" element={<Warum />} exact />
      <Route
        path="/blog/wissen/superprotokoll"
        element={<Superprotokoll />}
        exact
      />
      <Route path="/blog/wissen/atmung" element={<Atmung2 />} exact />
      <Route path="/blog/wissen/schlaf" element={<Schlaf2 />} exact />
      <Route path="/blog/training/workout1" element={<Workout1 />} exact />
      <Route path="/blog/training/workout2" element={<Workout2 />} exact />
      <Route path="/blog/training/workout3" element={<Workout3 />} exact />
      <Route path="/blog/training/workout4" element={<Workout4 />} exact />
      <Route path="/blog/rezepte/omnivor" element={<Omnivor />} exact />
      <Route path="/blog/rezepte/vegane" element={<Vegane />} exact />
      <Route path="/blog/action/bucketlist" element={<BucketList />} exact />
      <Route path="/blog/action/gutes" element={<Gutes />} exact />
      <Route path="/blog/action/burpees" element={<Burpees />} exact />
      <Route path="/blog/action/noscreen" element={<NoScreen />} exact />

      <Route path="/impressum" element={<Impressum />} exact />
      <Route path="/datenschutz" element={<Datenschutz />} exact />
      <Route path="/agb" element={<Agb />} exact />
      <Route path="/after-dashboard" element={<AfterChallenge />} exact />
      <Route path="/blog/:link" element={<SingleBlogs />} />
    </Routes>
  );
};

export default MyRoutes;
