import React from "react";

function CheckIcon() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="checkmark"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90234 0.341431C4.79393 0.341431 0.652344 4.48302 0.652344 9.59143C0.652344 14.6998 4.79393 18.8414 9.90234 18.8414C15.0108 18.8414 19.1523 14.6998 19.1523 9.59143C19.1435 4.48623 15.0075 0.350278 9.90234 0.341431Z"
        fill="white"
      />
      <mask
        id="mask0_10_6204"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="12"
        height="9"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.70117 5.4707H15.1028V13.7121H4.70117V5.4707Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10_6204)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7221 5.9045C15.2297 6.41256 15.2297 7.23539 14.7221 7.74346L9.51697 13.3823C9.27407 13.5994 8.9583 13.7168 8.6324 13.7122C8.32929 13.7097 8.03629 13.606 7.79946 13.4172L5.16247 11.2483C4.60633 10.7691 4.5446 9.93006 5.02331 9.37443C5.50253 8.81879 6.34155 8.75655 6.89719 9.23577L8.23314 10.3632C8.39962 10.5176 8.65669 10.5176 8.82318 10.3632L12.8832 5.88729C13.3715 5.36 14.1948 5.32913 14.7221 5.81746C14.7221 5.81746 14.7221 5.81746 14.7221 5.81797V5.9045Z"
          fill="#1B1B1B"
        />
      </g>
    </svg>
  );
}

export default CheckIcon;
