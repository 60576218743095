import React from "react";
import "./AdminDashboard.scss";

function AdminDashboard() {
  return (
    <div className="admin-welcome">
      <div className="welcome-decoration top-right"></div>
      <div className="welcome-decoration bottom-left"></div>
      <h2>Welcome to HiLife Admin</h2>
      <p>
        Select a section from the sidebar to manage your <span>Homepage</span>,{" "}
        <span>Challenges</span>, <span>Habits</span>, or <span>Blog</span>{" "}
        settings.
      </p>
    </div>
  );
}

export default AdminDashboard;
