import React from "react";

function RulesIconHomepage(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="w-8 h-8"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: props.color }}
    >
      <path d="M5.83325 9.37697L11.3183 11.761C11.7536 11.951 12.2485 11.951 12.6839 11.761L18.1746 9.37697C18.4884 9.23678 18.6894 8.92408 18.6867 8.58038C18.6876 8.23974 18.4833 7.93207 18.1689 7.80087L12.6839 5.4225C12.2485 5.2325 11.7536 5.2325 11.3183 5.4225L5.83325 7.81225C5.52268 7.94184 5.31908 8.24388 5.31547 8.58038C5.3122 8.92587 5.51619 9.2397 5.83325 9.37697Z" />
      <path d="M18.1693 11.2262L17.4638 10.9189C17.3187 10.8556 17.1537 10.8556 17.0086 10.9189L12.0015 13.0925L6.99443 10.9189C6.84931 10.8556 6.68436 10.8556 6.53924 10.9189L5.83369 11.2262C5.51805 11.358 5.3125 11.6665 5.3125 12.0085C5.3125 12.3506 5.51805 12.6591 5.83369 12.7909L11.3187 15.175C11.7541 15.365 12.2489 15.365 12.6843 15.175L18.1693 12.7909C18.4864 12.6536 18.6904 12.3398 18.6871 11.9943C18.6835 11.6578 18.4799 11.3558 18.1693 11.2262V11.2262Z" />
      <path d="M18.1689 14.6117L17.4633 14.3044C17.3182 14.2411 17.1533 14.2411 17.0082 14.3044L12.0011 16.4836L6.99398 14.3044C6.84886 14.2411 6.68391 14.2411 6.53879 14.3044L5.83325 14.6117C5.51619 14.7489 5.3122 15.0628 5.31547 15.4082C5.31451 15.7489 5.51888 16.0566 5.83325 16.1877L11.3183 18.5775C11.7536 18.7675 12.2485 18.7675 12.6839 18.5775L18.1689 16.1877C18.4833 16.0566 18.6876 15.7489 18.6867 15.4082C18.6899 15.0628 18.486 14.7489 18.1689 14.6117V14.6117Z" />
    </svg>
  );
}

export default RulesIconHomepage;
