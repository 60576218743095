import React from 'react'
import "./SingleBlogs.scss"
import atmung from "../../../img/atmung.png"
import BackIcon from '../../Icons/BackIcon'


function Atmung() {
  return (
    <div className='single-blog'>
      <div className="fixed" onClick={() => (window.location = "/blog")}>
        <BackIcon />
      </div>
      <h1 className="single-blog-title">Atmung</h1>
      <img src={atmung} alt="" className="single-blog-image" />
      <h2 className="single-blog-sub">Die Regel: Konzentriere Dich jeden Tag 5 Minuten lang nur auf Deine Atmung.</h2>
      <p className="single-blog-text">
        Wir atmen falsch! <br />
        Naja, zumindest die meisten von uns. Wir atmen zu schnell und oft, zu flach und leider auch noch durch den Mund. Das hat bittere Konsequenzen. Eine falsche Atmung führt zu einer ganzen Reihe von körperlichen Problemen und Beschwerden, die lange über das Schnarchen oder Luftnot hinausgehen. <br /> <br />
        Die perfekte Atmung ist eine lang vergessene Kunst, die nun langsam endlich wieder ins Bewusstsein der Mainstream Medien, Wissenschaftler und Ärzte rückt. Sie kann nicht nur bei Angststörungen und Asthma Linderung verschaffen. <br /> <br />
        Die neueste Forschung nimmt nun auch den Einfluss von richtiger Atmung und Autoimmunerkrankungen ins Visier. Eine wichtige Erkenntnis der Wissenschaft: Durch Atmung kann man aktiv Einfluss auf das Immunsystem und autonome Nervensystem nehmen. Hört sich unglaublich an - ist aber so!
      </p> <br />


      <ul className="single-blog-list">
        <p className="single-blog-text">Was machen wir falsch?
        </p>
        <li>Atmung durch den Mund: Die Nase ist
          das Organ, durch das wir atmen sollten. Sie erwärmt und filtert die Luft und die Nebenhöhlen produzieren Stickstoffmonoxid, dass beim Transport von Sauerstoff in die Zellen hilft.</li>
        <li>zu schnelles und flaches Atmen: durch das viele Sitzen in unserer heutigen Zeit kann das Zwerchfell bei der Atmung nicht richtig aktiviert und so nur ein Teil des Sauerstoffs verwertet werden.</li>
        <p className="single-blog-text">Und wie geht es richtig? <br /> <br />
          Wir sollten hier zwischen alltäglicher Atmung und spezielle Atemübungen unterscheiden.
        </p>
        <li>im Alltag: möglichst sanft, tief und immer durch die Nase atmen, dabei die Aus- und Einatmung etwa gleich lang, bzw. die Ausatmung etwas länger. Beispiel Übung: kohärente Atmung</li>
        <li>spezielles Atemtraining: Hier atmet man für eine bestimmte Zeit besonders schnell oder intensiv teilweise gefolgt von kompletten Atempausen mit Luftanhalten. Beispiel Übung: Tummo
          Atmung (z.B. von Wim Hof)</li>
      </ul>


      <p className="single-blog-text">
        Während der Challenge kannst Du entweder die Atemübung aus der App (kohärente Atmung) machen oder eine von Dir selbst ausgewählte Atemübung/-meditation aus unserer Library. <br /> <br />
        Falls Du verschiedene Atmungen machen möchtest: Mach Dir vorher einen konkreten Plan, wann Du welche Übung durchführen wirst. z.B. Montag-Samstag Atemübung aus der App und jeden Sonntag eine längere aus der Library. <br /> <br />
        Newbie Tipp: Die 5min Atemübung aus der App reicht am Anfang vollkommen. Versuche einfach im Alltag immer mal wieder zur kohärenten Atmung zurückzukehren.
      </p> <br />
    </div>
  )
}

export default Atmung